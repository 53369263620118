<template>
  <div class="item-main_block" :class="{'is-disabled': disabled, 'item-main_block-practice': isPractice, 'full-height': fullHeight}" :style="{ 'background-image': option.image_url ? `url(${option.image_url})` : 'none' }">
    <div>
      <div class="item-main_block_header">
      <div class="item-main_block__title">{{ option.title ?? option.name }}</div>
      <label class="item-main_block__checkbox" :class="type" v-if="!disabled">
        <slot></slot>
        <span class="item-main_block__checkmark"></span>
      </label>
    </div>
      <div class="item-main_block__desc">{{ option.description }}</div>
      <div class="item-main_block__desc" v-if="setupData && setupData?.course?.has_discount && setupData?.format === 'additional' && priceWithDiscount > 0">С учетом скидки за прохождение базового курса</div>
    </div>
    <div class="item-main_block__price" v-if="price > 0 && !disabled">
      <div>
        <template v-if="!packages">
          <s v-if="option.has_discount && price < priceWithDiscount">{{ numberFormat(price) }}</s>
          {{ numberFormat(priceWithDiscount) }}
          <CurrencyRuble/>
        </template>
        <slot name="input"></slot>
      </div>
      <a href="#" class="item-main_block__read-more" v-if="isPractice" @click.prevent="openReadMore = true">Подробнее</a>
    </div>
  </div>
  <BaseModal :show="openReadMore" :title="option.title ?? option.name" :description="option.description">
    <a href="#" class="item-main_block__read-more" @click.prevent="openReadMore = false">Закрыть</a>
  </BaseModal>
</template>

<script>
import numberFormat from "@/helpers/numberFormat";
import CurrencyRuble from "@/components/common/CurrencyRuble";
import {inject, ref} from "@vue/runtime-core";
import {computed} from "@vue/reactivity";
import BaseModal from "@/components/common/BaseModal";

export default {
  components: {BaseModal, CurrencyRuble},
  props: ['option', 'type', 'step', 'disabled', 'packages', 'isPractice', 'fullHeight'],
  setup(props) {
    const setupData = inject('setupData')
    const openReadMore = ref(false)

    const price = computed(() => {
      if(setupData && setupData.value?.course?.has_discount && setupData.value?.format === 'additional') {
        return props.option.price_base;
      }

      if(setupData && setupData.value?.format === 'base') {
        return props.option.price;
      }

      if(props.step?.id === 'format') {
        return props.option.price;
      }

      return props.option.price_additional || props.option.price;
    })

    const priceWithDiscount = computed(() => {
      return price.value - (props.option.has_discount ? props.option.discount : 0)
    })

    return {numberFormat, setupData, price, priceWithDiscount, openReadMore}
  }
}
</script>

<style scoped lang="scss">
.is-disabled {
  opacity: 0.56;
}
.item-main_block {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.item-main_block:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background: rgba(255,255,255,.75);
}
.item-main_block-practice {
  height: 240px;
}
.item-main_block-practice.full-height {
  height: auto !important;
}
.item-main_block-practice.full-height .item-main_block__read-more {
  display: none;
}
.item-main_block-practice .item-main_block__desc {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  max-height: 114px;
}
.item-main_block__read-more {
  font-size: 14px;
  color: #000;
  font-weight: bold;
}
.item-main_block__price {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 1025px) {
  .item-main_block-practice {
    height: auto;
  }
  .item-main_block-practice .item-main_block__desc {
    overflow: hidden;
    display: block;
    -webkit-line-clamp: initial;
    -webkit-box-orient: initial;
    max-height: none;
  }
  .item-main_block__read-more {
    display: none;
  }
}
</style>
