<template>
  <div class="form-group">
    <label class="label">
      <span v-if="title">{{ title }}</span>
      <span class="input-wrapper">
        <slot name="icon"/>
        <slot/>
      </span>
    </label>
    <small v-if="error" class="error">{{ error }}</small>
  </div>
</template>

<script>
export default {
  props: ["title", "error"]
}
</script>

<style lang="scss">
.form-group {
  text-align: left;
  margin-bottom: 8px;

  .error {
    color: red;
    margin-top: 8px;
    display: block;
    font-size: 12px;
  }
}

.label {
  display: block;
}

.input {
  box-sizing: border-box;
  width: 100%;
  resize: vertical;
  padding: 11px 12px;
  background: #FFFFFF;
  border: 1px solid #e3e6e8;
  border-radius: 4px;
  font-family: inherit;
  font-size: 14px;
  line-height: 120%;
  color: #000000;
  margin-top: 8px;

  &::placeholder {
    opacity: 0.4;
  }

  &:disabled {
    color: #9fa8ad;
  }
}

.input-wrapper {
  position: relative;
}

.form-icon {
  position: absolute;
  left: 12px;
  top: 2px;
  color: #dadada;

  & ~ .input {
    padding-left: 37px;
  }
}
</style>
