<template>
  <div class="modal" :class="{ 'modal-full': full }" v-if="show">
    <div class="modal_window">
      <div class="modal_window__title">{{ title }}</div>
      <div class="modal_window__desc"><slot name="description-slot">{{description}}</slot></div>
      <div class="modal_window__btns"><slot></slot></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['show', 'title', 'description', 'full'],
}
</script>

<style scoped>
  .modal {
    top: 0;
    left: 0;
    display: block;
    overflow-y: auto;
  }
  .modal_window {
    margin: 50px auto 50px auto;
  }
  .modal-full .modal_window {
    max-width: 90%;
    width: 100%;
    height: 100%;
    max-height: 90%;
  }
</style>
