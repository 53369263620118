<template>
  <div class="item-main_block item-main_block_date">
    <div class="item-main_block_header">
      <div class="item-main_block__title">{{ date ? date.day : '' }}</div>
      <label class="item-main_block__checkbox radio">
        <slot></slot>
        <span class="item-main_block__checkmark"></span>
      </label>
    </div>
    <div class="item-main_block__month">{{ date ? date.month : 'Без даты' }}</div>
    <div class="item-main_block__month in-progress" v-if="option.in_progress">Курс уже идет
      <span class="question">?<span class="tooltip">Часть курсов необходимо пройти самостоятельно</span></span>
    </div>
    <div class="item-main_block__month" v-if="option.times" style="margin-top: 10px;line-height: 1.2;">
      {{ option.times }}
    </div>
  </div>
</template>

<script>
import {computed} from "@vue/reactivity";
import {dateToMonthObject, stringToDate} from "@/helpers/date";

export default {
  props: ["option"],
  setup(props) {

    const date = props.option.date_of ? computed(() => dateToMonthObject(stringToDate(props.option.date_of))) : null;
    return {date}
  }
}
</script>

<style scoped>
.in-progress {
  font-size: 12px !important;
  margin-top: 10px;
  color: #9fa8ad !important;
}
.question {
  display: inline-flex;
  width: 22px;
  height: 22px;
  border-radius: 20px;
  background: #eee;
  color: #020202;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  cursor: help;
  position: relative;
}

.question:hover .tooltip {
  display: block;
}

.tooltip {
  display: none;
  bottom: 30px;
  left: calc(50% - 84px);
  position: absolute;
  justify-content: space-between;
  max-width: 160px;
  width: 160px;
  z-index: 1;
  border-radius: 8px;
  padding: 5px;
  background: #ffffff;
  box-shadow: 0 12px 36px rgba(0, 66, 102, 0.22);
  cursor: help;
  text-align: center;
  font-size: 12px;
}

.tooltip:after {
  content: "";
  display: block;
  position: absolute;
  left: calc(50% - 10px);
  bottom: -16px;
  border: 8px solid transparent;
  border-top: 8px solid #ffffff;
}
</style>
