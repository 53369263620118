export default [
    {
        id: 1,
        title: "Банковской картой",
        card: true,
        description: "Государственное удостоверение на право управления маломерным судном",
    },
    {
        id: 2,
        title: "Наличными",
        description: "Государственное удостоверение на право управления маломерным судном",
    },
    {
        id: 3,
        title: "Со счета компании",
        description: "Государственное удостоверение на право управления маломерным судном",
    },
]
