<template>
  <div class="content">
    <div class="content-header">
      <button class="content-header__exit" @click="logout" :class="{'logout-hide': !user}">Выйти</button>
      <RouterLink :to="{name: 'timetable'}" class="content-header__close"><i class="fas fa-times"></i></RouterLink>
    </div>
    <div class="content-header__title">Наш флот</div>
    <div class="content-main">
      <div class="content-main_desc" v-if="isLoading">
        <div class="fleets" v-for="fleetGroup in fleetGroups" :key="fleetGroup.id">
          <h2>{{ fleetGroup.name }}</h2>
          <div class="fleets-list">
            <div class="fleets-item" v-for="fleet in fleetGroup.fleets" :key="fleet.id">
              <div class="fleets-item-head">
                <div class="fleets-item-head-img">
                  <img :src="fleet.image_url" alt="" v-if="fleet.image_url">
                </div>
                <div class="fleets-item-content">
                  <h3>{{ fleet.name }}</h3>
                  <p>{{ fleet.description }}</p>
                  <div class="fleets-buttons" v-if="fleet.excursion_gallery || fleet.excursion_video">
                    <button class="button"
                            v-if="fleet.excursion_gallery"
                            @click="openExcursionModal(fleet, 'gallery')"
                    >
                      <i class="fas fa-vr-cardboard"></i>
                      Виртуальная экскурсия
                    </button>
                    <button class="button"
                            v-if="fleet.excursion_video"
                            @click="openExcursionModal(fleet, 'video')"
                    >
                      <i class="fas fa-video"></i>
                      Виртуальная экскурсия в видео
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ExcursionModal :show="excursionModal" @close="excursionModal = false" :fleet="activeFleet" :type="typeFleet"/>
  </div>
</template>

<script>
import {inject, onMounted, ref} from "@vue/runtime-core";
import {getFleets} from "@/data/requests";
import ExcursionModal from "@/components/parts/ExcursionModal";

export default {
  components: {
    ExcursionModal,
  },
  setup() {
    const logout = inject('logout');
    const user = inject('user');
    const token = ref(localStorage.getItem('token'));
    const fleetGroups = ref(null);
    const isLoading = ref(false);
    const excursionModal = ref(false);
    const activeFleet = ref(null);
    const typeFleet = ref(null);

    const openExcursionModal = (fleet, type) => {
      activeFleet.value = fleet;
      typeFleet.value = type;
      excursionModal.value = true;
    };

    onMounted(async () => {
      fleetGroups.value = await getFleets(token.value);
      isLoading.value = true;
    });

    return {logout, user, fleetGroups, isLoading, excursionModal, activeFleet, openExcursionModal, typeFleet};
  }
}
</script>
<style scoped lang="scss">
.content-main_desc {
  max-width: 700px;
}

.logout-hide {
  opacity: 0;
  pointer-events: none;
}

.fleets {
  max-width: 700px;
}

.fleets h2 {
  font-weight: 400;
  font-size: 18px;
}

.fleets-list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 25px;
}

.fleets-item {
  background: #FFFFFF;
  border-radius: 4px;
  flex-basis: 100%;
  margin-bottom: 20px;
}

.fleets-item-head {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.fleets-item-head-img {
  width: 100%;
  height: 300px;
}

.fleets-item-content {
  padding: 20px;
}

.fleets-item-head-img img {
  width: inherit;
  height: inherit;
  display: block;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
}

.fleets-item-head > div {
  flex-basis: 100%;
}

.fleets-item h3 {
  margin: 0 0 7px 0;
  font-weight: 800;
  font-size: 18px;
  line-height: 36px;
}

.fleets-item p {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
}

.fleets-buttons button {
  background-color: #00669c;
  color: #fff;
  border-color: #00669c;
  padding-left: 15px;
}

.fleets-buttons button i {
  margin-right: 9px;
}

.fleets-buttons button:hover {
  background-color: white;
  color: #00669c;
}

.fleets-buttons button:hover i {
  color: #00669c;
}

.fleets-buttons button {
  margin-right: 10px;
  margin-top: 10px;
}
</style>
