import {ref} from "@vue/runtime-core";

export function useLocalStore(name, defaultValue = {}) {
  const data = ref(defaultValue);

  function clear() {
    data.value = defaultValue;
    localStorage.removeItem(name);
  }

  function add(value) {
    data.value = {
      ...data.value,
      ...value
    };
    localStorage.setItem(name, JSON.stringify(data.value));
  }

  function update(value) {
    data.value = value;
    localStorage.setItem(name, JSON.stringify(data.value));
  }

  return {
    data,
    clear,
    update,
    add
  }
}
