<template>
  <div class="container">
    <LeftSidebar v-if="!$route.meta.blankPage" @show-support="isShowingSupport = true"/>
    <MobileHeader v-if="!$route.meta.blankPage" @show-support="isShowingSupport = true"/>
    <RouterView/>
    <SupportModal v-if="user" :show="isShowingSupport" @hide="isShowingSupport = false"/>
    <TelegramModal v-if="user" :show="isShowingTelegram" @hide="isShowingTelegram = false"/>
    <NotificationModal v-if="user && importantNotification" :show="importantNotification" @read="readNotification(importantNotification)" :notification="importantNotification" />
  </div>
</template>

<script>
import {onMounted, onUnmounted, provide, readonly, ref} from "@vue/runtime-core";
import MobileHeader from "@/components/parts/MobileHeader";
import {getPreparedUserData} from "@/helpers/user";
import {useRoute, useRouter} from "vue-router";
import LeftSidebar from "@/components/parts/LeftSidebar";
import '@/assets/css/reset.css';
import '@/assets/scss/style.scss';
import '@/assets/slick/slick.css';
import '@/assets/slick/slick-theme.css';
import SupportModal from "@/components/parts/SupportModal";
import NotificationModal from "@/components/parts/NotificationModal";
import {getUserProfile, getSiteInfo, getNotifications, markAsReadNotification} from "@/data/requests";
import {computed} from "@vue/reactivity";
import TelegramModal from "@/components/parts/TelegramModal";

export default {
  components: {
    TelegramModal,
    LeftSidebar,
    MobileHeader,
    SupportModal,
    NotificationModal,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const user = ref(null);
    const siteInfo = ref(null);
    const token = ref(localStorage.getItem("token"));
    const notifications = ref([]);
    const isShowingTelegram = ref(false)

    async function tryLoadUser() {
      try {
        if(route.name === 'user-edit') {
          console.log('dont update while in user edit mode')
          return;
        }
        siteInfo.value = await getSiteInfo(token.value);
        user.value = await getPreparedUserData(token.value);
      } catch (e) {
        if (e.message === "Unauthorized") await logout();
      }
    }

    async function tryLoadNotifications() {
      try {
        if(user.value) {
          notifications.value = await getNotifications(token.value)
        }
      } catch (e) {
        if (e.message === "Unauthorized") await logout();
      }
    }

    async function login(newToken) {
      token.value = newToken;
      localStorage.setItem("token", newToken);
      await tryLoadUser();
    }

    function logout() {
      token.value = "";
      user.value = null;
      localStorage.removeItem("token");
      if (route.meta.requiresAuth)
        router.push({name: "auth"})
    }

    onMounted(async () => {
      await tryLoadUser();
      await tryLoadNotifications();

      if(!user.value.has_telegram) {
        isShowingTelegram.value = true
      }
    });

    const interval = setInterval(tryLoadUser, 1000 * 60);
    const intervalNotifications = setInterval(tryLoadNotifications, 5000);
    onUnmounted(() => {
      clearInterval(interval)
      clearInterval(intervalNotifications)
    });

    async function updateProfileData(profile) {
      if (!profile) profile = await getUserProfile(token.value);
      user.value = {
        ...user.value,
        ...profile
      }
    }

    const newNotificationsCount = computed(() => {
      return notifications.value.reduce((sum, notification) => {
        return sum + ~~!notification.is_read;
      }, 0);
    });

    const importantNotification = computed(() => {
      return notifications.value
          .filter(notification => !notification.is_read && notification.important)
          ?.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
          ?.shift(0);
    });

    const readNotification = async (notification) => {
      await markAsReadNotification(token.value, notification.id);
      notification.is_read = true;
    };

    provide('user', readonly(user));
    provide('siteInfo', readonly(siteInfo));
    provide('tryLoadUser', tryLoadUser);
    provide('updateProfileData', updateProfileData);
    provide('token', readonly(token));
    provide('login', login);
    provide('logout', logout);
    provide('notifications', notifications);
    provide('new-notifications-count', newNotificationsCount);

    const isShowingSupport = ref(false);

    return {logout, user, isShowingSupport, notifications, importantNotification, readNotification, isShowingTelegram}
  }
}
</script>
