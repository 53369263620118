<template>
  <div class="user-info_item" v-if="show">
    <div class="user-info_item__title">Старый пароль:</div>
    <div class="user-info_item__value">
      <FormField class="field" :error="errors.old_password">
        <input class="input" type="password" v-model="form.old_password">
      </FormField>
    </div>
    <button class="user-info_item__action red" @click="show = false">Отмена</button>
  </div>
  <div class="user-info_item">
    <div class="user-info_item__title">Пароль:</div>
    <div class="user-info_item__value">
      <FormField class="field" :error="errors.password">
        <input class="input" type="password" v-model="form.password" :disabled="!show" placeholder="********">
      </FormField>
    </div>
    <button class="user-info_item__action" v-if="!show" @click="show = true">Изменить пароль</button>
  </div>
  <div class="user-info_item" v-if="show">
    <div class="user-info_item__title">Пароль ещё раз:</div>
    <div class="user-info_item__value">
      <FormField class="field" :error="errors.password_confirmation">
        <input class="input" type="password" v-model="form.password_confirmation">
      </FormField>
    </div>
    <button class="user-info_item__action button primary sm" @click="change">Сменить</button>
  </div>
</template>

<script>
import {inject, ref} from "@vue/runtime-core";
import FormField from "@/components/common/FormField";
import {changePassword} from "@/data/requests";

export default {
  components: {FormField},
  emits: ['success'],
  setup(props, {emit}) {
    const show = ref(false);
    const token = inject('token');
    const form = ref({});
    const errors = ref({});

    async function change() {
      errors.value = {};
      const data = await changePassword(token.value, form.value);
      if (data.errors) {
        errors.value = data.errors;
        return;
      }
      emit('success', 'Пароль успешно изменён');
      form.value = {};
      show.value = false;
    }

    return {show, form, errors, change}
  }
}
</script>

<style scoped>
.input {
  margin-top: 0;
}
.field {
  margin-bottom: 0;
}
</style>
