<template>
  <div>
    <input class="input" type="checkbox" v-model="checkbox">
    <span class="checkbox"><i class="fas fa-check"></i></span>
  </div>
</template>

<script>
import {computed} from "@vue/reactivity";

export default {
  props: ["modelValue"],
  setup(props, {emit}) {
    const checkbox = computed({
      get: () => props.modelValue,
      set: value => emit('update:modelValue', value)
    });
    return {checkbox}
  }
}
</script>

<style scoped lang="scss">
.input {
  display: none;
}
.checkbox {
  position: relative;
  cursor: pointer;
  user-select: none;
  width: 14px;
  height: 14px;
  flex: 0 0 auto;
  border-radius: 4px;
  background: #086596;
  color: #086596;
  font-size: 8px;
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
  .input:checked ~ & {
    color: #ffffff;
  }
}
</style>
