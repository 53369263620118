<template>
    <RouterLink class="blocks__block" :style="{color: course.color}" :class="{unpaid: !course.paid}"
                :to="!course.has_access || !link ? '#' : link">
        <div class="block__content">
            <div class="content__header">{{ course.name }}</div>
            <div class="content__text">{{ course.ships.join(', ') }}</div>
            <template v-if="course.paid">
                <span v-if="course.has_access" class="button primary zoom documents-link">Лекции</span>
                <span v-else-if="course.prolongation_price > 0 && course.prolongation_months > 0"
                      @click="openProlongationModal()"
                      class="button primary zoom documents-link"
                >Пролонгация</span>
            </template>
            <RouterLink
                    v-if="course.paid"
                    class="button secondary zoom documents-link"
                    :to="{name: 'user'}"
            >Документы
            </RouterLink>
            <template v-if="buyAdditional && course.has_access">
                <br>
                <button @click.prevent="buyAdditionalModal = true" class="button primary zoom documents-link">Расширить курс</button>
            </template>
            <div v-if="!course.paid">
                <div class="need-pay">
                    <i class="far fa-clock"></i>
                    <span class="need-pay__text">Курс ожидает оплаты</span>
                </div>
                <button class="button primary sm wide">Оплатить</button>
            </div>
            <div v-if="!course.has_access">
                <div class="need-pay">
                    <i class="far fa-clock"></i>
                    <span class="need-pay__text">Время действия курса истекло</span>
                </div>
            </div>
        </div>
        <div class="block__progress" v-if="course.paid && !course.no_dates">
            <ProgressRound :progress="course.progress" :color="course.color"/>
        </div>
    </RouterLink>
    <BaseModal :show="prolongationModal"
               @cancel-modal="prolongationModal = false"
               title="Пролонгация">
        <template #description-slot>
            <p>
                Стоимость пролонгации <b>{{ numberFormat(course.prolongation_price) }} <CurrencyRuble/></b><br>
                После подтверждения вы будете перенаправлены на страницу оплаты
            </p>
            <br>
            <div class="form-group field" v-if="prolongationGroups.length">
                <label class="label">
                    Выберите группу для пролонгации:
                    <span class="input-wrapper">
                        <select class="input" v-model="prolongationGroup">
                            <option :value="group.id" v-for="group in prolongationGroups" :key="group.id">
                                <template v-if="group.date_of && group.date_to">
                                    с {{ group.date_of }} по {{ group.date_to }}
                                </template>
                                <template v-else>
                                    Группа без дат
                                </template>
                            </option>
                        </select>
                    </span>
                </label>
            </div>
            <div v-else>
                <div class="alert-transfer">Нет предстоящих групп в курсе</div>
            </div>
        </template>
        <a :href="prolongationLink" class="button primary" :class="{'disabled': !prolongationGroup}" :disabled="!prolongationGroup">Подтвердить</a>
        <button class="button secondary" @click="prolongationModal = false">Закрыть</button>
    </BaseModal>
    <BaseModal :show="buyAdditionalModal"
               @cancel-modal="buyAdditionalModal = false"
               title="Расширение курса">
        <template #description-slot>
            <p>Вы можете расширить свой курс, докупив позиции ниже.</p>
            <template v-for="custom in course.additional_fields" :key="custom.id">
                <template v-if="custom.fields.length">
                    <h3>{{ custom.title }}</h3>
                    <div v-for="field in custom.fields" :key="field.id">
                        <label>
                            <input type="checkbox" :checked="buyAdditionalArray.indexOf(field) !== -1" @change="buyAdditionalChange(field)"> {{ field.title }} (<b>{{ numberFormat(field.price ) }} <CurrencyRuble/></b>)
                        </label>
                    </div>
                </template>
            </template>
            <br>
            <p>Сумма к оплате: <b>{{ numberFormat(buyAdditionalPrice) }} <CurrencyRuble/></b></p>
            <p>После подтверждения вы будете перенаправлены на страницу оплаты.</p>
            <br>
        </template>
        <a :href="buyAdditionalLink" class="button primary" :class="{'disabled': buyAdditionalPrice === 0}" :disabled="buyAdditionalPrice === 0">Подтвердить</a>
        <button class="button secondary" @click="buyAdditionalModal = false;buyAdditionalArray.splice(0)">Закрыть</button>
    </BaseModal>
</template>

<script>
    import {apiHost} from "@/config";
    import numberFormat from "@/helpers/numberFormat";
    import ProgressRound from "@/components/common/ProgressRound";
    import {computed, ref} from "@vue/reactivity";
    import {stringToTime} from "@/helpers/date";
    import BaseModal from "@/components/common/BaseModal";
    import CurrencyRuble from "@/components/common/CurrencyRuble";
    import {getCourses} from "@/data/requests";

    export default {
        props: ['course'],
        components: {ProgressRound, BaseModal, CurrencyRuble},
        setup(props) {
            let prolongationModal = ref(false);
            let prolongationGroup = ref(null);
            let prolongationGroups = ref([]);
            let buyAdditionalModal = ref(false);
            let buyAdditionalArray = ref([]);
            const token = ref(localStorage.getItem("token"));

            const link = computed(() => {
                const course = props.course;
                if(!course.lessons.length) return false;

                if (!course.paid) return {name: 'payment', params: {id: course.group_id}};
                const lessons = course.lessons.filter(lesson => stringToTime(lesson.date, lesson.time_of) > Date.now());
                const lessonId = lessons.length ? lessons[0].id : course.lessons[0].id;
                return {name: 'lesson', params: {id: course.id, lessonId}};
            });

            const prolongationLink = computed(() => {
                return `${apiHost}courses/${props.course.group_id}/prolongation/${prolongationGroup.value}?api_token=${token.value}`;
            });

            const buyAdditionalPrice = computed(() => {
                return buyAdditionalArray.value.reduce((accumulator, field) => {
                    return accumulator + field.price;
                }, 0)
            });

            const buyAdditionalLink = computed(() => {
                let link = `${apiHost}courses/${props.course.group_id}/additional?api_token=${token.value}`;

                buyAdditionalArray.value.forEach(field => {
                    link += `&custom[${field.custom_id}][]=${field.id}`;
                });

                return link;
            });

            const buyAdditional = ref(props.course.additional_fields.length && props.course.additional_fields.find(field => field.fields.length));

            return {link, prolongationModal, prolongationGroup, numberFormat, prolongationGroups, prolongationLink, buyAdditional, buyAdditionalModal, buyAdditionalArray, buyAdditionalPrice, buyAdditionalLink};
        },
        methods: {
            async openProlongationModal() {
                const courses = await getCourses(this.token);
                const groups = courses.find(course => this.course.id === course.id)?.groups ?? [];
                this.prolongationGroups = groups.filter(group => ((group.no_dates && group.id == this.course.group_id) || (!group.no_dates && group.id != this.course.group_id)));
                this.prolongationModal = true;
            },
            buyAdditionalChange(field) {
                const fieldIndex = this.buyAdditionalArray.indexOf(field);
                if(fieldIndex !== -1) {
                    this.buyAdditionalArray.splice(fieldIndex, 1);
                } else {
                    this.buyAdditionalArray.push(field);
                }
            },
        },
    }
</script>

<style scoped lang="scss">
    .unpaid {
        &::before {
            opacity: .3;
        }
    }

    .need-pay {
        margin-top: 18px;
        margin-bottom: 18px;
        display: flex;
        align-items: center;

        .fa-clock {
            color: #C7CDD1;
            font-size: 24px;
            margin-right: 7px;
        }

        &__text {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #282C2E;
        }
    }

    .documents-link {
        font-size: 10px;
        padding: 5px 15px 5px 15px;
        margin-top: 10px;
        margin-left: 5px;
    }
    .alert-transfer {
        background: #ffdbdb;
        padding: 10px;
        border-radius: 4px;
        margin-top: 20px;
    }
</style>
