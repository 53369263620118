let domains = {
    'cmp.gldev.pro': 'https://admin.cmp.gldev.pro/api/',
    'cmp2.gldev.pro': 'https://admin.cmp2.gldev.pro/api/',
    'cmp.gusi4dev.ru': 'https://admin.cmp.gusi4dev.ru/api/',
    '127.0.0.1': 'https://admin.cmp.gusi4dev.ru/api/',
    'localhost': 'http://cmp.api:8001/api/',
    'lk-sea-law.ru': 'https://admin.lk-sea-law.ru/api/',
    'n21.lk-sea-law.ru': 'https://admin.n21.lk-sea-law.ru/api/',
};
export const apiHost = domains[document.domain] ? domains[document.domain] : 'https://admin.lk-sea-law.ru/api/';
export const apiHeaders = {
    "Content-Type": "application/json",
    "Accept": "application/json"
}
