<template>
  <div class="course-item" :class="{active}">
    <div class="course-item_description">
      <div class="description__title">{{ course.name }}</div>
      <div class="description__desc" v-if="course.address" style="font-style: italic;margin-bottom: 10px;">{{ course.address }}</div>
      <div class="description__desc">{{ course.description }}</div>
      <div class="main-description_footer">
        <div class="item-footer" v-if="ships">
          <div class="item-footer__title">Категории</div>
          <div class="item-footer__desc">{{ ships }}</div>
        </div>
        <div class="item-footer" v-if="forms">
          <div class="item-footer__title">Формы обучения</div>
          <div class="item-footer__desc">{{ forms }}</div>
        </div>
      </div>
    </div>
    <div class="course-item_buy">
      <div class="item-buy">
        <div class="item-buy__title">Стоимость курса</div>
        <div class="item-buy__value">от {{ numberFormat(course.price) }} <CurrencyRuble/></div>
      </div>
      <div class="item-buy" v-if="course.period">
        <div class="item-buy__title">Длительность курса</div>
        <div class="item-buy__value secondary">{{ course.period }}</div>
      </div>
      <div class="item-buy" v-if="course.limit">
        <div class="item-buy__title">Свободных мест</div>
        <div class="item-buy__value secondary">{{ course.limit }}</div>
      </div>
      <div class="item-buy" v-if="course.near_datetime">
        <div class="item-buy__title">Ближайшая дата</div>
        <div class="item-buy__value secondary">{{ course.near_datetime }}</div>
      </div>
      <RouterLink :to="{name: 'payment', params: {id: course.group_for_pay}}" class="button primary" style="margin-bottom:10px" v-if="course.group_for_pay">Продолжить оплату</RouterLink>
      <button class="button secondary" @click="setCourse(course)" v-if="!active">Выбрать курс</button>
      <button class="button primary" @click="setCourse(course)" v-else>Выбрано</button>
    </div>
  </div>
</template>

<script>
import {computed} from "@vue/reactivity";
import {inject} from "@vue/runtime-core";
import numberFormat from "@/helpers/numberFormat";
import CurrencyRuble from "@/components/common/CurrencyRuble";

export default {
  components: {CurrencyRuble},
  props: ["course"],
  setup(props, {emit}) {
    const setupData = inject('setupData');
    const clearSetupData = inject('clearSetupData');
    const updateSetupData = inject('updateSetupData');

    const active = setupData.value.course?.id === props.course.id;
    const ships = computed(() => props.course.customs
        ?.find(el => el.slug === "ship")
        ?.fields.map(el => el.title)
        .join(", ") ?? ""
    );
    const forms = computed(() => props.course.customs
        ?.find(el => el.slug === "form")
        ?.fields.map(el => el.title)
        .join(", ") ?? ""
    );

    function setCourse(course) {
      clearSetupData();
      updateSetupData({course});
      emit('next-page');
    }

    return {ships, forms, numberFormat, setCourse, active}
  }
}
</script>
