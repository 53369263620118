<template>
  <BaseModal :show="show"
             title="Уведомления в телеграм">
    <template #description-slot>
      <p>Подписывайтесь на наш телеграм-бот, чтобы получать важные уведомления мгновенно! <a href="https://t.me/CentrMorskogoPravaBot" target="_blank">https://t.me/CentrMorskogoPravaBot</a></p>
    </template>
    <div class="buttons">
      <a href="https://t.me/CentrMorskogoPravaBot" target="_blank" class="button primary">Подписаться</a>
      <button class="button secondary" type="button" @click="$emit('hide')">Закрыть</button>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from "@/components/common/BaseModal";

export default {
  props: ['show', 'notification'],
  components: {BaseModal},
}
</script>
