<template>
  <div class="main-sidebar" :class="{'main-sidebar-short': false}">
    <div class="main-sidebar_logo">
      <div class="main-sidebar_logo__icon">
        <img class="icon-normal" src="@/assets/img/logo.png" alt="логотип">
        <img class="icon-short" src="@/assets/img/home.svg" alt="логотип">
      </div>
    </div>
    <RouterLink :to="{name: 'user'}" class="main-sidebar_profile" v-if="user">
      <img class="main-sidebar_profile__avatar" :src="user?.avatar_url" alt="аватар" v-if="user?.avatar_url">
      <img class="main-sidebar_profile__avatar" src="@/assets/img/user.png" alt="нет аватара" v-else>
      <div class="main-sidebar_profile__info">
        <div class="main-sidebar_profile__name">{{ user?.first_name }}</div>
        <div class="main-sidebar_profile__role">Ученик</div>
      </div>
    </RouterLink>
    <div class="main-sidebar_menu">
      <RouterLink v-if="user" :to="{name: 'user'}" active-class="item-menu__active" class="item-menu">
        <div class="item-menu__icon"><i class="fas fa-user"></i></div>
        <div class="item-menu__title">Редактировать профиль</div>
      </RouterLink>
      <RouterLink v-if="user && practicesForReview.length > 0" :to="{name: 'practices-review'}" active-class="item-menu__active" class="item-menu">
        <div class="item-menu__icon"><i class="fas fa-user"></i></div>
        <div class="item-menu__title">Оцените преподавателя <span class="item-menu__circle">{{ practicesForReview.length }}</span></div>
      </RouterLink>
      <RouterLink v-if="user" :to="{name: 'notifications'}" active-class="item-menu__active" class="item-menu">
        <div class="item-menu__icon"><i class="fas fa-bell"></i></div>
        <div class="item-menu__title">
          Уведомления <span class="item-menu__circle" v-if="newNotificationsCount">{{ newNotificationsCount }}</span>
        </div>
      </RouterLink>
      <RouterLink v-if="user" :to="{name: 'payments'}" active-class="item-menu__active" class="item-menu">
        <div class="item-menu__icon"><i class="fas fa-receipt"></i></div>
        <div class="item-menu__title">Мои Оплаты</div>
      </RouterLink>
      <RouterLink v-if="user" :to="{name: 'timetable'}" active-class="item-menu__active" class="item-menu">
        <div class="item-menu__icon"><i class="fas fa-home"></i></div>
        <div class="item-menu__title">Расписание</div>
      </RouterLink>
      <RouterLink :to="{name: 'setup'}" active-class="item-menu__active" class="item-menu">
        <div class="item-menu__icon"><i class="fas fa-briefcase"></i></div>
        <div class="item-menu__title">Записаться на теорию</div>
      </RouterLink>
      <RouterLink :to="{name: 'setup-practice'}" active-class="item-menu__active" class="item-menu">
        <div class="item-menu__icon"><i class="fas fa-ship"></i></div>
        <div class="item-menu__title">Записаться на практику</div>
      </RouterLink>
      <RouterLink :to="{name: 'practice-exam'}" active-class="item-menu__active" class="item-menu">
        <div class="item-menu__icon"><i class="fas fa-pencil-alt"></i></div>
        <div class="item-menu__title">Практический экзамен</div>
      </RouterLink>
      <RouterLink :to="{name: 'master-class'}" active-class="item-menu__active" class="item-menu">
        <div class="item-menu__icon"><i class="fas fa-user-friends"></i></div>
        <div class="item-menu__title">Записаться на мастер-класс</div>
      </RouterLink>
      <RouterLink v-if="user" :to="{name: 'practice-batches'}" active-class="item-menu__active" class="item-menu">
        <div class="item-menu__icon"><i class="fas fa-anchor"></i></div>
        <div class="item-menu__title">Оплаченные пакетные занятия</div>
      </RouterLink>
      <RouterLink v-if="content?.tickets" :to="{name: 'tickets'}" active-class="item-menu__active" class="item-menu">
        <div class="item-menu__icon"><i class="fas fa-book-open"></i></div>
        <div class="item-menu__title">Билеты ГИМС</div>
      </RouterLink>
      <RouterLink :to="{name: 'teams'}" active-class="item-menu__active" class="item-menu">
        <div class="item-menu__icon"><i class="fas fa-user-friends"></i></div>
        <div class="item-menu__title">Наша команда</div>
      </RouterLink>
      <RouterLink :to="{name: 'fleet'}" active-class="item-menu__active" class="item-menu">
        <div class="item-menu__icon"><i class="fas fa-ship"></i></div>
        <div class="item-menu__title">Наш флот</div>
      </RouterLink>
      <div class="item-menu_footer">
        <a href="#" class="item-menu" v-if="user" @click.prevent="$emit('showSupport')">
          <div class="item-menu__icon__footer"><i class="fas fa-life-ring"></i></div>
          <div class="item-menu__title">Поддержка</div>
        </a>
        <a href="https://sea-law.ru/kontakty.html" class="item-menu" target="_blank">
          <div class="item-menu__icon__footer"><i class="fas fa-phone-alt"></i></div>
          <div class="item-menu__title">Адрес и контакты</div>
        </a>
        <a href="https://sea-law.ru" class="item-menu" target="_blank">
          <div class="item-menu__icon__footer"><i class="fas fa-globe"></i></div>
          <div class="item-menu__title">Основной сайт</div>
        </a>
        <RouterLink :to="{name: 'auth'}" class="item-menu" v-if="!user">
          <div class="item-menu__icon__footer"><i class="fas fa-user"></i></div>
          <div class="item-menu__title">Войти</div>
        </RouterLink>
        <a href="#" class="item-menu" v-else @click.prevent="logout">
          <div class="item-menu__icon__footer"><i class="fas fa-sign-in-alt"></i></div>
          <div class="item-menu__title">Выйти</div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {inject, onMounted, ref} from "@vue/runtime-core";
import {getPracticesForReviews} from "@/data/requests";

export default {
  emits: ['showSupport'],
  setup() {
    const logout = inject('logout')
    const user = inject('user');
    const token = inject('token');
    const content = inject('siteInfo');
    const newNotificationsCount = inject('new-notifications-count')
    const practicesForReview = ref([])

    onMounted(async () => {
      practicesForReview.value = await getPracticesForReviews(token.value)
    })

    return {user, logout, content, newNotificationsCount, practicesForReview};
  }
}
</script>
<style scoped>
.item-menu__circle {
  background-color: #da482e;
  width: 20px;
  height: 20px;
  line-height: 20px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  color: white;
  font-size: 10px;
  margin-left: 3px;
  padding: 0;
  vertical-align: middle;
}
</style>
