<template>
  <div class="content">
    <div class="content-header">
      <div class="header__text">Привет, {{ user?.first_name }}</div>
      <div class="header__notification">
        <button class="btn_notification" style="display: none">
          <i class="far fa-bell"></i>
        </button>
      </div>
    </div>
    <div class="content-blocks">
      <ClosestLesson :lessons="lessons"/>
      <UnpaidPractice/>
      <div class="blocks__block" v-if="!user?.passport_issued">
        <div class="block__header">Оформление</div>
        <div class="block__desc">Заполните личные данные</div>
      </div>
    </div>

    <div class="content-courses">
      <div class="courses-available">
        <div class="available__header">Твои курсы</div>
        <div class="available__blocks">
          <CourseCard v-for="course in user?.courses" :course="course" :key="course.id" />
        </div>
      </div>

      <Timetable :lessons="lessons" title="Расписание" />
    </div>
  </div>
  <BaseModal :show="isModalShowing"
             @cancel-modal="isModalShowing = false"
             title="Курс не оплачен"
             description="Оплатите, чтобы получить доступ к занятием. Это займет всего 2 минуты.">
    <RouterLink :to="{name: 'payment', params: {id: unpaid.teacher ? 'practices' : unpaid.groupId}}"
                class="button primary">Оплатить</RouterLink>
    <button class="button secondary" @click="isModalShowing = false">Закрыть</button>
  </BaseModal>
  <BaseModal :show="practiceModal"
             @cancel-modal="practiceModal = false"
             :title="`Практика ${unpaid.name}`">
    <template v-slot:description-slot>
      <p>{{unpaid?.category?.description}}</p>
      <p>{{unpaid.address}}</p>


      <div v-if="transferPossible(unpaid)">
        <div class="alert-transfer-possible">
          Вы можете перенести занятие в пакет практики. После этого данное занятие станет не доступным. Занятие из пакета практики вы сможете потратить в любое удобное для вас время.<br><br>
          <button class="button secondary" @click="transferBatchAction(unpaid)">Перенести занятие в пакет</button>
        </div>
      </div>

      <div v-if="transferPossible(unpaid)">
        <div v-if="transferMessage != ''" class="alert-transfer" :class="transferMessageType">{{ transferMessage }}</div>
        <div v-if="possibleTransfers.length" class="alert-transfer-possible">
          <p>Можно перенести занятие до {{new Intl.DateTimeFormat('ru-RU').format(transferPossibleBefore(unpaid))}}</p>
          <select class="button secondary" v-model="transferTo">
            <option disabled value="0">Выберите другое время</option>
            <optgroup v-for="day in possibleTransfers" :key="day.id" :label="day.date">
              <option v-for="time in day.times" :key="time.id" :value="time.id">
                {{ time.time_of }} - {{ time.time_to }}
                <template v-if="time.teacher">{{ teacherShortName(time.teacher) }}</template>
                <template v-if="time.vessel"> ({{ time.vessel }})</template>
              </option>
            </optgroup>
          </select>
          <button class="button secondary" style="margin-left: 5px;" @click="transferLesson(unpaid)">Перенести</button>
        </div>
        <div v-else class="alert-transfer">
          Нет доступных вариантов для переноса занятия
        </div>
      </div>
      <div v-else class="alert-transfer">
        Занятие можно перенести не позднее чем за 3 дня до начала
      </div>
    </template>
    <button class="button secondary" @click="practiceModal = false">Закрыть</button>
  </BaseModal>
</template>

<script>
import Timetable from "@/components/timetable/Timetable";
import CourseCard from "@/components/timetable/CourseCard";
import ClosestLesson from "@/components/timetable/ClosestLesson";
import {inject, provide, ref, watch} from "@vue/runtime-core";
import {computed} from "@vue/reactivity";
import {addCarousel} from "@/helpers/slider";
import BaseModal from "@/components/common/BaseModal";
import UnpaidPractice from "@/components/timetable/UnpaidPractice";
import colors from "@/data/colors";
import {getPossiblePracticesToTransfer, transferBatch, transferLessonTo} from "@/data/requests";
import {teacherShortName} from "@/helpers/teacher";

export default {
  components: {
    Timetable,
    CourseCard,
    ClosestLesson,
    UnpaidPractice,
    BaseModal
  },
  setup() {
    const user = inject('user');
    const tryLoadUser = inject('tryLoadUser');
    const token = inject('token');

    watch(user, addCarousel, {immediate: true});

    const transferBatchAction = async lesson => {
      if(confirm('Вы действительно хотите перенести занятие в пакет практики?')) {
        await transferBatch(token.value, lesson.id)
        window.location.reload();
      }
    }

    const lessons = computed(() => {
      const lections = user.value?.courses.map(course => {
        return course.lessons.filter(lesson => lesson.date && lesson.time_of && lesson.time_to).map(lesson => ({
            ...lesson,
            color: course.color,
            paid: course.paid,
            courseId: course.id,
          }))
      }).flat() ?? [];

      const practices = user.value?.practices.map(el => ({
        ...el,
        category: {...el.category},
        teacher: {...el.teacher},
        color: colors[0]
      })) ?? [];

      if(unpaid.value) {
        for (let practice of practices) {
          if(practice.id === unpaid.value.id) {
            unpaid.value = practice
          }
        }
      }

      return [
          ...lections,
          ...practices
      ].sort((a, b) => a.time_of.localeCompare(b.time_of));
    });

    const isModalShowing = ref(false);
    const unpaid = ref({});
    function setUnpaid(lesson) {
      isModalShowing.value = true;
      unpaid.value = lesson;
    }
    provide('setUnpaid', setUnpaid);

    const practiceModal = ref(false);
    const possibleTransfers = ref([]);
    function setPractice(lesson) {
      practiceModal.value = true;
      unpaid.value = lesson;
    }

    watch(unpaid, (lesson) => {
      if(lesson.id) {
        getPossiblePracticesToTransfer(token.value, lesson).then(result => {
          possibleTransfers.value = result
        })
      }
    }, {immediate: true});

    provide('showPracticeModal', setPractice);

    const transferPossibleBefore = (lesson) => {
      var parts = lesson?.date.split(".");
      var dt = new Date(parseInt(parts[2], 10),
          parseInt(parts[1], 10) - 1,
          parseInt(parts[0], 10));

      return new Date((dt.getTime()) - (60 * 60 * 24 * 3 * 1000));
    }

    const transferPossible = function(lesson) {
      if(transferPossibleBefore(lesson).getTime() > +new Date()) {
        return true;
      } else {
        return false;
      }
    }

    const transferTo = ref(0);
    const transferMessage = ref('');
    const transferMessageType = ref('success');
    const transferLesson = function(lesson) {
      if(parseInt(transferTo.value) <= 0) {
        transferMessage.value = 'Вы не выбрали время для переноса'
        transferMessageType.value = 'error'

        setTimeout(() => {
          transferMessage.value = '';
        }, 5000)
        return;
      }

      transferLessonTo(token.value, lesson, transferTo.value)
          .then((resp) => {
            transferMessage.value = resp.data
            transferMessageType.value = resp.code === 200 ? 'success':'error'
            transferTo.value = 0
            tryLoadUser()
          })
         .catch((e) => {
           transferMessage.value = 'error'
           transferMessageType.value = 'error'
           console.error(e)
         })
        .finally(() => {
          setTimeout(() => {
            transferMessage.value = '';
          }, 5000)
        })
    }

    return {user, lessons, isModalShowing, unpaid, practiceModal, possibleTransfers, transferPossible, transferPossibleBefore, transferTo, transferLesson, transferMessage, transferMessageType, teacherShortName, transferBatchAction}
  }
}
</script>

<style scoped>
.alert-transfer {
  background: #ffdbdb;
  padding: 10px;
  border-radius: 4px;
  margin-top: 20px;
}

.alert-transfer.success {
  background: #dbffe6;
}

.alert-transfer.error {
  background: #ffdbdb;
}

.alert-transfer-possible {
  background: #dbffe6;
  padding: 10px;
  border-radius: 4px;
  margin-top: 20px;
}

.alert-transfer-possible p {
  margin-bottom: 10px;
}
</style>
