<template>
  <div class="content">
    <div class="content-header">
      <div class="content-header__title">Сформируй курс</div>
      <button class="content-header__close" @click="$emit('clearSetup')"><i class="fas fa-times"></i></button>
    </div>
    <div class="content-main">
      <StepItem v-for="step in data" :key="step.id" :step="step" :title="true"/>
    </div>
  </div>
</template>

<script>
import StepItem from "@/components/setup/StepItem";

export default {
  props: ["data"],
  components: {StepItem}
}
</script>