export const addCarousel = () => {
    try {
        const contentBlocks = document.querySelector('.content-blocks');
        const availableBlocks = document.querySelector('.available__blocks');
        const screenWidth = document.documentElement.clientWidth;

        if (screenWidth <= 1025){
            contentBlocks.classList.add('single-item');
            availableBlocks.classList.add('single-item');
        }
        // eslint-disable-next-line no-undef
        $('.single-item:not(.slick-initialized)').slick();
    } catch {
        console.log("carousel not initiated");
    }
}




