<template>
  <div class="content">
    <div class="content-header">
      <div class="content-header__title">Оплаченные пакетные занятия</div>
    </div>
    <div class="select-city">
      <i class="fas fa-map-marker-alt"></i>
      <div class="select-city-item"
           v-for="city in cities" :key="city.id"
           @click="city_id = city.id"
           :class="{ 'active': city_id === city.id }"
      >
        {{ city.name }}
      </div>
    </div>
    <div class="content-main" v-if="isLoad">
      <div class="item-main">
        <div class="item-main_blocks">
          <CourseOption v-for="option in dataFilters" :key="option.id" :option="option" type="checkbox" :disabled="isDisabled(option.id)">
            <template #default>
              <input type="checkbox" v-model="practiceIds" :value="option.id">
            </template>
          </CourseOption>
        </div>
        <div v-if="dataFilters.length === 0">
          Нет пакетных занятий
        </div>
      </div>
    </div>
    <Preloader v-else />
  </div>
</template>

<script>
import CourseOption from "@/components/batch/CourseOption";
import {computed} from "@vue/reactivity";
import {onMounted, ref} from "@vue/runtime-core";
import {getCities} from "@/data/requests";
import Preloader from "@/components/common/Preloader.vue";

export default {
  components: {Preloader, CourseOption },
  props: ['data', 'modelValue', 'isLoad'],
  data() {
    return {
      city_id: 1
    };
  },

  setup(props, {emit}) {
    function findPracticeId(id) {
      return practiceIds.value.find(item => item == id)
    }

    function isDisabled(id) {
      return !findPracticeId(id) && practiceIds.value.length > 0
    }

    const practiceIds = computed({
      get: () => props.modelValue.practiceIds ?? [],
      set: value => emit('update:modelValue', {practiceIds: value})
    });

    const cities = ref([]);

    onMounted(async function loadData() {
      cities.value = await getCities();
    });

    return {practiceIds, isDisabled, cities};
  },

  computed: {
    dataFilters() {
      return this.data.filter(obj => {
        return obj.purchased > 0 && obj.city?.id === this.city_id;
      });
    }
  },
}
</script>

<style scoped>

</style>