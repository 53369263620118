<template>
    <div class="preloader">
      <div class="loader"></div>
    </div>
</template>

<style scoped lang="scss">
.preloader {
  width: 100%;
  max-width: 700px;
  margin-top: 100px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader {
  border: 5px solid transparent;
  border-top: 5px solid #00669c;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.teams-item-preload {
  animation: preload .7s linear infinite;
}
@keyframes preload {
  0% { opacity: 0; }
  100% { opacity: 1;}
}
</style>
