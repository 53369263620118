<template>
  <div class="slider">
    <slot></slot>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
.slider {
  overflow: auto;
  cursor: default;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  padding: 20px 40px;
  background: white;
  max-width: 540px;
  box-shadow: 0 0 10px #0003;
  @media screen and (max-width: 1025px) {
    top: 48px;
    left: 0;
    bottom: 80px;
    max-width: 100%;
    padding: 20px;
  }
}
</style>
