<template>
  <svg width="48" height="48">
    <circle r="20" cx="50%" cy="50%" fill="none" stroke="#F2F2F2" stroke-width="2" />
    <circle ref="circle" class="progress-circle" r="20" cx="50%" cy="50%" fill="none" :stroke="color" stroke-width="3"
            :stroke-dasharray="length" :stroke-dashoffset="offset" transform="rotate(-90 0 0)" />
    <text x="24" y="28" font-size="10" text-anchor="middle">{{ progress }}%</text>
  </svg>
</template>

<script>
import {nextTick, onMounted, ref} from "@vue/runtime-core";
import {computed, toRefs} from "@vue/reactivity";

export default {
  props: ['progress', 'color'],
  setup(props) {
    const {progress, color} = toRefs(props);

    const circle = ref(null);
    const length = ref(0);

    onMounted(async () => {
      await nextTick();
      length.value = circle.value.getTotalLength();
    });

    const offset = computed(() => length.value * (1 - progress.value / 100));

    // eslint-disable-next-line vue/no-dupe-keys
    return {progress, color, circle, offset, length}
  }
}
</script>

<style scoped>
.progress-circle {
  transition: stroke-dashoffset 1s ease-in-out;
}
</style>