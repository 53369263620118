<template>
  <div class="content">
    <div class="content-header">
      <div class="content-header__title">{{ data.title }}</div>
      <button class="content-header__close" @click="$emit('clearSetup')"><i class="fas fa-times"></i></button>
    </div>
    <div class="content-main">
      <StepItem :step="data"/>
    </div>
  </div>
</template>

<script>
import StepItem from "@/components/setup/StepItem";

export default {
  components: {StepItem},
  props: ["data"],
}
</script>