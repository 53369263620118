<template>
  <div>
    <label class="wrapper">
      <BaseCheckbox v-model="checkbox"/>
      <span class="text" v-if="$slots.default"><slot/></span>
      <span class="text" v-else>{{ text }}</span>
    </label>
    <small v-if="error" class="error">{{ error }}</small>
  </div>
</template>

<script>
import {computed} from "@vue/reactivity";
import BaseCheckbox from "@/components/common/BaseCheckbox";

export default {
  components: {BaseCheckbox},
  props: ["text", "modelValue", "error"],
  setup(props, {emit}) {
    const checkbox = computed({
      get: () => props.modelValue,
      set: value => emit('update:modelValue', value)
    });
    return {checkbox}
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  display: flex;
  align-items: center;
}
.text {
  font-size: 12px;
  line-height: 16px;
  color: #51585c;
  margin-left: 8px;
}
.error {
  color: red;
  margin-top: 8px;
  display: block;
  font-size: 12px;
}
</style>
