import {getColor} from "@/data/colors";
import {stringToTime} from "@/helpers/date";
import {getUserCourses, getUserPractices, getUserProfile} from "@/data/requests";

const addLessonInfo = (color, now, courseName, groupId) => lesson => ({
  ...lesson,
  passed: stringToTime(lesson.date, lesson.time_to) < now,
  color,
  courseName,
  groupId
})

const lessonComparator = (a, b) => stringToTime(a.date, a.time_to) - stringToTime(b.date, b.time_to);

const addCourseInfo = now => (course, i) => {
  const color = getColor(i + 1);
  const lessons = course.lessons
    .map(addLessonInfo(color, now, course.name, course.group_id))
    .sort(lessonComparator);
  const passedCount = lessons.filter(el => el.passed).length;

  return {
    ...course,
    color,
    progress: passedCount > 0 ? Math.round(passedCount / lessons.length * 100) : 0,
    lessons
  }
}

const addPracticeInfo = now => practice => {
  return {
    ...practice,
    passed: stringToTime(practice.date, practice.time_to) < now
  }
}

export const getPreparedUserData = async token => {
  const [profile, rawCourses, rawPractices] = await Promise.all([
    await getUserProfile(token),
    await getUserCourses(token),
    await getUserPractices(token)
  ]);

  const courses = rawCourses
    .map(addCourseInfo(Date.now()))
    .sort((a, b) => b.paid - a.paid);

  const practices = rawPractices
    .map(addPracticeInfo(Date.now()))

  return {
    ...profile,
    courses,
    practices
  };
}
export const prepareUserEditForm = form => {
  if (!form) {
    return {};
  }
  let data = {...form}

  let bd = null;
  if(data.birth_date) {
    bd = new Date()
    let [d,m,y] = data.birth_date.split('.')
    bd.setFullYear(y,m-1,d)
  }
  data.birth_date = bd;

  let passport_issued_at = null;
  if(data.passport_issued_at) {
    passport_issued_at = new Date()
    let [d,m,y] = data.passport_issued_at.split('.')
    passport_issued_at.setFullYear(y,m-1,d)
  }
  data.passport_issued_at = passport_issued_at

  return data
}
