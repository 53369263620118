<template>
  <div class="content">
    <div class="content-header">
      <div class="content-header__title">Курсы</div>
      <div class="header__notification">
        <button class="btn_notification" style="display:none;"><i class="far fa-bell"></i></button>
      </div>
    </div>
    <div class="filter">
      <div class="filter-option">
        <RouterLink class="button big light" active-class="active" :to="{name: 'setup'}">Теория</RouterLink>
        <RouterLink class="button big light" active-class="active" :to="{name: 'master-class'}">Мастер-класс</RouterLink>
        <RouterLink class="button big light" active-class="active" :to="{name: 'setup-practice'}">Практика</RouterLink>
        <RouterLink class="button big light" active-class="active" :to="{name: 'practice-exam'}">Практический экзамен</RouterLink>
        <RouterLink class="button big light" :to="{name: 'tickets'}">Билеты</RouterLink>
        <RouterLink class="button big light" :to="{name: 'practice-batches'}">Оплаченные пакетные занятия</RouterLink>
      </div>
    </div>
    <div class="select-city">
      <i class="fas fa-map-marker-alt"></i>
      <div class="select-city-item"
           v-for="city in cities" :key="city.id"
           @click="city_id = city.id"
           :class="{ 'active': city_id === city.id }"
      >
        {{ city.name }}
      </div>
      <template v-if="0">
        <span class="batch-separator"></span>
        <div class="select-city-item batch" :class="{ 'active': packageBuy }" @click="packageBuy = ~~(!packageBuy);">
          <i class="fas fa-check"></i>
          Пакетный курс
          <span class="select-city-item-question">?</span>
          <span class="select-city-item-answer">Приобретайте необходимое количество занятий и записывайтесь на них в удобное для вас время</span>
        </div>
      </template>
    </div>
    <div class="content-main">
      <div class="item-main">
        <div v-for="category in categories" :key="category.id">
          <h2 class="category-title" v-if="practices.filter(practice => practice.category.id === category.id).length">{{ category.title }}</h2>
          <p v-if="category.notice">{{ category.notice }}</p>
          <div class="item-main_blocks">
            <CourseOption v-for="option in practices.filter(practice => practice.category.id === category.id)" :key="option.id" :option="option" type="checkbox" :disabled="isDisabled(option.id, option.batches.length)" :packages="packageBuy" :is-practice="true" :full-height="packageBuy && findPracticeId(option.id)">
              <template #default>
                <input type="checkbox" v-model="practiceIds" :value="option.id">
              </template>
              <template #input>
                <template v-if="!findPracticeId(option.id) && option.batches.length && !practiceIds.length">
                  <a href="#" class="batch-card" v-if="!packageBuy" @click.prevent="packageSet(true, option.id)">Пакетный курс</a>
                  <a href="#" class="batch-card" v-if="packageBuy" @click.prevent="packageSet(false, option.id)">Обычные занятия</a>
                </template>
                <div class="package-inline" v-if="packageBuy && findPracticeId(option.id)">
                  <div class="package" v-for="batche in option.batches" :key="batche">
                    <div class="item-main_block_header">
                      <label class="item-main_block__checkbox radio">
                        <input type="radio" v-model="packageCount" name="radio" :value="batche.count">
                        <span class="item-main_block__checkmark"></span>
                      </label>
                      <div class="item-main_block__title">{{ batche.count + ' ' + numberDecl(batche.count, ['затятие', 'занятия', 'занятий']) }}</div>
                    </div>
                    <div>{{ numberFormat(batche.price) }} <CurrencyRuble /></div>
                  </div>
                </div>
              </template>
            </CourseOption>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import numberFormat from "@/helpers/numberFormat";
import numberDecl from "@/helpers/numberDecl";
import CourseOption from "@/components/setup/CourseOption";
import {computed} from "@vue/reactivity";
import {onMounted, ref, watch} from "@vue/runtime-core";
import CurrencyRuble from "@/components/common/CurrencyRuble";
import {getCities} from "@/data/requests";
import {useRouter} from "vue-router";

export default {
  components: {
    CourseOption,
    CurrencyRuble
  },
  props: ["data", "modelValue", "package"],

  data() {
    return {
      city_id: 1
    };
  },

  setup(props, {emit}) {

    const packageCount = computed({
      get: () => props.package.packageCount,
      set: value => emit('packageCountUpdate', value)
    })
    const packageBuy = computed({
      get: () => props.package.packageBuy ?? false,
      set: value => emit('packageUpdate', value)
    })
    const packageSet = (set, id) => {
      packageBuy.value = set
      practiceIds.value = [ id ]
      packageCount.value = 0
    }
    const packageOld = ref(null)
    const router = useRouter()
    const isExam = ref(false)

    watch(router.currentRoute, () => {
      isExam.value = router.currentRoute.value.name === 'practice-exam'
    })

    watch(packageBuy,() => {
      if (!packageBuy.value) {
        practiceIds.value = []
      }
    })

    function findPracticeId(id) {
      return practiceIds.value.find(item => item === id)
    }

    function isDisabled(id, batchesCount) {
      return packageBuy.value && !findPracticeId(id) && practiceIds.value.length > 0 || packageBuy.value && batchesCount === 0
    }

    const practiceIds = computed({
      get: () => props.modelValue.practiceIds ?? [],
      set: value => {
        if (!value.length && packageBuy.value) {
          packageBuy.value = false
        }
        if(packageBuy.value) {
          if(packageOld.value === null || packageOld.value !== value)
            packageCount.value = 0
          packageCount.value = 1
          if(value.length > 0)
            packageCount.value = props.data.find(item => item.id === value[0]).batches[0].count
        }
        emit('update:modelValue', {practiceIds: value})
      }
    });

    const categories = computed(() => {
      let arrayOfCategories = [];

      props.data.forEach(practice => {
        if(!arrayOfCategories.find(category => practice.category.id === category.id)) {
          arrayOfCategories.push(practice.category);
        }
      });

      return arrayOfCategories.sort((a, b) => a.order - b.order);
    });

    const cities = ref([]);

    onMounted(async function loadData() {
      cities.value = await getCities();
    });

    return {cities, numberFormat, practiceIds, packageCount, findPracticeId, isDisabled, packageBuy, numberDecl, categories, isExam, packageSet};
  },

  computed: {
    practices() {
      return this.data.filter((practice) => {
        return practice.city?.id === this.city_id;
      });
    }
  },
}
</script>

<style lang="scss">
.package-inline {
  display: block;
  .package {
    display: flex;
    color: black;
    font-size: 16px;
    border-radius: 5px;
    line-height: 24px;
    font-weight: 100;
    justify-content: space-between;
    &-minus, &-plus, &-count {
      padding: 5px 10px;
    }
    &-count {
      min-width: 40px;
      max-width: 40px;
      text-align: center;
    }
  }
}
.category-title {
  margin-top: 30px;
}
.batch-separator {
  height: 20px;
  width: 1px;
  background: #a1aeaf;
  margin-right: 10px;
}
.batch-card {
  position: absolute;
  bottom: 40px;
  right: 14px;
  font-size: 14px;
  color: #000;
}
</style>