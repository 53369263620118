<template>
  <div class="content">
    <div class="content-header">
      <div>
        <div class="content-header__title">Восстановление пароля</div>
        <div class="content-header__subtitle">Введите Ваш email для отправки дальнейших инструкций</div>
      </div>
    </div>
    <div class="content-form">
      <form @submit.prevent="submit">
        <div class="success" v-if="success">{{ success }}</div>
        <template v-else>
          <FormField :error="errors.email">
            <input class="input" type="text" v-model="email" placeholder="Email">
          </FormField>
          <button class="button primary wide">Отправить</button>
        </template>
      </form>
    </div>
    <div class="content-offer">
      <span class="content-offer__text">Вспомнили пароль?</span>
      <RouterLink class="button secondary wide" :to="{name: 'auth'}">Войти</RouterLink>
    </div>
  </div>
</template>

<script>
import FormField from "@/components/common/FormField";
import {ref} from "@vue/runtime-core";
import {forgotPassword} from "@/data/requests";

export default {
  components: {
    FormField,
  },
  setup() {
    const errors = ref({});

    const email = ref('');
    const success = ref('');

    async function submit() {
      const {data, code} = await forgotPassword(email.value);

      if (code === 200) {
        success.value = 'Запрос отправлен успешно. Проверьте вашу электронную почту.';
        return;
      }

      errors.value = data.errors;
    }

    return {errors, email, success, submit}
  }
}
</script>

<style scoped lang="scss">
.content-form {
  max-width: 360px;
  @media all and (max-width: 1025px) {
    max-width: 100%;
  }
  .button {
    margin-top: 10px;
  }
}

.content-offer {
  max-width: 360px;
  width: calc(100% - 30px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 42px;

  &__text {
    font-size: 12px;
    line-height: 16px;
    color: #51585C;
  }

  .button {
    width: 50%;
    box-sizing: border-box;
  }
}
</style>
