export const teacherShortName = teacher => (teacher?.first_name)
  ? `${teacher?.last_name} ${teacher?.first_name?.substr(0, 1)}. ${teacher?.middle_name?.substr(0, 1)}.`
  : '';
export const teacherFullName = teacher => (teacher?.first_name)
  ? `${teacher?.last_name} ${teacher?.first_name} ${teacher?.middle_name}`
  : '';
export const teacherNameToObject = name => {
  if (name instanceof Object) return name;
  const [last_name, first_name, middle_name] = name.split(' ');
  return {last_name, first_name, middle_name};
}

