import {createRouter, createWebHistory} from 'vue-router'
import routes from "@/router/routes";

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to) => {
  if (to.meta.requiresAuth && !localStorage.getItem('token')) {
    return {
      name: 'auth',
      query: { redirect: to.fullPath },
    }
  }
})

export default router
