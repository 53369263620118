<template>
  <div class="content">
    <div class="content-header">
      <div class="content-header__title">Оплата забронированной практики</div>
      <RouterLink :to="{name: 'timetable'}" class="content-header__close"><i class="fas fa-times"></i></RouterLink>
    </div>
    <div class="content-main">
      <Timetable :lessons="lessons"/>
      <div class="item-menu">
        <div class="item-menu_footer item-menu_footer-practice">
          <div class="item-footer_payment">
            <div class="item-footer_payment__img"><i class="far fa-clock"></i>
            </div>
            <div class="item-footer_payment_desc">
              <div class="item-footer_payment_desc__title">Практика ожидает оплаты</div>
              <div class="item-footer_payment_desc__description">При отсутствии оплаты бронирование отменится через 20 минут</div>
            </div>
          </div>
          <div class="item-menu__btn">
            <button class="button secondary big" style="display: none">Изменить заказ</button>
          </div>
        </div>
      </div>
      <div class="item-main_pay">
        <div class="item-main_header">
          <div class="item-main_payment">
            <div class="item-main_payment__title">
              Оплачивая вы соглашаетесь с условиями
            </div>
            <div class="item-main_date__title"></div>
            <div class="item-main_payment__address">
              <div class="item-main_payment__address__icon">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M6.99967 1.75001C4.10018 1.75001 1.74967 4.10051 1.74967 7.00001C1.74967 9.8995 4.10018 12.25 6.99967 12.25C9.89917 12.25 12.2497 9.8995 12.2497 7.00001C12.2497 4.10051 9.89917 1.75001 6.99967 1.75001ZM0.583008 7.00001C0.583008 3.45618 3.45585 0.583344 6.99967 0.583344C10.5435 0.583344 13.4163 3.45618 13.4163 7.00001C13.4163 10.5438 10.5435 13.4167 6.99967 13.4167C3.45585 13.4167 0.583008 10.5438 0.583008 7.00001Z" fill="#07A4B2"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M6.99967 2.91668C7.32184 2.91668 7.58301 3.17784 7.58301 3.50001V6.63949L9.59388 7.64493C9.88204 7.789 9.99883 8.1394 9.85476 8.42755C9.71068 8.7157 9.36029 8.8325 9.07213 8.68843L6.7388 7.52176C6.54118 7.42295 6.41634 7.22096 6.41634 7.00001V3.50001C6.41634 3.17784 6.67751 2.91668 6.99967 2.91668Z" fill="#07A4B2"/>
                </svg>
              </div>
              <div class="item-main_payment__address__text">Вы не сможете перенести или отменить практику менее чем за три дня до начала практики</div>
            </div>
            <div class="item-main_payment__address">
              <div class="item-main_payment__address__icon">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M0.854272 9.02096C1.40125 8.47398 2.14312 8.16669 2.91667 8.16669H7C7.77355 8.16669 8.51541 8.47398 9.06239 9.02096C9.60938 9.56794 9.91667 10.3098 9.91667 11.0834V12.25C9.91667 12.5722 9.6555 12.8334 9.33333 12.8334C9.01117 12.8334 8.75 12.5722 8.75 12.25V11.0834C8.75 10.6192 8.56563 10.1741 8.23744 9.84592C7.90925 9.51773 7.46413 9.33335 7 9.33335H2.91667C2.45254 9.33335 2.00742 9.51773 1.67923 9.84592C1.35104 10.1741 1.16667 10.6192 1.16667 11.0834V12.25C1.16667 12.5722 0.905499 12.8334 0.583333 12.8334C0.261167 12.8334 0 12.5722 0 12.25V11.0834C0 10.3098 0.307291 9.56794 0.854272 9.02096Z" fill="#07A4B2"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M4.95833 2.33335C3.99183 2.33335 3.20833 3.11686 3.20833 4.08335C3.20833 5.04985 3.99183 5.83335 4.95833 5.83335C5.92483 5.83335 6.70833 5.04985 6.70833 4.08335C6.70833 3.11686 5.92483 2.33335 4.95833 2.33335ZM2.04167 4.08335C2.04167 2.47252 3.3475 1.16669 4.95833 1.16669C6.56916 1.16669 7.875 2.47252 7.875 4.08335C7.875 5.69418 6.56916 7.00002 4.95833 7.00002C3.3475 7.00002 2.04167 5.69418 2.04167 4.08335Z" fill="#07A4B2"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M13.8291 4.83754C14.057 5.06535 14.057 5.43469 13.8291 5.6625L11.4958 7.99583C11.268 8.22364 10.8987 8.22364 10.6709 7.99583L9.50419 6.82917C9.27638 6.60136 9.27638 6.23201 9.50419 6.00421C9.73199 5.7764 10.1013 5.7764 10.3291 6.00421L11.0833 6.7584L13.0042 4.83754C13.232 4.60974 13.6013 4.60974 13.8291 4.83754Z" fill="#07A4B2"/>
                </svg>
              </div>
              <div class="item-main_payment__address__text">Если Вы записываетесь в день практики, то позвоните в офис и уточните возможность записи</div>
            </div>
            <div class="item-main_payment__address" v-if="user">
              <FormCheckbox v-model="useBonus">
                <span class="item-main_payment__address__text">Использовать {{ user.bonus }} {{ numberDecl(user.bonus, ['балл', 'балла', 'баллов']) }}</span>
              </FormCheckbox>
            </div>

            <div class="item-main_payment__address">
              <FormCheckbox v-model="agree" :error="error_agree">
                <span class="item-main_payment__address__text">Я ознакомлен и согласен с условиями</span>
              </FormCheckbox>
            </div>
          </div>

          <div class="item-main_price">
            <div class="item-main_price__title">
              Стоимость обучения
            </div>
            <div class="item-main_price__price">{{ numberFormat(data.price) }} <CurrencyRuble/></div>
          </div>
        </div>
        <div class="item-main_footer">
          <div class="item-main_footer_btn">
            <button class="button primary" disabled style="display: none">Отменить выбор практики</button>
          </div>
          <div class="item-main_footer_btn">
            <button @click="goPay" v-if="data.price" class="button primary" :class="{disabled: !agree}">Перейти для оплаты</button>
            <button @click="goPayBonus" v-else class="button primary" :class="{disabled: !agree}">Оплатить баллами</button>
          </div>

        </div>
      </div>
    </div>
  </div>
  <BaseModal :show="isModalShowing"
             @cancel-modal="isModalShowing = false"
             title="Отказ от занятия практики"
             description="Вы уверены, что хотите отказаться?">
    <button class="button secondary red" @click="removeLesson">Удалить</button>
    <button class="button secondary" @click="isModalShowing = false">Закрыть</button>
  </BaseModal>
</template>

<script>
import numberFormat from "@/helpers/numberFormat";
import colors from "@/data/colors";
import {computed} from "@vue/reactivity";
import {inject, provide, ref, watch} from "@vue/runtime-core";
import Timetable from "@/components/timetable/Timetable";
import BaseModal from "@/components/common/BaseModal";
import {payPractice, payPracticeBonus, removePractice} from "@/data/requests";
import CurrencyRuble from "@/components/common/CurrencyRuble";
import FormCheckbox from "@/components/common/FormCheckbox";
import numberDecl from "../../helpers/numberDecl";
import {useRouter} from "vue-router";

export default {
  methods: {numberDecl},
  props: ["data"],
  emits: ["refresh"],
  components: {CurrencyRuble, Timetable, BaseModal, FormCheckbox},
  setup(props, {emit}) {
    const user = inject('user');
    const router = useRouter()
    const removed = ref([]);
    const useBonus = ref(false);
    const lessons = computed(() => {
      const practices = props.data.lessons?.map(el => ({
        ...el,
        category: {...el.category},
        color: colors[0],
        removable: true
      })) ?? [];

      return practices
          .filter(el => !removed.value.includes(el.id))
          .sort((a, b) => a.time_of.localeCompare(b.time_of));
    });

    watch(useBonus, () => {
      emit('bonus', useBonus.value)
    })

    const isModalShowing = ref(false);
    const removeItem = ref({});
    function setUnpaid(lesson) {
      isModalShowing.value = true;
      removeItem.value = lesson;
    }
    provide('setUnpaid', setUnpaid);

    const token = inject('token');
    async function removeLesson() {
      isModalShowing.value = false;
      removed.value.push(removeItem.value.id);
      await removePractice(token.value, removeItem.value.id);
      removeItem.value = {};
      emit('refresh');
    }

    async function goPay() {
      if(!agree.value) {
        error_agree.value = 'Вы должны принять условия'
        setTimeout(() => {
          error_agree.value = ''
        }, 3000)
        return false;
      }

      const res = await payPractice(token.value, useBonus.value)
      location.replace(res.url)
    }

    async function goPayBonus() {
      if(!agree.value) {
        error_agree.value = 'Вы должны принять условия'
        setTimeout(() => {
          error_agree.value = ''
        }, 3000)
        return false;
      }

      const res = await payPracticeBonus(token.value)

      if (res?.status) {
        await router.push({path: '/timetable'})
      } else {
        alert('Ошибка. Обратитесь в поддержку')
      }
    }

    const error_agree = ref('');
    const agree = ref(false);

    return {lessons, numberFormat, isModalShowing, removeLesson, goPay, error_agree, agree, user, useBonus, goPayBonus}
  }
}
</script>
