<template>
  <div class="content content-user_info_page">
    <div class="content-header">
      <button class="content-header__exit" @click="logout">Выйти</button>
      <RouterLink :to="{name: 'timetable'}" class="content-header__close"><i class="fas fa-times"></i></RouterLink>
    </div>
    <div class="content-main">
      <div class="user-info">
        <div class="user-info_name">
          <div class="user-info_fullname">История оплат</div>
        </div>
        <div class="user-info__group" v-if="payments.length > 0">
          <table class="table-payments">
            <thead>
              <tr>
                <th style="width: 150px;text-align: left;">Номер платежа</th>
                <th style="width: 150px;text-align: left">Тип занятия</th>
                <th style="text-align: left;">Покупка</th>
                <th style="width: 150px;">Скидка</th>
                <th style="width: 150px;">Стоимость</th>
                <th style="width: 130px; text-align: right">Дата</th>
              </tr>
            </thead>
            <tr v-for="payment in payments" :key="payment.id">
              <td style="text-align: left;">#{{payment.id}}</td>
              <td style="text-align: left;">{{ paymentTypes[payment.type] }}</td>
              <td style="text-align: left;">
                <template v-if="payment.type == 'theory' || payment.type == 'theory-prolongation' || payment.type == 'theory-additional'">
                  {{ payment.course }}
                </template>
                <template v-if="payment.type == 'practice-batch' && payment.practice_batch">
                  <p>
                    {{ payment.practice_batch.practice_name }} - {{ payment.practice_batch.count }} шт.
                  </p>
                </template>
                <template v-else>
                  <p v-for="practice in payment.practices" :key="practice.name" style="margin-bottom: 5px">
                    {{ practice.practice.name }}<br>
                    <span style="opacity: 0.7;font-size: 14px">{{ practice.date.date }} {{ practice.lesson.time_of }} - {{ practice.lesson.time_to }}</span>
                  </p>
                </template>
              </td>
              <td>{{numberFormat(payment.discount)}} <CurrencyRuble/></td>
              <td>{{numberFormat(payment.price)}} <CurrencyRuble/></td>
              <td style="text-align: right">{{payment.created_at}}</td>
            </tr>
          </table>
        </div>
          <div class="user-info__group" v-else>
            <div class="item-main_pay alert">
              <div class="item-main__title">
                {{isLoading ? 'Загрузка...':'У вас нет оплаченных занятий'}}
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
  <div class="support" style="display: none">
    <button class="support_btn"><i class="far fa-comment-alt"></i></button>
  </div>
</template>

<script>
import {inject, onMounted, ref} from "@vue/runtime-core";
import {getPayments} from "@/data/requests";
import CurrencyRuble from "@/components/common/CurrencyRuble";
import numberFormat from "@/helpers/numberFormat";

export default {
  components: {
    CurrencyRuble
  },
  setup() {
    const logout = inject('logout');
    const user = inject('user');
    const token = inject('token');

    const payments = ref([])
    const isLoading = ref(true)
    const paymentTypes = {
      theory: 'Теория',
      practice: 'Практика',
      'practice-batch': 'Пакет практики',
      'theory-prolongation': 'Пролонгация курса',
      'theory-additional': 'Расширение курса'
    };

    async function loadData() {
      payments.value = await getPayments(token.value);
      isLoading.value = false
    }
    onMounted(loadData);

    return {logout, user, payments, numberFormat, isLoading, paymentTypes}
  }
}
</script>
<style scoped lang="scss">
.wrapper {
  width: 200px;
  box-sizing: border-box;
  @media screen and (max-width: 1025px) {
    width: auto;
  }
}
.button {
  margin-top: 5px;
  margin-right: 10px;
}
</style>
