<template>
  <div class="calendar">
    <template v-if="teachersVisible">
      <div class="calendar__title calendar__title-between">
        <span>{{ title }}</span>
        <div class="input-wrapper">
          <select v-model="selectedTeacher" class="input">
            <option value="0">Все инструкторы</option>
            <option v-for="teacher in teachers" :value="`${teacher.last_name} ${teacher.first_name} ${teacher.middle_name}`" :key="`${teacher.last_name} ${teacher.first_name} ${teacher.middle_name}`">{{ teacher.last_name + ' ' + teacher.first_name + ' ' + teacher.middle_name }}</option>
          </select>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="calendar__title">{{ title }}</div>
    </template>
    <div class="calendar__table">
      <div class="calendar__month">
        <div class="month__back">
          <button class="btn_left" @click="prev">
            <i class="fas fa-chevron-left"></i>
          </button>
        </div>
        <div class="month__name">{{ monthName }} {{ year }}</div>
        <div class="month__forward">
          <button class="btn_right" @click="next">
            <i class="fas fa-chevron-right"></i>
          </button>
        </div>
      </div>
      <table class="table">
        <thead>
        <tr class="week-names">
          <th><span>Пн</span></th>
          <th><span>Вт</span></th>
          <th><span>Ср</span></th>
          <th><span>Чт</span></th>
          <th><span>Пт</span></th>
          <th><span>Сб</span></th>
          <th><span>Вс</span></th>
        </tr>
        </thead>
        <tbody>
        <tr class="week" v-for="(week, weekIndex) in timetable" :key="weekIndex">
          <template v-if="!practice">
            <TimetableCell v-for="(day, dayIndex) in week" :key="weekIndex * 7 + dayIndex"
                           :day="day" :selected="selected" @select-cell="selected = $event"/>
          </template>
          <template v-else>
            <TimetablePracticeCell v-for="(day, dayIndex) in week" :key="weekIndex * 7 + dayIndex"
                                   :day="day" :selected="selected" @select-cell="selected = $event"
                                   :selectedTeacher="selectedTeacher"
            />
          </template>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {computed} from "@vue/reactivity";
import chunk from "@/helpers/chunk";
import {ref} from "@vue/runtime-core";
import months from "@/data/months";
import {combineSchedule, computeCalendarDays} from "@/helpers/calendar";
import TimetableCell from "@/components/timetable/TimetableCell";
import TimetablePracticeCell from "@/components/timetable/TimetablePracticeCell";

export default {
  props: ['startMonth', 'startYear', 'lessons', 'title', 'practice', 'teachersVisible'],
  components: {
    TimetableCell,
    TimetablePracticeCell
  },
  setup(props) {
    const now = new Date();
    const month = ref(props.startMonth ?? now.getMonth());
    const year = ref(props.startYear ?? now.getFullYear());
    const selectedTeacher = ref('0');

    const timetable = computed(() => {
      const days = computeCalendarDays(month.value, year.value);
      const schedule = combineSchedule(days, props.lessons.filter(lesson => {
        let lessonAccept = false;

        lesson.times?.forEach(time => {
          if(time.teacher) {
            const teacherFullName = time.teacher.last_name + ' ' + time.teacher.first_name + ' ' + time.teacher.middle_name;
            if (teacherFullName === selectedTeacher.value) {
              lessonAccept = true;
            }
          }
        });

        return lessonAccept || selectedTeacher.value === '0';
      }));
      return chunk(schedule, 7);
    });

    const teachers = computed(() => {
      let arr = [];

      props.lessons.forEach(lesson => {
          lesson.times.forEach(time => {
            if(time.teacher) {
              if (!arr.find(teacher => (teacher.last_name + ' ' + teacher.first_name + ' ' + teacher.middle_name) === (time.teacher.last_name + ' ' + time.teacher.first_name + ' ' + time.teacher.middle_name))) {
                arr.push(time.teacher);
              }
            }
          });
      });

      return arr;
    });

    const monthName = computed(() => months[month.value]);
    const selected = ref(null);

    function prev() {
      if (month.value === 0) {
        month.value = 11;
        year.value--;
      } else {
        month.value--;
      }
      selected.value = null;
    }

    function next() {
      if (month.value === 11) {
        month.value = 0;
        year.value++;
      } else {
        month.value++;
      }
      selected.value = null;
    }

    return {timetable, selected, monthName, prev, next, month, year, teachers, selectedTeacher}
  }
}
</script>
<style scoped>
.calendar__title-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>