<template>
  <div class="user-info__document wrapper">
    <div class="user-info__document__title" :title="document.url">{{ document.name ?? document.url }}</div>
    <div class="buttons">
      <a :href="document.url" class="button secondary sm" :download="document.name ?? 'Документ'" target="_blank">Скачать</a>
      <button class="button secondary red sm" v-if="deletable" @click="remove">Удалить</button>
    </div>
  </div>
</template>

<script>
import {removeDocument} from "@/data/requests";
import {inject} from "@vue/runtime-core";

export default {
  props: ['document', 'deletable'],
  setup(props) {
    const token = inject('token');
    const updateProfileData = inject('updateProfileData');
    async function remove() {
      await removeDocument(token.value, props.document.id);
      await updateProfileData();
    }
    return {remove}
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  width: 122px;
  box-sizing: border-box;
  @media screen and (max-width: 1025px) {
    width: auto;
  }
}
.button {
  margin-top: 5px;
  margin-right: 10px;
}
</style>
