<template>
  <th @click="$emit('selectCell', day)"
      class="practice" :class="cellClasses">
    <SliderModal v-if="isSelected">
      <div class="practice__header">
        <h3 class="practice__title">Занятия <span class="practice__date">{{ day.dateString }}</span></h3>
        <button class="button circle" @click.stop="$emit('selectCell', null)"><i class="fas fa-times"></i></button>
      </div>
      <div class="practice__items accordion">
        <div class="accordion__item" v-for="time in activeTimes" :key="time.id">
          <div class="accordion__header">
            <label>
              <span class="accordion__label">
                <span class="practice__time"
                      :class="{error: errorsIds.includes(time.id)}">{{ time.time_of }} - {{ time.time_to }}</span>
                <input v-if="!(isBatch && time.price_for_lesson)" class="input" type="checkbox" v-model="selectedTimes" :value="time.id">
                <span v-if="!(isBatch && time.price_for_lesson)" class="checkbox"><i class="fas fa-check"></i></span>
              </span>
              <span class="practice__info">
                <span class="practice__key">Преподаватель</span>
                <template v-if="time.teacher">
                  <div class="practice__value">{{ teacherFullName(time.teacher) }}</div>
                </template>
                <span class="practice__value" v-else>Не указан</span>
                <template v-if="time.address">
                  <div class="practice__key">Место практики</div>
                  <div class="practice__value">{{ time.address }}</div>
                </template>
                <template v-if="time.vessel">
                  <div class="practice__key">Судно</div>
                  <div class="practice__value">{{ time.vessel }}</div>
                </template>
                <template v-if="time.price_for_lesson">
                  <div class="practice__key"><b>Цена</b></div>
                  <div class="practice__value"><b>{{ time.price_for_lesson }} <svg xmlns="http://www.w3.org/2000/svg" style="width: 8px;" viewBox="0 0 384 512" class="icon" data-v-b5aa881a=""><path fill="currentColor" d="M243.128 314.38C324.987 314.38 384 257.269 384 172.238S324.987 32 243.128 32H76c-6.627 0-12 5.373-12 12v215.807H12c-6.627 0-12 5.373-12 12v30.572c0 6.627 5.373 12 12 12h52V352H12c-6.627 0-12 5.373-12 12v24c0 6.627 5.373 12 12 12h52v68c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12v-68h180c6.627 0 12-5.373 12-12v-24c0-6.627-5.373-12-12-12H128v-37.62h115.128zM128 86.572h105.61c53.303 0 86.301 31.728 86.301 85.666 0 53.938-32.998 87.569-86.935 87.569H128V86.572z" class="" data-v-b5aa881a=""></path></svg></b></div>
                  <div class="practice__value"><b>На данное занятие цена отлична от стандартной.<br>Оплаченные пакетные занятия на данное занятие не распространяются</b></div>
                </template>
              </span>
            </label>
            <div class="practice__key error">{{ timeError(time) }}</div>
          </div>
          <div class="accordion__body"
               v-if="selectedTimes.includes(time.id) && time.occupied === 0 && time.limit !== 1">
            <div class="practice__key">Дополнительно</div>
            <LessonToggle2 :individual="isIndividual(time)"
                           @update:individual="value => updateIndividual(time.id, value)"
                           :disabled="time.occupied > 0"/>
            <div class="accordion__price" v-if="isIndividual(time)">
              <div class="practice__desc">На судне будете только Вы и инструктор</div>
              <div class="practice__price">+{{ numberFormat(lesson.individual_price) }}
                <CurrencyRuble/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SliderModal>
    <div class="practice-info" v-if="isActive">
      <div class="practice-info__title">{{ lesson.name }}</div>
      <div class="practice-info__time">{{ minTime }} - {{ maxTime }}</div>
      <div class="practice-info__name">{{ teacherShortName(closestTime.teacher) }}</div>
    </div>
    <span class="calendar__value">{{ day.day }}</span>
  </th>
</template>

<script>
import {computed, toRefs} from "@vue/reactivity";
import LessonToggle2 from "@/components/common/LessonToggle2";
import SliderModal from "@/components/common/SliderModal";
import {inject} from "@vue/runtime-core";
import numberFormat from "@/helpers/numberFormat";
import {teacherFullName, teacherShortName} from "@/helpers/teacher";
import CurrencyRuble from "@/components/common/CurrencyRuble";
import {useRouter} from "vue-router";

export default {
  components: {
    CurrencyRuble,
    LessonToggle2,
    SliderModal
  },
  props: ['day', 'selected', 'selectedTeacher'],
  setup(props) {
    const router = useRouter()
    const isBatch = router.currentRoute.value.name === 'practice-batches'
    const {day, selected, selectedTeacher} = toRefs(props);

    const errorsIds = inject('errorsIds');
    const errorsMap = inject('errorsMap');

    const lesson = computed(() => day.value.lessons[0] ?? {});
    const activeTimes = computed(() => lesson.value.times?.filter(el => {
      const limit = el.occupied < el.limit;
      const propsTeacherFullName = selectedTeacher.value;
      const timeTeacherFullName = el.teacher?.last_name + ' ' + el.teacher?.first_name + ' ' + el.teacher?.middle_name;

      return limit && (selectedTeacher.value === '0' || propsTeacherFullName === timeTeacherFullName);
    }).sort((a, b) => a.time_of.localeCompare(b.time_of)) ?? []);
    const closestTime = computed(() => activeTimes.value[0] ?? {});

    const minTime = computed(() => activeTimes.value.map(el => el.time_of).sort((a, b) => a.localeCompare(b))?.[0] ?? '');
    const maxTime = computed(() => activeTimes.value.map(el => el.time_to).sort((a, b) => b.localeCompare(a))?.[0] ?? '');

    const isSelected = computed(() => {
      const hasLessonsAndSelected = day.value.lessons.length && selected.value;
      return hasLessonsAndSelected &&
          selected.value.day === day.value.day &&
          selected.value.another === day.value.another;
    });
    const isActive = computed(() => activeTimes.value.length > 0);

    const cellClasses = computed(() => {
      const hasErrors = activeTimes.value.some(time => errorsIds.value.includes(time.id));
      return {
        current: day.value.current,
        another_month: day.value.another,
        active: isActive.value,
        error: hasErrors
      };
    });

    const practiceData = inject('practiceData');
    const updatePracticeData = inject('updatePracticeData');
    const selectedTimes = computed({
      get: () => practiceData.value.times ?? [],
      set: value => {
        updatePracticeData({
          ...practiceData.value,
          times: value
        });
      }
    });

    const individuals = computed(() => practiceData.value.individuals ?? []);

    function updateIndividual(id, value) {
      updatePracticeData({
        ...practiceData.value,
        individuals: value
            ? [...individuals.value, id]
            : individuals.value.filter(el => el !== id)
      });
    }

    const isIndividual = time => individuals.value.includes(time.id);
    const timeError = time => errorsMap.value[time.id] ?? '';

    return {
      lesson,
      closestTime,
      teacherShortName,
      teacherFullName,
      cellClasses,
      activeTimes,
      isSelected,
      selectedTimes,
      individuals,
      updateIndividual,
      numberFormat,
      minTime,
      maxTime,
      isIndividual,
      errorsIds,
      timeError,
      isActive,
      isBatch
    }
  }
}
</script>

<style scoped lang="scss">
.unpaid {
  opacity: .3;
}

.practice {
  text-align: left;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }

  &__title {
    font-weight: 500;
    font-size: 21px;
    line-height: 32px;
    color: #020202;
  }

  &__date {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #07A4B2;
    margin-left: 10px;
  }

  &__info {
    text-align: left;
    margin-top: 16px;
    display: inline-block;
  }

  &__key {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #0007;
    margin-top: 10px;

    &.error {
      color: red;
    }
  }

  &__value {
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #282C2E;
  }

  &__items {
    width: 100%;
    overflow: auto;
    min-width: 450px;
    @media screen and (max-width: 1025px) {
      min-width: auto;
    }
  }

  &__time {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #282C2E;

    &.error {
      color: red;
    }
  }

  &__desc {
    font-size: 12px;
    line-height: 16px;
    color: #51585C;
  }

  &__price {
    font-weight: 500;
    font-size: 21px;
    line-height: 32px;
    color: #07A4B2;
    @media screen and (max-width: 1025px) {
      font-size: 16px;
      line-height: 24px;
      min-width: 65px;
    }
  }

  &.error {
    border-color: #e57373 !important;

    .practice__date {
      color: red;
    }
  }
}

.accordion {
  &__item {
    border: 1px solid #F8FAFC;
    border-radius: 4px;
    margin-bottom: 12px;
  }

  &__header {
    box-sizing: border-box;
    padding: 16px;
  }

  &__label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  &__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
  }

  &__body {
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #5FCED8;
    border-top: 1px solid #F8FAFC;
    padding: 8px 16px 16px;
  }

  &__price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
  }
}

label {
  cursor: pointer;
}

.input {
  display: none;
}

.checkbox {
  position: relative;
  cursor: pointer;
  user-select: none;
  width: 24px;
  height: 24px;
  flex: 0 0 auto;
  border-radius: 4px;
  color: #9FA8AD;
  background: #F0F3F4;
  font-size: 12px;
  display: inline-flex !important;
  justify-content: center;
  align-items: center;

  .input:checked ~ & {
    color: #ffffff;
    background: #5FCED8;
  }
}
</style>
