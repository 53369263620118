<template>
    <div class="content">
      <div class="content-header">
          <button class="content-header__exit" @click="logout" :class="{'logout-hide': !user}">Выйти</button>
          <RouterLink :to="{name: 'timetable'}" class="content-header__close"><i class="fas fa-times"></i></RouterLink>
      </div>
      <div class="content-header__title">Команда</div>
      <div class="content-main">
        <div class="content-main_desc" v-if="isLoading">
          <div class="teams" v-for="team in teams" :key="team.id">
            <h2>{{ team.name }}</h2>
            <div class="teams-list">
              <div class="teams-item" v-for="member in team.members" :key="member.id">
                <div class="teams-item-head">
                  <div class="teams-item-head-img">
                    <img :src="member.image_url" alt="" v-if="member.image_url">
                  </div>
                  <div>
                    <h3>{{ member.full_name }}</h3>
                    <p>{{ member.description }}</p>
                    <p v-if="member.avg_score">Рейтинг как инструктора: {{ parseFloat(member.avg_score).toFixed(2) }} / 5</p>
                    <p v-if="member.reviews_count">Количество отзывов: {{ member.reviews_count }}</p>
                    <a v-if="member?.tips_link"  class="button secondary" :href="member?.tips_link" target="_blank">Оставить чаевые</a>
                  </div>
                </div>
                <div class="teams-item-foot" v-if="member.position || member.contact">
                  <div v-if="member.is_teacher">
                    <div class="teams-item-foot-title">Часы инструктажа</div>
                    <div class="teams-item-foot-subtitle">{{ member.hours + ' ' + numberDecl(member.hours ?? 0, ['час', 'часа', 'часов']) }}</div>
                  </div>
                  <div v-if="member.position">
                    <div class="teams-item-foot-title">Позиция</div>
                    <div class="teams-item-foot-subtitle">{{ member.position }}</div>
                  </div>
                  <div v-if="member.contact">
                    <div class="teams-item-foot-title">Контакт</div>
                    <div class="teams-item-foot-subtitle">{{ member.contact }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <Preloader />
        </div>
      </div>
    </div>
</template>

<script>
    import {inject, ref} from "@vue/runtime-core";
    import {getTeams} from "@/data/requests";
    import numberDecl from "@/helpers/numberDecl";
    import Preloader from "@/components/common/Preloader.vue";

    export default {
        components: {Preloader},
        setup() {
            const logout = inject('logout');
            const user = inject('user');
            const token = ref(localStorage.getItem('token'));
            const teams = ref(null);
            const isLoading = ref(false);

            (async () => {
              teams.value = await getTeams(token.value);
              isLoading.value = true;
            })();

            return {logout, user, teams, isLoading, numberDecl};
        }
    }
</script>
<style scoped lang="scss">
    .content-main_desc {
        max-width: 1000px;
    }
    .logout-hide {
        opacity: 0;
        pointer-events: none;
    }
    .teams {
      width: 1200px;
    }
    .teams h2 {
      font-weight: 400;
      font-size: 18px;
    }
    .teams-list {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin-bottom: 25px;
    }
    .teams-item {
      background: #FFFFFF;
      border-radius: 4px;
      padding: 20px;
      flex-basis: calc(100% / 2 - 45px);
    }
    @media screen and (max-width: 1480px) {
      .teams {
        width: 100%;
        max-width: 700px;
      }
      .teams-list {
        flex-wrap: wrap;
      }
      .teams-item {
        flex-basis: 100%;
      }
    }
    .teams-item-head {
      display: flex;
      align-items: flex-start;
    }
    .teams-item-head-img {
      width: 150px;
      height: 150px;
      flex: 0 0 150px !important;
      margin-right: 20px;
    }
    .teams-item-head-img img {
      width: inherit;
      height: inherit;
      display: block;
      object-fit: cover;
      border-radius: 50%;
    }
    .teams-item-head > div {
      flex-basis: 100%;
    }
    .teams-item h3 {
      margin: 0 0 7px 0;
      font-weight: 800;
      font-size: 18px;
      line-height: 36px;
    }
    .teams-item p {
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
    }
    .teams-item-foot {
      display: flex;
      gap: 10px;
      margin-top: 20px;
    }
    .teams-item-foot > div {
      flex-basis: calc(100% / 2 - 10px);
    }
    .teams-item-foot-title {
      font-weight: 400;
      font-size: 12px;
      text-transform: uppercase;
      color: #000000;
      opacity: 0.5;
      line-height: 1.3;
      margin-bottom: 5px;
    }
    .teams-item-foot-subtitle {
      font-weight: 400;
      font-size: 14px;
      line-height: 120%;
      color: #000000;
    }
</style>
