<template>
  <div class="user-info__document wrapper">
    <div class="user-info__document__title" :title="discount.url">{{ discount.name ?? discount.url }}</div>
    <div class="user-info__document__status" :class="documentStatusClass">{{ documentStatusText }}</div>
    <div class="user-info__document__text">{{ discount.description }}</div>
    <div class="user-info__document__text">{{ discount.discount }} руб</div>

    <DiscountPreview v-for="(file, i) in filesForUpload" :key="file.lastModified" :file="file" :discount="discount.id"
                     @remove-file="filesForUpload.splice(i, 1)"/>

    <div class="buttons">
      <a v-if="discount.url" :href="discount.url" class="button secondary sm" :download="discount.name ?? 'Документ'" target="_blank">Скачать</a>
      <div v-if="!discount.confirmed" class="add-document">
        <a class="button sm" :class="{primary: !discount.url, secondary: discount.url}">{{discount.url ? 'Заменить документ':'Загрузить документ'}}</a>
        <input class="document-loader" type="file" @change="addDocument">
      </div>
    </div>
  </div>
</template>

<script>
import {inject, ref, watch} from "@vue/runtime-core";
import DiscountPreview from "@/components/common/DiscountPreview";

export default {
  components: {
    DiscountPreview
  },
  props: ['discount', 'deletable'],
  setup(props) {
    const updateProfileData = inject('updateProfileData');
    async function remove() {
      await updateProfileData();
    }

    const filesForUpload = ref([]);

    async function addDocument(e) {
      const file = e.target.files[0];
      if (!file) return;
      e.target.value = null;
      filesForUpload.value.push(file)
    }

    const documentStatusText = ref('');
    const documentStatusClass = ref('');
    watch(() => props.discount, () => {
      if(props.discount.url && props.discount.confirmed) {
        documentStatusText.value = 'Скидка подтверждена'
        documentStatusClass.value = 'confirmed'
      }
      if(!props.discount.url && !props.discount.confirmed) {
        documentStatusText.value = 'Загрузите документ'
        documentStatusClass.value = 'info'
      }
      if(props.discount.url && !props.discount.confirmed) {
        documentStatusText.value = 'Документ на проверке'
        documentStatusClass.value = 'progress'
      }
    }, {immediate: true, deep: true})

    return {remove, filesForUpload, addDocument, documentStatusClass, documentStatusText}
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  width: 200px;
  box-sizing: border-box;
  @media screen and (max-width: 1025px) {
    width: auto;
  }
}
.button {
  margin-top: 5px;
  margin-right: 10px;
}

.add-document {
  position: relative;
  .document-loader {
    position: absolute;
    box-sizing: border-box;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
}
</style>
