<template>
  <div class="content">
    <div class="content-header">
      <button class="content-header__exit" @click="logout">Выйти</button>
      <RouterLink :to="{name: 'timetable'}" class="content-header__close"><i class="fas fa-times"></i></RouterLink>
    </div>
    <div class="content-header__title">Уведомления</div>
    <div class="content-main">
      <div class="content-main_desc">
        <div class="notification-read-all">
          <a href="#" @click.prevent="markAsReadAll()">Прочитать все</a>
        </div>
        <div class="notifications-list">
          <div class="notifications-item"
               v-for="notification in notificationsWithPaginate"
               :key="notification.id"
               :class="{'notifications-item-unread': !notification.is_read}"
          >
            <div class="notifications-item-new">
              <i v-if="notification.important" class="fas fa-exclamation-circle" title="Важное уведомление"></i>
            </div>
            <div>
              <div class="notification-message">{{ notification.message }}</div>

              <Timeago :datetime="notification.created_at" locale="ru" class="notification-time"/>
            </div>
            <div class="notifications-item-read">
              <button v-if="!notification.is_read" @click="markAsRead(notification)" title="Прочитать"><i
                  class="fas fa-check-circle"></i></button>
            </div>
          </div>
        </div>
        <div class="paginate" v-if="countPages > 1">
          <button class="button" :disabled="page === 1" @click="page -= 1">Назад</button>
          <button class="button" :disabled="page === countPages" @click="page += 1">Далее</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {inject} from "@vue/runtime-core";
import {Timeago} from "vue2-timeago";
import {markAsReadAllNotification, markAsReadNotification} from "@/data/requests";
import {computed, ref} from "@vue/reactivity";

export default {
  components: {
    Timeago,
  },
  setup() {
    const logout = inject('logout');
    const user = inject('user');
    const notifications = inject('notifications');
    const token = inject('token');
    const markAsRead = async notification => {
      await markAsReadNotification(token.value, notification.id);
      notification.is_read = true;
    };
    const markAsReadAll = async () => {
      await markAsReadAllNotification(token.value);
      notifications.value.map(notification => {
        notification.is_read = true;
        return notification;
      });
    };
    const page = ref(1);
    const perPage = 10;
    const countPages = computed(() => {
      return Math.ceil(notifications.value.length / perPage)
    });
    const notificationsWithPaginate = computed(() => {
      return notifications.value.slice((page.value - 1) * perPage, perPage * page.value);
    });

    return {logout, user, notifications, markAsRead, markAsReadAll, notificationsWithPaginate, countPages, page}
  }
}
</script>
<style scoped>
.content-main_desc,
.notifications-list {
  max-width: 800px;
  width: 100%;
}

.notifications-list {
  margin-top: 5px;
  font-size: 14px;
  line-height: 1.4;
}

.notifications-item {
  border-radius: 8px;
  background-color: white;
  margin-bottom: 10px;
  padding: 20px;
  display: flex;
  justify-content: flex-start;
}

.notifications-item-unread.notifications-item {
  background-color: #cce5ff;
}

.notifications-item > div {
  flex-basis: 100%;
}

.notifications-item-new {
  position: relative;
  flex: 0 0 30px !important;
  color: #da482e;
}

.notifications-item-unread .notifications-item-new i {
  margin-top: 25px;
}

.notifications-item-read {
  flex: 0 0 100px !important;
  text-align: right;
}

.notifications-item-read button {
  background-color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  padding: 0;
}

.notifications-item-read i {
  font-size: 20px;
  color: #00669c;
  line-height: inherit;
}

.notification-read-all {
  text-align: right;
  font-size: 14px;
  margin-top: 20px;
}

.notification-read-all a {
  color: #00669c;
}

.notifications-item-new:before {
  position: absolute;
  top: 3px;
  left: 0;
  display: block;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  background-color: #00669c;
}

.notifications-item-unread .notifications-item-new:before {
  content: '';
}

.notification-time {
  margin-top: 5px;
  opacity: .5;
  display: block;
}

.notification-message {
  white-space: pre-wrap;
}

.paginate button + button {
  margin-left: 10px;
}
.paginate button:not(:disabled) {
  background-color: #00669c;
  border-color: #00669c;
  color: white;
}
</style>
