<template>
  <div class="content-main_avatar">
    <img :src="user?.avatar_url" alt="Аватар" class="avatar" v-if="user?.avatar_url">
    <img src="@/assets/img/user.png" alt="Нет аватара" class="avatar" v-else>
    <svg class="add-icon" width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="37" cy="37" r="18" fill="#E3E6E8"/>
      <path d="M45 39V42H48V44H45V47H43V44H40V42H43V39H45ZM45.008 27C45.556 27 46 27.445 46 27.993V37H44V29H28V42.999L38 33L41 36V38.829L38 35.829L30.827 43H38V45H26.992C26.7288 44.9997 26.4765 44.895 26.2905 44.7088C26.1045 44.5226 26 44.2702 26 44.007V27.993C26.0018 27.7304 26.1069 27.479 26.2925 27.2932C26.4781 27.1074 26.7294 27.0021 26.992 27H45.008ZM32 31C32.5304 31 33.0391 31.2107 33.4142 31.5858C33.7893 31.9609 34 32.4696 34 33C34 33.5304 33.7893 34.0391 33.4142 34.4142C33.0391 34.7893 32.5304 35 32 35C31.4696 35 30.9609 34.7893 30.5858 34.4142C30.2107 34.0391 30 33.5304 30 33C30 32.4696 30.2107 31.9609 30.5858 31.5858C30.9609 31.2107 31.4696 31 32 31Z" fill="#07A4B2"/>
    </svg>
    <div class="error" v-if="error">{{ error }}</div>
    <input class="avatar-loader" type="file" @change="loadAvatar">
  </div>
</template>

<script>
import {inject, ref} from "@vue/runtime-core";
import {changeAvatar} from "@/data/requests";

export default {
  props: ['success'],
  setup(props, {emit}) {
    const token = inject('token');
    const user = inject('user');
    const error = ref('');
    const updateProfileData = inject('updateProfileData');

    async function loadAvatar(e) {
      const file = e.target.files[0];
      if (!file) return;

      e.target.value = null;
      error.value = '';
      const formData = new FormData();
      formData.append('file', file);

      const data = await changeAvatar(token.value, formData);

      if (data.errors) {
        error.value = Object.values(data.errors).join(', ');
        return;
      }

      emit('success', 'Аватар успешно загружен');
      await updateProfileData();
    }
    return { error, loadAvatar, user }
  }
}
</script>

<style scoped lang="scss">
.avatar {
  position: absolute;
  z-index: -1;
}
.content-main_avatar {
  cursor: pointer;
  position: relative;
}

.avatar-loader {
  position: absolute;
  box-sizing: border-box;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.error {
  color: red;
  margin-top: 8px;
  display: block;
  font-size: 12px;
}
</style>
