<template>
  <div class="content">
    <div class="content-header">
      <div>
        <div class="content-header__title">Смена пароля</div>
        <div class="content-header__subtitle">Введите новый пароль</div>
      </div>
    </div>
    <div class="content-form">
      <form @submit.prevent="submit">
        <template v-if="success">
          <div class="success">{{ success }}</div>
          <RouterLink class="button secondary wide" :to="{name: 'timetable'}">На главную</RouterLink>
        </template>
        <template v-else>
          <FormField :error="errors.password">
            <input class="input" type="password" v-model="form.password" placeholder="Пароль">
          </FormField>
          <FormField :error="errors.password_confirmation">
            <input class="input" type="password" v-model="form.password_confirmation" placeholder="Повтор пароля">
          </FormField>
          <button class="button primary wide">Отправить</button>
        </template>
      </form>
    </div>
    <div class="content-offer">
      <span class="content-offer__text">Вспомнили пароль?</span>
      <RouterLink class="button secondary wide" :to="{name: 'auth'}">Войти</RouterLink>
    </div>
  </div>
</template>

<script>
import FormField from "@/components/common/FormField";
import {inject, reactive, ref} from "@vue/runtime-core";
import {resetPassword} from "@/data/requests";
import {useRoute} from "vue-router";

export default {
  components: {
    FormField,
  },
  setup() {
    const errors = ref({});

    const form = reactive({
      password: '',
      password_confirmation: ''
    });

    const success = ref('');

    const route = useRoute();
    const login = inject('login');

    async function submit() {
      const data = await resetPassword({hash: route.query.token, ...form});

      if (data.token) {
        success.value = 'Пароль успешно сменен';
        await login(data.token);
        return;
      }

      errors.value = data.errors;
    }

    return {errors, form, success, submit}
  }
}
</script>

<style scoped lang="scss">
.content-form {
  max-width: 360px;
  @media all and (max-width: 1025px) {
    max-width: 100%;
  }
  .button {
    margin-top: 10px;
  }
}

.content-offer {
  max-width: 360px;
  width: calc(100% - 30px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 42px;

  &__text {
    font-size: 12px;
    line-height: 16px;
    color: #51585C;
  }

  .button {
    width: 50%;
    box-sizing: border-box;
  }
}
</style>
