<template>
  <div class="blocks__block white" v-if="closestLesson.id">
    <div class="block_content" v-if="isPractice">
      <div class="block__header black">Ближайшая практика</div>
      <div class="block__desc black">{{ closestLesson.category?.name }}</div>
      <div class="block__desc black">{{ teacherShortName(closestLesson.teacher) }}</div>
    </div>
    <div class="block_content" v-else>
      <div class="block__header black">Ближайшее занятие</div>
      <div class="block__desc black">{{ closestLesson.courseName }}</div>
    </div>
    <div class="block__date">
      <div class="number">{{ date.day }}</div>
      <div class="week">{{ date.month }}</div>
    </div>
  </div>
</template>

<script>
import {computed} from "@vue/reactivity";
import {dateToMonthObject, stringToDate, stringToTime} from "@/helpers/date";
import {teacherShortName} from "@/helpers/teacher";

const maxTimeObject = {date: "01.01.9999", time_of: "23:59"};

export default {
  props: ['lessons'],
  setup(props) {
    const closestLesson = computed(() => props.lessons
        .filter(el => !el.passed)
        .filter(el => el.paid)
        .reduce(
            (el, min) => stringToTime(el.date, el.time_of) < stringToTime(min.date, min.time_of) ? el : min,
            maxTimeObject
        ));

    const date = computed(() => dateToMonthObject(stringToDate(closestLesson.value.date)));

    const isPractice = computed(() => !!closestLesson.value.teacher);

    return {closestLesson, isPractice, date, teacherShortName}
  }
}
</script>
