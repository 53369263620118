import Timetable from "@/views/TimetablePage";
import NotFoundPage from "@/views/NotFoundPage";
import CoursePage from "@/views/CoursePage";
import LessonView from "@/components/lesson/LessonView";
import TestView from "@/components/lesson/TestView";
import SetupPage from "@/views/SetupPage";
import AuthPage from "@/views/AuthPage";
import RegisterPage from "@/views/RegisterPage";
import EmailConfirmPage from "@/views/EmailConfirmPage";
import PaymentPage from "@/views/PaymentPage";
import PracticeSetupPage from "@/views/PracticeSetupPage";
import PracticeExamSetupPage from "@/views/PracticeExamSetupPage";
import ForgotPasswordPage from "@/views/ForgotPasswordPage";
import ResetPasswordPage from "@/views/ResetPasswordPage";
import UserInfoPage from "@/views/UserInfoPage";
import UserPaymentsPage from "@/views/UserPaymentsPage";
import UserInfoEditPage from "@/views/UserInfoEditPage";
import PaymentSuccessPage from "@/views/PaymentSuccessPage";
import PaymentFailPage from "@/views/PaymentFailPage";
import PolicyPage from "@/views/PolicyPage";
import PracticeBatchesPage from "@/views/PracticeBatchesPage";
import TicketsPage from "@/views/TicketsPage";
import TeamPage from "@/views/TeamPage";
import NotificationPage from "@/views/NotificationPage";
import FleetPage from "@/views/FleetPage";
import PracticesReview from "@/views/PracticesReview";

const routes = [
    {
        path: '/',
        redirect: '/setup/course'
    },
    {
        path: '/auth',
        name: 'auth',
        component: AuthPage,
    },
    {
        path: '/register',
        name: 'register',
        component: RegisterPage,
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: ForgotPasswordPage,
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        component: ResetPasswordPage,
    },
    {
        path: '/email-confirm',
        name: 'email-confirm',
        component: EmailConfirmPage,
    },
    {
        path: '/setup/course/:step?',
        name: 'setup',
        component: SetupPage,
    },
    {
        path: '/setup/master-class/:step?',
        name: 'master-class',
        component: SetupPage,
    },
    {
        path: '/setup/practice/:step?',
        name: 'setup-practice',
        component: PracticeSetupPage,
    },
    {
        path: '/setup/practice-exam/:step?',
        name: 'practice-exam',
        component: PracticeExamSetupPage,
    },
    {
        path: '/practice/batches/:step?',
        name: 'practice-batches',
        component: PracticeBatchesPage,
    },
    {
        path: '/payment/:id',
        name: 'payment',
        component: PaymentPage,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/payment/success',
        name: 'payment-success',
        component: PaymentSuccessPage,
    },
    {
        path: '/payment/fail',
        name: 'payment-fail',
        component: PaymentFailPage,
    },
    {
        path: '/timetable',
        name: 'timetable',
        component: Timetable,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/notifications',
        name: 'notifications',
        component: NotificationPage,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/practices/reviews',
        name: 'practices-review',
        component: PracticesReview,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/course/:id',
        name: 'course',
        component: CoursePage,
        children: [
            {
                path: 'lesson/:lessonId/test/:testId/result',
                name: 'lesson_test_result',
                component: TestView
            },
            {
                path: 'lesson/:lessonId/test/:testId',
                name: 'lesson_test',
                component: TestView
            },
            {
                path: 'lesson/:lessonId',
                name: 'lesson',
                component: LessonView
            }
        ],
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/user',
        name: 'user',
        component: UserInfoPage,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/payments',
        name: 'payments',
        component: UserPaymentsPage,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/tickets',
        name: 'tickets',
        component: TicketsPage,
    },
    {
        path: '/teams',
        name: 'teams',
        component: TeamPage,
    },
    {
        path: '/fleet',
        name: 'fleet',
        component: FleetPage,
    },
    {
        path: '/user/edit',
        name: 'user-edit',
        component: UserInfoEditPage,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/not-found',
        name: 'not-found',
        component: NotFoundPage,
    },
    {
        path: '/policy',
        name: 'policy',
        component: PolicyPage,
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/not-found'
    },
];

export default routes;
