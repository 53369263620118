<template>
  <div class="sidebar">
    <div class="sidebar-progress_title">
      {{ course?.name }}
      <span v-if="course?.address" class="sidebar-progress_title_desc">{{ course?.address }}</span>
    </div>
    <div class="sidebar-progress_lessons">
      <RouterLink class="item-lesson-link" v-for="lesson in course?.lessons" :key="lesson.id"
                  :to="{name: 'lesson', params: {id: $route.params.id, lessonId: lesson.id}}">
        <LessonItem :lesson="lesson" :course="course"/>
      </RouterLink>
    </div>
  </div>
  <RouterView />
</template>

<script>
import LessonItem from "@/components/course/LessonItem";
import {useRoute} from "vue-router";
import {inject} from "@vue/runtime-core";
import {computed} from "@vue/reactivity";

export default {
  components: {
    LessonItem
  },
  setup() {
    const user = inject('user');
    const route = useRoute();
    const course = computed(() => user.value?.courses.find(el => el.id === +route.params.id && el.lessons.find(lesson => lesson.id === +route.params.lessonId)));

    return { course }
  }
}
</script>

<style scoped>
  .sidebar-progress_title_desc {
    display: block;
    font-size: 14px;
    margin-top: 10px;
    line-height: 1.3;
  }
</style>
