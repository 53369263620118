<template>
  <div class="content-header_mobile" v-click-away="close">
    <div class="content-header_mobile__btn">
      <button @click="isMenuActive = !isMenuActive"><i class="fas fa-bars"></i></button>
    </div>
    <div class="content-header_mobile__logo">
      <img src="@/assets/img/logo_mobile.png" alt="logo"/>
    </div>
    <RouterLink :to="{name: 'notifications'}" class="content-header_mobile__notification" v-if="user">
      <i class="far fa-bell"></i>
      <span class="content-header_mobile__notification-count" v-if="newNotificationsCount">{{ newNotificationsCount }}</span>
    </RouterLink>
    <RouterLink :to="{name: 'user'}" class="content-header_mobile__avatar" v-if="user">
      <img :src="user?.avatar_url" alt="аватар" v-if="user?.avatar_url">
      <img src="@/assets/img/user.png" alt="нет аватара" v-else>
    </RouterLink>
  </div>

  <div class="mobile_menu" :class="{open: isMenuActive}" @click.stop>
    <div class="mobile_menu__header">
      <RouterLink v-if="user" :to="{name: 'timetable'}">Расписание</RouterLink>
      <RouterLink :to="{name: 'setup'}">Записаться на теорию</RouterLink>
      <RouterLink :to="{name: 'setup-practice'}">Записаться на практику</RouterLink>
      <RouterLink :to="{name: 'master-class'}">Записаться на мастер-класс</RouterLink>
      <RouterLink v-if="user && content.tickets" :to="{name: 'practice-batches'}">Оплаченные пакетные занятия</RouterLink>
      <RouterLink v-if="content?.tickets" :to="{name: 'tickets'}">Билеты ГИМС</RouterLink>
      <RouterLink :to="{name: 'teams'}">Наша команда</RouterLink>
      <RouterLink :to="{name: 'fleet'}">Наш флот</RouterLink>
    </div>
    <div class="mobile_menu__footer">
      <a href="#" v-if="user" @click.prevent="close() ^ $emit('showSupport')">Поддержка</a>
      <a href="https://sea-law.ru/kontakty.html">Адрес и контакты</a>
      <a href="https://sea-law.ru/">Основной сайт</a>
      <RouterLink :to="{name: 'auth'}" class="item-menu" v-if="!user">Войти</RouterLink>
      <a href="#" v-if="user" @click.prevent="close() ^ logout()">Выйти</a>
    </div>
  </div>
</template>

<script>
import {inject, ref} from "@vue/runtime-core";
import {useRouter} from "vue-router";

export default {
  emits: ['showSupport'],
  setup() {
    const user = inject('user');
    const logout = inject('logout')
    const isMenuActive = ref(false);
    const content = inject('siteInfo');
    const newNotificationsCount = inject('new-notifications-count');

    function close() {
      isMenuActive.value = false;
    }

    const router = useRouter();
    router.beforeResolve(close);

    return {isMenuActive, close, user, logout, content, newNotificationsCount};
  }
}
</script>
<style scoped>
.content-header_mobile__notification {
  position: relative;
}
.content-header_mobile__notification-count {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #da482e;
  font-size: 10px;
  width: 15px;
  height: 15px;
  line-height: 15px;
  color: white;
  text-align: center;
  border-radius: 50%;
}
</style>