<template>
  <div class="content">
    <div class="content-header">
      <div class="content-header__title">Дата начала обучения</div>
      <button class="content-header__close" @click="$emit('clearSetup')"><i class="fas fa-times"></i></button>
    </div>
    <div class="content-main">
      <div class="item-main">
        <div class="item-main__desc">Выберите дату начала обучения</div>
        <div class="item-main_blocks item-main_blocks_date">
          <DateItem v-for="group in groups" :key="group.id" :option="group">
            <input type="radio" v-model="stepData" :value="group">
          </DateItem>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DateItem from "@/components/setup/DateItem";
import {inject} from "@vue/runtime-core";
import {computed} from "@vue/reactivity";
import {stringToTime} from "@/helpers/date";

export default {
  components: {DateItem},
  props: ["data"],
  setup(props) {
    const setupData = inject('setupData');
    const updateSetupData = inject('updateSetupData');
    const stepData = computed({
      get: () => setupData.value.group,
      set: value => updateSetupData({group: value})
    });
    const groups = computed(() => [...props.data].sort((a, b) => stringToTime(a.date_of) - stringToTime(b.date_of)))
    return {stepData, groups}
  }
}
</script>
