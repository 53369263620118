export const stringToDate = (date, time = "") => {
    const [day, month, year] = date.split('.');
    const [hours, minutes] = time.split(':');
    return new Date(year, month - 1, day, hours ?? null, minutes ?? null);
}

const getTimezoneDiff = () => -(180 + (new Date()).getTimezoneOffset()) * 60 * 1000;

export const stringToTime = (date, time) => {
    if(!date || !time) return Number.MAX_SAFE_INTEGER;

    return stringToDate(date, time).getTime() + getTimezoneDiff();
};

const dayMonthFormatter = new Intl.DateTimeFormat('ru', {month: 'long', day: 'numeric'});

export const dayMonthFormat = date => dayMonthFormatter.format(date);

export const dateToMonthObject = date => {
    const [day, month] = dayMonthFormat(date).split(' ');
    return {day, month};
}

export const timeFormat = seconds => {
    const m = seconds / 60 ^ 0;
    const s = seconds % 60;
    return `${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`;
}
