<template>
  <div class="content">
    <div class="content-header">
      <button class="content-header__exit" @click="logout" :class="{'logout-hide': !user}">Выйти</button>
      <RouterLink :to="{name: 'timetable'}" class="content-header__close"><i class="fas fa-times"></i></RouterLink>
    </div>
    <div class="content-header__title" style="margin-bottom: 50px;">Оставьте отзыв преподавателям практики</div>
    <div class="content-main">
      <div class="content-main_desc" v-if="practicesForReview.length">
        <div class="teams">
          <div class="teams-list">
            <div class="teams-item" v-for="review in practicesForReview" :key="review.id">
              <div class="teams-item-head">
                <div class="teams-item-head-img" v-if="review.time.teacher?.avatar_url">
                  <img :src="review.time.teacher.avatar_url" alt="">
                </div>
                <div>
                  <h3>{{ review.time.teacher.fullname }}</h3>
                  <p><b>{{ review.time.name }}</b> {{ review.time.date }} в {{ review.time.time_of }} - {{ review.time.time_to }}</p>
                  <div class="reviews-star">
                    <i class="fas fa-star" :class="{'active': review.score >= 1}" title="1" @click="review.score = 1"></i>
                    <i class="fas fa-star" :class="{'active': review.score >= 2}" title="2" @click="review.score = 2"></i>
                    <i class="fas fa-star" :class="{'active': review.score >= 3}" title="3" @click="review.score = 3"></i>
                    <i class="fas fa-star" :class="{'active': review.score >= 4}" title="4" @click="review.score = 4"></i>
                    <i class="fas fa-star" :class="{'active': review.score === 5}" title="5" @click="review.score = 5"></i>
                  </div>
                  <div class="form-group field">
                    <label class="label">
                      <span class="input-wrapper">
                        <textarea class="input" placeholder="Отзыв" v-model="review.description"></textarea>
                      </span>
                    </label>
                  </div>
                  <div style="display: flex;justify-content: space-between">
                    <button class="button primary" @click="sendReview(review)" :disabled="!review.score || (review.score < 5 && !review.description)">Отправить</button>
                    <a v-if="review?.time?.teacher?.tips_link"  class="button secondary" :href="review?.time?.teacher?.tips_link" target="_blank">Оставить чаевые</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {inject, onMounted, ref} from "@vue/runtime-core";
import {getPracticesForReviews, sendPracticesReview} from "@/data/requests";

export default {
  components: {},
  setup() {
    const logout = inject('logout');
    const user = inject('user');
    const token = ref(localStorage.getItem('token'));

    const practicesForReview = ref([])

    const loadReviews = async () => {
      practicesForReview.value = await getPracticesForReviews(token.value)
    }

    onMounted(async () => {
      await loadReviews()
    })

    const sendReview = async review => {
      if(review.score === 5 || (review.score > 0 && review.description)) {
        await sendPracticesReview(token.value, review.id, {
          score: review.score,
          description: review.description
        })
        await loadReviews()
      }
    }

    return {user, logout, practicesForReview, sendReview};
  }
}
</script>
<style scoped lang="scss">
.content-main_desc {
  max-width: 1000px;
}

.logout-hide {
  opacity: 0;
  pointer-events: none;
}

.teams {
  width: 1200px;
}

.teams h2 {
  font-weight: 400;
  font-size: 18px;
}

.teams-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 25px;
}

.teams-item {
  background: #FFFFFF;
  border-radius: 4px;
  padding: 20px;
  flex-basis: calc(100% / 2 - 45px);
}

@media screen and (max-width: 1480px) {
  .teams {
    width: 100%;
    max-width: 700px;
  }
  .teams-list {
    flex-wrap: wrap;
  }
  .teams-item {
    flex-basis: 100%;
  }
}

.teams-item-head {
  display: flex;
  align-items: flex-start;
}

.teams-item-head-img {
  width: 150px;
  height: 150px;
  flex: 0 0 150px !important;
  margin-right: 20px;
}

.teams-item-head-img img {
  width: inherit;
  height: inherit;
  display: block;
  object-fit: cover;
  border-radius: 50%;
}

.teams-item-head > div {
  flex-basis: 100%;
}

.teams-item h3 {
  margin: 0 0 7px 0;
  font-weight: 800;
  font-size: 18px;
  line-height: 36px;
}

.teams-item p {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
}

.teams-item-foot {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.teams-item-foot > div {
  flex-basis: calc(100% / 2 - 10px);
}

.teams-item-foot-title {
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase;
  color: #000000;
  opacity: 0.5;
  line-height: 1.3;
  margin-bottom: 5px;
}

.teams-item-foot-subtitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #000000;
}

.reviews-star i {
  color: #aaa;
  display: inline-block;
  margin-right: 3px;
  cursor: pointer;
}
.reviews-star i.active {
  color: #e8cc1c;
}
</style>
