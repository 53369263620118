<template>
  <div class="item-main">
    <div class="item-main__title" v-if="title">{{ step.title }}</div>
    <div class="item-main__desc">{{ step.description }}</div>
    <div class="item-main_blocks">
      <CourseOption v-for="option in step.fields.filter(field => (setupData?.format === 'base' && !field.hide_base) || (setupData?.format === 'additional' && !field.hide_additional))" :key="option.id" :option="option" :step="step"
                    :type="step.type" v-model="stepData">
        <input :disabled="forced[option.id] || ((option.id === 26 && regions.includes(27)) || (option.id === 12 && regions.includes(59)))" :class="{disabled: forced[option.id] || ((option.id === 26 && regions.includes(27))) || (option.id === 12 && regions.includes(59))}" :type="step.type" v-model="stepData" :value="option.id">
      </CourseOption>
    </div>
  </div>
</template>

<script>
import CourseOption from "@/components/setup/CourseOption";
import {inject, ref, watch} from "@vue/runtime-core";
import {computed} from "@vue/reactivity";

export default {
  props: ["step", "title"],
  components: {CourseOption},
  setup(props) {
    const setupData = inject('setupData');
    const setField = inject('setField');
    const stepData = computed({
      get: () => setupData.value.fields.find(el => el.id === props.step.id)?.value ?? [],
      set: value => setField({
        ...props.step,
        value
      })
    });

    const forced = ref([])

    watch(() => setupData.value, () => {
      if(setupData.value?.format === 'base') {
        let update = false
        let value = setupData.value.fields.find(el => el.id === props.step.id)?.value ?? [];
        // course_id: 7
        // custom_id: 6, fields: [25]
        // custom_id: 5, fields: [21]
        if(setupData.value?.course?.id === 7) {
          if(props.step.id === 5 && !value.includes(21)) {
            value.push(21);
            forced.value[21] = true
            update = true
          }

          if(props.step.id === 6 && !value.includes(25)) {
            value.push(25);
            forced.value[25] = true
            update = true
          }
        }

        //course_id: 4
        // custom_id: 5, fields: [6]
        // custom_id: 6, fields: [11]
        if(setupData.value?.course?.id === 4) {
          if(props.step.id === 5 && !value.includes(6)) {
            value.push(6);
            forced.value[6] = true
            update = true
          }

          if(props.step.id === 6 && !value.includes(11)) {
            value.push(11);
            forced.value[11] = true
            update = true
          }
        }

        if(setupData.value?.course?.id === 19) {
          if(props.step.id === 5 && !value.includes(91)) {
            value.push(91);
            forced.value[91] = true
            update = true
          }

          if(props.step.id === 6 && !value.includes(94)) {
            value.push(94);
            forced.value[94] = true
            update = true
          }
        }

        if(update) {
          setField({
            ...props.step,
            value
          })
        }
      }
    }, {immediate: true, deep: true})

    const regions = computed({
      get: () => setupData.value.fields.find(el => el.slug === 'region')?.value ?? []
    });

    return {stepData, setupData, regions, forced}
  }
}
</script>

<style >
input.disabled + .item-main_block__checkmark {
  background: #fff !important;
}
</style>
