import {useRoute, useRouter} from "vue-router";
import {ref, watch} from "@vue/runtime-core";
import {computed} from "@vue/reactivity";

export function useSteps(steps, routeName) {
  console.log(routeName)
  const router = useRouter();
  const route = useRoute();

  const currentIndex = ref(0);
  const currentStep = computed(() => steps.value[currentIndex.value]);
  const readyToSwitch = computed(() => (currentStep.value.readyFunc ?? (() => true))());
  const isFirst = computed(() => currentIndex.value === 0);
  const isLast = computed(() => +currentIndex.value === steps.value.length - 1);

  function changeStep(index) {
    if ((+index === 0 || steps.value[index - 1]?.readyFunc()) && currentIndex.value !== +index) {
      currentIndex.value = index;
      router.replace({name: routeName, params: {step: index}});
    }
  }

  function prev() {
    changeStep(currentIndex.value === 0 ? 0 : currentIndex.value - 1);
  }

  function next() {
    if (!readyToSwitch.value) return;
    changeStep(currentIndex.value === steps.value.length - 1 ? steps.value.length - 1 : parseInt(currentIndex.value) + 1);
  }

  watch(() => route.params.step, changeStep, {immediate: true});

  return {
    currentIndex,
    currentStep,
    readyToSwitch,
    isFirst,
    isLast,
    changeStep,
    next,
    prev,
  }
}
