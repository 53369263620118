<template>
  <div class="blocks__block" v-if="!ended && unpaidLessonsCount > 0">
    <div class="block__header">Оплата</div>
    <div class="block__desc">Оплатите практику ({{ unpaidLessonsCount }} ед) на {{ unpaid.price }}р, пока не истекло бронирование {{ time }}</div>
    <RouterLink :to="{name: 'payment', params: {id: 'practices'}}" class="button primary sm mt-1">Оплатить</RouterLink>
    <button class="button primary red sm mt-1 ml-1" @click="removePractices">Отменить</button>
    <BaseModal :show="isModalShowing"
               @cancel-modal="isModalShowing = false"
               title="Отказ от занятий практики"
               description="Вы уверены, что хотите отказаться?">
      <button class="button secondary red" @click="removePractices">Отказаться</button>
      <button class="button secondary" @click="isModalShowing = false">Закрыть</button>
    </BaseModal>
  </div>
</template>

<script>
import {inject, onMounted, onUnmounted, ref} from "@vue/runtime-core";
import {getUnpaidPractices, removeUnpaidPractices} from "@/data/requests";
import {computed} from "@vue/reactivity";
import {stringToTime, timeFormat} from "@/helpers/date";
import BaseModal from "@/components/common/BaseModal";

const twentyMinutes = 20 * 60 * 1000;

export default {
  components: {BaseModal},
  setup() {
    const unpaid = ref({});
    const time = ref("00:00");
    const ended = ref(false);
    let interval = null;
    
    const updateTime = startTime => () => {
      const now = Date.now();
      const diff = (startTime - now) / 1000 ^ 0;
      if (diff < 0) {
        time.value = "00:00";
        ended.value = true;
        clearInterval(interval);
        return;
      }
      time.value = timeFormat(diff);
    }

    const token = inject('token');
    onMounted(async () => {
      unpaid.value = await getUnpaidPractices(token.value);

      const times = unpaid.value?.lessons.map(lesson => stringToTime(...lesson.created_at.split(' '))) ?? [];
      const min = Math.min(...times);
      setInterval(updateTime(min + twentyMinutes), 1000)
    });
    onUnmounted(() => clearInterval(interval));

    const unpaidLessonsCount = computed(() => unpaid.value.lessons?.length);

    const isModalShowing = ref(false);
    const tryLoadUser = inject('tryLoadUser');

    async function removePractices() {
      isModalShowing.value = false;
      await removeUnpaidPractices(token.value);
      tryLoadUser();
      unpaid.value = [];
    }

    return {unpaid, unpaidLessonsCount, time, ended, isModalShowing, removePractices}
  }
}
</script>

<style scoped>
.mt-1 {
  margin-top: 10px;
}
.ml-1 {
  margin-left: 10px;
}
</style>
