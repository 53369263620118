<template>
  <div class="toggle" :class="{disabled}">
    <button class="toggle__item" @click="$emit('update:individual', false)" :class="{active: !individual}">
      <i class="fas fa-users" :class="{enabled: !individual}"></i>
      Группой
    </button>
    <button v-if="!disabled" class="toggle__item" @click="disabled || $emit('update:individual', true)" :class="{active: individual}">
      <i class="fas fa-user" :class="{enabled: individual}"></i>
      Индивидуально
    </button>
  </div>
</template>

<script>
export default {
  props: ["individual", "disabled"],
}
</script>

<style scoped lang="scss">
.toggle {
  color: #9fa8ad;
  display: flex;
  border: 1px solid #9FA8AD;
  border-radius: 46px;
  padding: 3px;
  box-sizing: border-box;
  margin-top: 12px;
  &__item {
    border-radius: 46px;
    padding: 8px 32px;
    width: 50%;
    box-sizing: border-box;
    font-size: 10px;
    line-height: 20px;
    color: #383F42;
    i {
      color: currentColor;
      transition: color .3s ease-in-out;
    }
    &.active {
      background: #D4F4F7;
      color: #07A4B2;
    }
    @media screen and (max-width: 1025px) {
      padding: 8px;
    }
  }
  &.disabled {
    width: 50%;
    min-width: 200px;
    .toggle__item {
      min-width: 100%;
      width: 100%;
    }
  }
}
</style>
