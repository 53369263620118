<template>
  <div class="item-lesson">
    <a href="#" class="item-lesson__title" title="Перейти к уроку">{{ lesson.fullname }}</a>
    <div class="item-lesson__progressbar" v-if="!course?.no_dates && lesson.date"><span :style="progressStyle"></span></div>
    <div v-if="lesson.test_exist" class="test-count">
      Тестов в лекции: {{ lesson.tests_count }}
    </div>
  </div>
</template>

<script>
import {computed} from "@vue/reactivity";
import {stringToTime} from "@/helpers/date";
import {ref} from "@vue/runtime-core";

export default {
  props: ['lesson', 'course'],
  setup(props) {
    const lesson = ref(props.lesson);

    const progressStyle = computed(() => {
      const now = Date.now();
      const lessonStarts = stringToTime(lesson.value.date, lesson.value.time_of);
      const lessonEnds = stringToTime(lesson.value.date, lesson.value.time_to);
      const progress = Math.min(Math.round(Math.max(now - lessonStarts, 0) / (lessonEnds - lessonStarts) * 100), 100);
      return {
        width: `${progress}%`,
        background: lesson.value.color
      }
    });

    return {progressStyle}
  }
}
</script>

<style lang="scss">
.test-button {
  margin-top: 3px;
  padding: 3px;
}
.test-btn {
  padding: 3px 10px;
}
.test-count {
  font-size: 0.75rem;
  color: #00659C;
}

</style>