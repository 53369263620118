const oneHour = 1000 * 60 * 60;
const oneDay = oneHour * 24;
const dateFormatter = new Intl.DateTimeFormat('ru-RU');

const padDate = number => number.toString().padStart(2, "0");
const dateFormat = date => dateFormatter.format(date);

export const computeCalendarDays = (month, year) => {
    const today = new Date();
    const currentMonth = new Date(year, month);
    const nextMonth = new Date(year, month + 1);
    const prevMonth = new Date(year, month - 1);

    const currentFirstDay = currentMonth.getDay();
    const nextFirstDay = nextMonth.getDay();
    const currentLastDate = Math.ceil((nextMonth.getTime() - currentMonth.getTime() - oneHour) / oneDay);
    const prevLastDate = Math.ceil((currentMonth.getTime() - prevMonth.getTime() - oneHour) / oneDay);

    const dayFn = (dateMonth, callback, another = true) => (_, i) => {
        const day = callback(i);
        const month = dateMonth.getMonth();
        const year = dateMonth.getFullYear();
        const dateString = `${padDate(day)}.${padDate(month + 1)}.${year}`;
        const current = dateFormat(today) === dateString;

        return {another, day, month, year, current, dateString}
    };

    const prevDays = Array.from({length: (currentFirstDay + 6) % 7 || 7}, dayFn(prevMonth, i => prevLastDate - i)).reverse();
    const currentDays = Array.from({length: currentLastDate}, dayFn(currentMonth, i => i + 1, false));
    const nextDays = Array.from({length: 8 - nextFirstDay}, dayFn(nextMonth, i => i + 1));

    return [
        ...prevDays,
        ...currentDays,
        ...nextDays
    ];
}

const addLessonsToDay = (day, lessons) => ({
    ...day,
    lessons: lessons.filter(lesson => lesson.date === day.dateString)
})

export const combineSchedule = (days, lessons) => days.map(day => addLessonsToDay(day, lessons))
