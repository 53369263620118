"use strict";

// Host of application

import {apiHeaders, apiHost} from "@/config";

/**
 * Http request
 * @param url
 * @param method
 * @param headers
 * @param body
 * @param html
 * @returns {Promise<{error: *}|{code: number, data: any}>}
 */
export const http = async (url, method = "GET", headers = {}, body = null, html = false) => {
    const init = {
        headers,
        method
    };

    if (body) init.body = body;

    try {
        const res = await fetch(url, init);
        const code = res.status;
        let data;
        if(html) {
            data = await res.text();
        } else {
            data = await res.json();
        }
        return {data, code};
    } catch (error) {
        return {error};
    }
}

/**
 * Api request
 * @param path
 * @param method
 * @param headers
 * @param body
 * @param html
 * @returns {Promise<{error: *}|{code: number, data: *}>}
 */
export const apiRequest = (path, method, headers = {}, body, html = false) => http(`${apiHost}${path}`, method, {...apiHeaders, ...headers}, JSON.stringify(body), html);

