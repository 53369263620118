<template>
  <div class="content">
    <div class="content-header">
      <div class="content-header__title">Дата обучения</div>
      <div class="header__notification">
        <button class="content-header__close" @click="clearSetup"><i class="fas fa-times"></i></button>
      </div>
    </div>
    <div class="content-description">
      <div class="description__text">Выберите дату и время практического занятия по типу судна</div>
    </div>
    <Timetable v-for="practice in data" :key="practice.id" :lessons="practice.lessons"
               :title="practice.name" practice="true" teachersVisible="true" />
  </div>
</template>

<script>

import Timetable from "@/components/timetable/Timetable";
import {inject} from "@vue/runtime-core";

export default {
  props: ['data', 'modelValue'],
  components: {
    Timetable,
  },
  setup() {
    const clearSetup = inject('clearSetup');
    return {clearSetup};
  }
}
</script>
