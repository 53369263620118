<template>
  <BaseModal :show="show" title="Виртуальная экскурсия" :full="true">
    <template #description-slot>
      <div class="excursion-container">
        <iframe v-if="type === 'gallery'" allowfullscreen allow="xr-spatial-tracking; gyroscope; accelerometer"
                :src="fleet.excursion_gallery"></iframe>
        <iframe v-if="type === 'video'"
                :src="`https://www.youtube.com/embed/${fleet.excursion_video}`" title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
      </div>
    </template>
    <div class="buttons">
      <button class="button secondary" type="button" @click="$emit('close')">Закрыть</button>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from "@/components/common/BaseModal";

export default {
  props: ['show', 'fleet', 'type'],
  components: {BaseModal},
}
</script>
<style scoped>
.excursion-container {
  display: block;
}

.buttons {
  display: block;
}
iframe {
  display: block;
  width: 100%;
  height: calc(100vh - 20vh - 50px);
}
</style>