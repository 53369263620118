export const prepareCourseRegisterData = data => ({
  course_id: data?.course?.id,
  group_id: data.group?.id,
  format: data.format,
  customs: data.fields.filter(el => el.id !== 'format' && (Array.isArray(el.value) ? [...el.value] : [el.value]).length > 0).map(el => ({
    custom_id: el.id,
    fields: Array.isArray(el.value) ? [...el.value] : [el.value]
  }))
});

export const preparePracticeRegisterData = data => ({
  times: data.times?.map(time => ({
    time_id: time,
    individual: data.individuals?.includes(time) ?? false
  }))
});

export const preparePracticeData = data => data.map(practice => {
  const {price, individual_price, discount, has_discount} = practice;
  const lessons = practice.lessons.map(el => ({...el, price, individual_price, discount, has_discount}));
  return {
    ...practice,
    lessons
  }
});

export const preparePracticeTimes = data => data
  .map(el => {
      const {discount, has_discount, individual_price, price} = el;
      return el.lessons.map(lesson => {
          return lesson.times.map(time => ({...time, discount, has_discount, individual_price, price}));
      });
  }).flat(2);

export const preparePracticeErrors = (errors, data) =>
  Object.entries(errors).map(([key, value]) => {
    const [, index] = key.split('.');
    const id = data.times?.[index]?.time_id;
    return [id, value]
  });

export const getSetupData = () => JSON.parse(localStorage.getItem('setupData'));
export const clearSetupData = () => localStorage.removeItem('setupData');

export const setRegisterErrors = value => localStorage.setItem('registerErrors', JSON.stringify(value));
export const getRegisterErrors = () => JSON.parse(localStorage.getItem('registerErrors'));
export const clearRegisterErrors = () => localStorage.removeItem('registerErrors');

export const getPracticeData = () => JSON.parse(localStorage.getItem('practiceSetup'));
export const clearPracticeData = () => localStorage.removeItem('practiceSetup');

export const setGroupId = id => localStorage.setItem('group_id', id);
export const getGroupId = () => localStorage.getItem('group_id');
export const clearGroupId = () => localStorage.removeItem('group_id');
