<template>
  <div class="sidebar">
    <div class="sidebar_title">Начать<br>
      обучение —<br>
      просто
    </div>
    <div class="sidebar-guide">
      <a href="#" class="item-guide" v-for="(step, i) in steps" :key="step.title"
         :class="{'item-guide__active': i <= currentIndex}" @click.prevent="changeStep(i)">
        <div class="item-guide__icon">{{ i + 1 }}</div>
        <div class="item-guide__title">{{ step.title }}</div>
      </a>
    </div>
    <div class="sidebar-price">
      <div class="sidebar-price__title">Выбранный пакет</div>
      <div class="sidebar-price__price">{{ dataPurchased }}</div>
      <div class="sidebar-price__btns">
        <button class="button secondary wide" v-if="isFirst"
                :class="{disabled: !readyToSwitch}" @click="next">Далее
        </button>
        <template v-else>
          <button class="button secondary big" @click="prev">Назад</button>
          <button class="button primary big" :class="{disabled: !readyToSwitch}" @click="next"
                  v-if="!isLast">Далее
          </button>
          <RouterLink class="button primary big"
                      v-if="isLast && !user && readyToSwitch" :to="{name: 'register'}">Войти
          </RouterLink>
          <button class="button primary big"
                  v-if="isLast && user && readyToSwitch" @click="register">Завершить
          </button>
        </template>
      </div>
    </div>
  </div>
  <div class="mobile_footer">
    <div class="mobile_footer__left">
      <div class="title">Выбранный пакет</div>
      <div class="price">{{ dataPurchased }}</div>
    </div>
    <div class="mobile_footer__right">
      <button class="button secondary" v-if="isFirst"
              :class="{disabled: !readyToSwitch}" @click="next">Далее
      </button>
      <template v-else>
          <button class="button secondary" @click="prev">Назад</button>
          <button class="button primary" :class="{disabled: !readyToSwitch}" @click="next"
                  v-if="!isLast">Далее
          </button>
          <RouterLink class="button primary"
                      v-if="isLast && !user && readyToSwitch" :to="{name: 'register'}">Войти
          </RouterLink>
          <button class="button primary" :class="{disabled: !readyToSwitch}"
                  v-if="isLast && user && readyToSwitch" @click="register">Завершить
          </button>
      </template>
    </div>
  </div>
  <component :is="currentStep.component"
             :data="currentStep.data"
             :steps="steps"
             :is-load="isLoadPractice"
             v-model="practice"/>
  <BaseModal :show="isErrorModalShowing"
             @cancel-modal="isErrorModalShowing = false"
             title="Выбранное время уже забронировано"
             description="Пожалуйста, проверьте время бронирования и исправьте ошибки">
    <button class="button primary" @click="isErrorModalShowing = false">Вернуться</button>
    <button class="button secondary red" @click="clearSetup">Прервать</button>
  </BaseModal>
</template>

<script>
import PracticeView from "@/components/batch/PracticeView";
import {inject, onMounted, provide, readonly, ref} from "@vue/runtime-core";
import {getPractice, registerPracticeBatch} from "@/data/requests";
import numberFormat from "@/helpers/numberFormat";
import DatesView from "@/components/batch/DatesView";
import {useSteps} from "@/use/steps";
import {useLocalStore} from "@/use/localStore";
import {computed} from "@vue/reactivity";
import {
  clearRegisterErrors,
  getRegisterErrors,
  preparePracticeData,
  preparePracticeErrors,
  preparePracticeRegisterData
} from "@/helpers/courseRegister";
import {useRouter} from "vue-router";
import BaseModal from "@/components/common/BaseModal";

export default {
  components: {BaseModal},
  setup() {
    const router = useRouter();
    const user = inject('user');
    const token = inject('token');
    const practices = ref([]);
    const tryLoadUser = inject('tryLoadUser')
    const isLoadPractice = ref(false)

    onMounted(async () => {
      practices.value = preparePracticeData(await getPractice(token.value, false))
      isLoadPractice.value = true
    });

    const {
      clear: clearPracticeData,
      data: practiceData,
      update: updatePracticeData
    } = useLocalStore('practiceSetup', {});
    provide('practiceData', readonly(practiceData));
    provide('updatePracticeData', updatePracticeData);

    const datesData = computed(() => practices.value?.filter(el => practiceData.value.practiceIds?.includes(el.id)));
    const dataPurchased = computed(() => {
      if(datesData.value.length === 0)
        return 'Выберите пакет'
      else {
        const purchased = ref(datesData.value[0].purchased)
        const remainder = ref(purchased.value)
        if(practiceData.value.times?.length)
          remainder.value = purchased.value - practiceData.value.times?.length
        return `Доступно: ${remainder.value}/${purchased.value}`
      }
    })

    const steps = computed(() => {
      return [
        {
          title: 'Выберите пакетный курс',
          component: PracticeView,
          data: practices.value,
          readyFunc: () => practiceData.value.practiceIds?.length > 0
        },
        {
            title: 'Выберите дату практики',
            component: DatesView,
            data: datesData.value,
            readyFunc: () => practiceData.value.times?.length > 0
        }
      ]
    });

    const practice = computed({
      get: () => practiceData.value,
      set: value => updatePracticeData(value)
    });

    const isErrorModalShowing = ref(false);
    const registerErrors = ref(preparePracticeErrors(
        getRegisterErrors() ?? {},
        preparePracticeRegisterData(practiceData.value)
    ));
    const errorsIds = computed(() => registerErrors.value.map(el => el[0]));
    const errorsMap = computed(() => Object.fromEntries(registerErrors.value));
    provide('errorsIds', errorsIds);
    provide('errorsMap', errorsMap);

    async function register() {
      clearRegisterErrors();
      registerErrors.value = [];
      const preparedData = preparePracticeRegisterData(practiceData.value);
      const data = await registerPracticeBatch(token.value, preparedData);

      if (data?.errors) {
        isErrorModalShowing.value = true;
        registerErrors.value = preparePracticeErrors(data.errors, preparedData);
        return;
      }

      await tryLoadUser()
      await router.push({name: 'timetable'});
      clearPracticeData();
    }

    async function clearSetup() {
      isErrorModalShowing.value = false;
      await router.push({name: "timetable"});
      clearRegisterErrors();
      clearPracticeData();
    }

    provide('clearSetup', clearSetup);

    const {changeStep, currentIndex, currentStep, next, prev, readyToSwitch, isFirst, isLast} = useSteps(steps, "practice-batches");

    return {
      steps,
      changeStep,
      currentIndex,
      currentStep,
      isFirst,
      isLast,
      next,
      prev,
      readyToSwitch,
      numberFormat,
      user,
      practice,
      register,
      isErrorModalShowing,
      clearSetup,
      dataPurchased,
      isLoadPractice
    }
  }
}
</script>


<style lang="scss">
.sidebar-price {
  width: 220px;
}
</style>