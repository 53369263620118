<template>
  <BaseModal :show="show"
             title="Важное уведомление"
             :description="notification.message">
    <div class="buttons">
      <button class="button secondary" type="button" @click="$emit('read', notification)">Прочитано</button>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from "@/components/common/BaseModal";

export default {
  props: ['show', 'notification'],
  components: {BaseModal},
}
</script>
