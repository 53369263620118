<template>
  <div class="content content-user_info_page">
    <div class="content-header">
      <button class="content-header__exit" @click="logout">Выйти</button>
      <RouterLink :to="{name: 'timetable'}" class="content-header__close"><i class="fas fa-times"></i></RouterLink>
    </div>
    <div class="content-main">
      <div class="content-main_avatar">
        <img :src="user?.avatar_url" alt="Аватар" v-if="user?.avatar_url">
        <img src="@/assets/img/user.png" alt="Нет аватара" v-else>
      </div>
      <div class="user-info">
        <div class="user-info_name">
          <div class="user-info_fullname">{{ user?.fullname }}</div>
          <RouterLink :to="{name: 'user-edit'}" class="button primary zoom user-info_edit_button"><i class="far fa-edit"></i> Редактировать профиль</RouterLink>
        </div>
        <div class="user-info__group">
          <div class="user-info__title">
            Контакты
          </div>
          <div class="user-info_item">
            <div class="user-info_item__title">Email:</div>
            <div class="user-info_item__value">{{ user?.email }}</div>
          </div>
          <div class="user-info_item">
            <div class="user-info_item__title">Телефон:</div>
            <div class="user-info_item__value">{{ phoneFormat(user?.phone) }} {{ user?.phone_verified ? '' : '(не подтвержден)' }}</div>
          </div>
        </div>
        <div class="user-info__group">
          <div class="user-info__title">
            Загруженные документы
          </div>
          <div class="user-info__documents">
            <div class="user-info__document">
              <div class="user-info__document__title">Паспорт</div>
              <div class="user-info__document_body">
                <div class="user-info__document_item">
                  <div class="user-info__document_item__title">Серия, номер</div>
                  <div class="user-info__document_item__value">{{ user?.passport_number }}</div>
                </div>
                <div class="user-info__document_item">
                  <div class="user-info__document_item__title">Кем выдан</div>
                  <div class="user-info__document_item__value">{{ user?.passport_issued }}</div>
                </div>
              </div>
            </div>
            <DocumentItem v-for="document in user?.documents" :key="document.id" :document="document"/>
          </div>
        </div>

        <div class="user-info__group" id="documents">
          <div class="user-info__title">
            Документы
          </div>
          <div class="user-info__documents">
            <div class="user-info__document wrapper" v-for="document in documents" :key="document.link">
              <div class="user-info__document__title">{{document.title}}</div>
              <div class="buttons">
                <a
                    @click.prevent="getDocument(document.slug)"
                    href="#"
                    class="button secondary sm"
                    target="_blank">Скачать</a>
              </div>
            </div>
          </div>
        </div>

        <div class="user-info__group">
          <div class="user-info__title">
            Договоры оплаченных курсов
          </div>
          <div class="user-info__documents">
            <div class="user-info__document wrapper" v-for="course in user?.courses.filter(course => course.paid)" :key="course.id">
              <div class="user-info__document__title">{{ course.name }}</div>
              <div class="buttons">
                <a
                    @click.prevent="getDocument(course.document_url)"
                    href="#"
                    class="button secondary sm"
                    target="_blank">Скачать договор</a>
              </div>
            </div>
          </div>
        </div>

        <div class="user-info__group">
          <div class="user-info__title">
            Скидки
          </div>
          <div class="user-info__documents">
            <DiscountItem v-for="discount in user?.discounts" :key="discount.id" :discount="discount"/>
          </div>
        </div>

        <div class="user-info__group" v-if="user">
          <div class="user-info__title">
            Баллы
          </div>
          <p>На вашем счету {{ user.bonus }} {{ numberDecl(user.bonus, ['балл', 'балла', 'баллов']) }}.<br>Баллы можно использовать при покупке курсов теорий и занятий практики.</p>
        </div>
      </div>
    </div>
  </div>
  <div class="support" style="display: none">
    <button class="support_btn"><i class="far fa-comment-alt"></i></button>
  </div>
</template>

<script>
import {inject, onMounted, ref} from "@vue/runtime-core";
import phoneFormat from "@/helpers/phoneFormat";
import DocumentItem from "@/components/common/DocumentItem";
import DiscountItem from "@/components/common/DiscountItem";
import {apiHost} from "@/config";
import {getDocuments} from "@/data/requests";
import numberDecl from "@/helpers/numberDecl";

export default {
  methods: {numberDecl},
  components: {
    DocumentItem,
    DiscountItem
  },
  setup() {
    const logout = inject('logout');
    const user = inject('user');
    const token = inject('token');

    const getDocument = (document) => {
      window.open(`${apiHost}documents/${document}?api_token=${token.value}`,"_blank");
    }

    /*
    [
      {
        title: 'Заявление',
        link: 'zayavlenie',
      },
    ]
     */

    const documents = ref([]);
    async function loadData() {
      documents.value = await getDocuments(token.value);
    }
    onMounted(loadData);

    return {logout, user, phoneFormat, getDocument, documents}
  }
}
</script>
<style scoped lang="scss">
.wrapper {
  width: 200px;
  box-sizing: border-box;
  @media screen and (max-width: 1025px) {
    width: auto;
  }
}
.button {
  margin-top: 5px;
  margin-right: 10px;
}
.user-info_edit_button {
  line-height: 20px;
}
.user-info_edit_button i {
  font-size: 16px;
  margin-right: 10px;
}
</style>
