<template>
  <div class="content">
    <div class="sidebar-mobile">
      <select class="input" v-model="selectLesson" >
        <option v-for="lesson in course?.lessons"  :key="lesson.id" :value="lesson.id">
          {{ lesson.fullname }}
        </option>
      </select>
    </div>
    <div class="content-header">
      <div class="content-header__title">{{ lesson?.fullname }}</div>
      <RouterLink :to="{name: 'timetable'}" class="content-header__close"><i class="fas fa-times"></i></RouterLink>
    </div>
    <div v-if="test?.id" class="content-main">
      <div class="test-header">Тест на усвоение материала</div>
      <p v-if="test.duration_limit">Ограничение в минутах: <b>{{ test.duration_limit }}</b></p>
      <p v-if="test.duration_limit">Прошло времени: <b>{{ left.minutes }} м. {{ left.seconds }} с.</b></p>
      <div v-if="lessonResult?.score || lessonResult?.score === 0" class="test-results">
        Вопросов в тесте: {{ lessonResult.counts.all }}<br />
<!--        Правильных ответов: {{ lessonResult.counts.right }}<br />-->
        <span class="gray">Процент выполнения:</span> {{ lessonResult.score }}%<br />
        <span class="gray">Минимальный процент выполнения для прохождения теста:</span> {{ test?.accept_score }}%<br />
        <span class="gray">Тест {{ lessonResult.success ? 'сдан' : 'не сдан' }}</span>
      </div>
      <form action="" @submit.prevent="submit">
        <div v-for="(question, index) in questions" :key="question.id">
          <div class="question-card">
            <div class="question-header">Вопрос {{ index+1 }}. {{ question.name }}</div>
            <div class="question-body">
              <div class="question-text">
                <p class="question-desc">{{ question.description }}</p>
                <p class="question-subtitle" v-if="question.type === 'one'">Один вариант ответа</p>
                <p class="question-subtitle" v-else-if="question.type === 'multi'">Можно выбрать несколько вариантов</p>
                <p class="question-subtitle" v-else-if="question.type === 'compare'">Соотнесите варианты ответа</p>
                <p class="question-subtitle" v-else-if="question.type === 'text'">Свободный ответ</p>
                <div>
                  <template v-if="question.type === 'one' || question.type === 'multi'">
                    <div class="question-check-wrap" :class="{ 'radio-style': question.type === 'one' }" v-for="answer of question.answers" :key="answer.id">
                      <div class="dnc-inputs__submit" :class="{ 'answer-correct': answer.correct === true, 'answer-incorrect': answer.correct === false }">
                        <label class="dnc-submit__checkbox">
                          <input type="radio" :value="answer.id" v-model="form[question.id].answer_id" :disabled="locked" v-if="question.type === 'one'"
                                 :class="{ 'answer-correct': answer.correct === true, 'answer-incorrect': answer.correct === false }">
                          <input type="checkbox" :value="answer.id" v-model="form[question.id].answer_ids" :disabled="locked" v-else
                                 :class="{ 'answer-correct': answer.correct === true, 'answer-incorrect': answer.correct === false }">
                          <span class="dnc-checkbox__checkmark"></span>
                          {{ answer.answer }}
                        </label>
                      </div>
                    </div>
                    <span class="dnc-error-msg">{{ errors['answers.'+question.id+'.question_id'] }}</span>
                  </template>

                  <template v-else-if="question.type === 'compare'">
                    <div class="form-field form-field--select" v-for="answer of question.answers" :key="answer.id">
                      <label class="form-field__label" :for="`q${question.id}_${answer.id}`">
                        {{ answer.answer }}
                      </label>
                      <div class="form-field__select" :class="{ 'answer-correct': answer.correct === true, 'answer-incorrect': answer.correct === false }">
                        <select class="form-field__input" :id="`q${question.id}_${answer.id}`" v-model="form[question.id].answer_compare[answer.id]" :disabled="locked" :class="{ 'answer-correct': answer.correct === true, 'answer-incorrect': answer.correct === false }">
                          <option :value="undefined" disabled selected>Выберите</option>
                          <option v-for="other of question.compare_answers" :key="other" :value="other">{{ other }}</option>
                        </select>
                        <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.97055 9.50614C7.77408 9.50728 7.57931 9.46944 7.39742 9.39479C7.21553 9.32015 7.0501 9.21016 6.9106 9.07114L0.939046 3.07114C0.799851 2.93128 0.689436 2.76524 0.614105 2.58251C0.538773 2.39978 0.5 2.20393 0.5 2.00614C0.5 1.80835 0.538773 1.6125 0.614105 1.42976C0.689436 1.24703 0.799851 1.08099 0.939046 0.941137C1.07824 0.801279 1.24349 0.690337 1.42536 0.614647C1.60722 0.538956 1.80215 0.5 1.999 0.5C2.19585 0.5 2.39077 0.538956 2.57264 0.614647C2.75451 0.690337 2.91975 0.801279 3.05895 0.941137L7.97055 5.90614L12.8971 1.13614C13.0344 0.982702 13.2018 0.859389 13.3887 0.77391C13.5756 0.688431 13.7781 0.642625 13.9835 0.639356C14.1888 0.636087 14.3926 0.675425 14.5822 0.754911C14.7717 0.834398 14.9429 0.952323 15.085 1.10131C15.2271 1.2503 15.337 1.42714 15.408 1.62078C15.479 1.81442 15.5094 2.0207 15.4975 2.2267C15.4855 2.4327 15.4313 2.63401 15.3384 2.81802C15.2454 3.00204 15.1157 3.1648 14.9573 3.29614L8.98571 9.08614C8.7127 9.35061 8.34975 9.50077 7.97055 9.50614Z" fill="#9E9E9E"/>
                        </svg>
                      </div>
                      <div class="form-field__description answer-correct-label" v-if="answer.correct === true">
                        <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1.67587 5.48186C1.48741 5.31318 1.24183 5.22165 0.988394 5.22565C0.734961 5.22965 0.492419 5.32887 0.309421 5.50341C0.126422 5.67795 0.0165037 5.9149 0.00171272 6.16673C-0.0130783 6.41856 0.0683524 6.66665 0.229671 6.86122L2.45922 9.19013C2.55257 9.28758 2.66474 9.36527 2.78899 9.41851C2.91325 9.47176 3.04704 9.49947 3.18232 9.49998C3.31689 9.50076 3.45024 9.47462 3.57446 9.42311C3.69867 9.3716 3.81122 9.29577 3.90542 9.20012L10.7146 2.20339C10.8069 2.10889 10.8796 1.99721 10.9286 1.87473C10.9775 1.75226 11.0018 1.62138 10.9999 1.48959C10.998 1.35779 10.9701 1.22765 10.9177 1.10659C10.8653 0.985538 10.7895 0.875941 10.6945 0.784059C10.5995 0.692176 10.4873 0.619808 10.3643 0.571086C10.2412 0.522365 10.1097 0.498243 9.97728 0.5001C9.84486 0.501956 9.7141 0.529753 9.59246 0.581905C9.47083 0.634057 9.36071 0.709542 9.26839 0.804049L3.19236 7.06112L1.67587 5.48186Z" fill="#04BD86"/>
                        </svg>
                        <span>Верно</span>
                      </div>
                      <div class="form-field__description answer-incorrect-label" v-else-if="answer.correct === false">
                        <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.27933 8.37179C-0.09311 8.74424 -0.0931098 9.34809 0.27933 9.72054C0.651769 10.093 1.25561 10.093 1.62805 9.72054L4.99994 6.34861L8.37195 9.72067C8.74439 10.0931 9.34823 10.0931 9.72067 9.72067C10.0931 9.34822 10.0931 8.74437 9.72067 8.37192L6.34866 4.99986L9.7204 1.62808C10.0928 1.25563 10.0928 0.651779 9.7204 0.279334C9.34795 -0.0931111 8.74411 -0.0931114 8.37167 0.279334L4.99994 3.65112L1.62833 0.279462C1.25589 -0.092983 0.652045 -0.0929828 0.279606 0.279462C-0.0928338 0.651907 -0.0928341 1.25576 0.279606 1.62821L3.65121 4.99986L0.27933 8.37179Z" fill="#F53669"/>
                        </svg>
                        <span>Неверно</span>
                      </div>
                    </div>
                    <span class="dnc-error-msg">{{ errors['answers.'+question.id+'.question_id'] }}</span>
                  </template>

                  <div class="form-field" v-else-if="question.type === 'text'" :class="{ 'answer-correct': question.correct === true, 'answer-incorrect': question.correct === false }">
                    <label class="form-field__label" :for="`q${question.id}`">Ответ</label>
                    <textarea class="form-field__input" name="question-body" :id="`q${question.id}`" v-model="form[question.id].answer_text" :disabled="locked" />
                    <span class="dnc-error-msg">{{ errors['answers.'+question.id+'.question_id'] }}</span>
                  </div>

                  <template v-if="question.explain">
                    <h3 class="question-explanation">Правильный ответ</h3>
                    <p>{{ question.explain }}</p>
                  </template>

                </div>
              </div>
              <div class="question-image" v-if="question.image_url">
                <img :src="question.image_url" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="buttons-container">
          <div class="evq-container" v-if="!locked">
            <button type="submit" class="button primary local-primary"> Завершить тестирование </button>
          </div>
          <div class="evq-container" v-if="locked">
            <button class="button primary local-primary"
                    @click="resetForm"
                    :class="{disabled: lessonResult.is_main_test}"
            >
              <template v-if="!lessonResult.is_main_test">Пройти тест ещё раз</template>
              <template v-else>Тест можно проходить раз в 3 дня</template>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {useRoute, useRouter} from "vue-router";
import {getLessonResult, getTest, sendTest} from "@/data/requests";
import {inject, onMounted, ref, watch} from "@vue/runtime-core";
import {computed} from "@vue/reactivity";
import useForm from '@/use/form';
import {stringToTime} from "@/helpers/date";
import _ from 'lodash';

export default {
  components: {
  },
  setup() {
    const token = inject('token');
    const route = useRoute();
    const router = useRouter();
    const lesson = ref({});
    const lessonResult = ref({});
    const test = ref({});
    const testResult = ref({});
    const questions = ref([]);
    const locked = ref(false);
    const left = ref({
      minutes: 0,
      seconds: 0
    })

    const now = Date.now();

    const user = inject('user');
    const course = computed(() => user.value?.courses.find(el => el.id === +route.params.id && el.lessons.find(lesson => lesson.id === +route.params.lessonId)));

    const seconds = ref(0)

    let testInterval = setInterval(() => {
      seconds.value++
      left.value.seconds++;
      if(left.value.seconds >= 60) {
        left.value.seconds = 0;
        left.value.minutes++;
      }
    }, 1000)

    const loadLessonResult = async () => {
      lessonResult.value = await getLessonResult(token.value, route.params.testId);
    }
    const loadTest = async() => {
      const res = await getTest(token.value, route.params.testId);
      test.value = res.test;
      lesson.value = res.lesson;
    }

    onMounted(loadTest);
    // watch(() => route.params.lessonId, loadTest);

    const resetForm = () => {
      testResult.value = {};
      lessonResult.value = {};
      loadTest();
      seconds.value = 0;
      left.value.minutes = 0;
      left.value.seconds = 0;
      testInterval = setInterval(() => {
        seconds.value++
        left.value.seconds++;
        if(left.value.seconds >= 60) {
          left.value.seconds = 0;
          left.value.minutes++;
        }
      }, 1000)
      locked.value = false;
      window.scrollTo(0,0);
    }

    const { form, errors, submit, loading } = useForm(async data => {
      const testData = ref({});
      testData.value.answers = _.cloneDeep(data);
      testData.value.seconds = seconds.value;
      for (const key of Object.keys(testData.value.answers)) {
        const question = testData.value.answers[key];
        if (question.type === 'compare') {
          question.answer_compare = Object.keys(question.answer_compare).reduce((acc, cur) => {
            acc.push({ id: cur, answer_compare: question.answer_compare[cur] });
            return acc;
          }, []);
        }
      }
      // return sendTest(token.value, lesson.value.id, testData.value);
      const res = await sendTest(token.value, route.params.testId, testData.value);
      clearInterval(testInterval)
      testResult.value = Object.assign({}, res);
      return res;
    }, async () => {
      if (_.isEmpty(errors.value)) {
        loadLessonResult();
        window.scrollTo(0,0);
        locked.value = true;
      }
    })

    // заполнение теста (формирование формы)
    watch(() => test, testVal => {
      if (testVal?.value.questions && !lessonResult?.value.result) {
        questions.value = testVal.value.questions;
        form.value = testVal.value.questions.reduce((qmap, q) => {
          qmap[q.id] = { question_id: q.id, type: q.type }
          if (q.type === 'compare') { qmap[q.id].answer_compare = {}; }
          else if (q.type === 'multi') { qmap[q.id].answer_ids = []; }
          else if (q.type === 'one') { qmap[q.id].answer_id =  null; }
          else if (q.type === 'text') { qmap[q.id].answer_text = ''; }
          return qmap;
        }, {});

        const pathParts = route.path.split('/');
        if(pathParts[pathParts.length - 1] === 'result'){
          loadLessonResult();
          locked.value = true;
        }

      }
    }, { deep: true, immediate: true });

    // обновление данных вопросов на основе ответов для форматирования
    watch(() => lessonResult, resultVal => {
      if(resultVal?.value.result) {
        const testRun = _.cloneDeep(resultVal.value.result);
        testRun.map((q) => {
          const i = questions.value.findIndex((obj => obj.id === q.question.id));
          if (q.question.type === 'one' || q.question.type === 'multi' || q.question.type === 'compare') {
            questions.value[i].answers.map((ans) => {
              const a = q.question.answers.findIndex((obj => obj.id === ans.id));
              ans.correct = !!q.question.answers[a].right;
              return ans;
            });
          }
          questions.value[i].explain = q.question.right_comment;

          if (q.question.type === 'compare') {
            let ans_compare = [];
            q.user_answer.answer_compare.map((item) => { ans_compare[item.id] = item.answer_compare; });
            form.value[q.question.id].answer_compare = ans_compare;
          }
          else if (q.question.type === 'multi') { form.value[q.question.id].answer_ids = q.user_answer.answer_ids; }
          else if (q.question.type === 'one') { form.value[q.question.id].answer_id = q.user_answer.answer_id; }
          else if (q.question.type === 'text') { form.value[q.question.id].answer_text = q.user_answer.answer_text; }
        });
      }
    }, { deep: true, immediate: true });


   const status = computed(() => {
      if (!lesson.value?.id) return 'empty';

      const date = lesson.value?.date;
      const timeFrom = lesson.value?.time_of;
      const timeTo = lesson.value?.time_to;

      if (stringToTime(date, timeFrom) > now) {
        return 'before';
      } else if (stringToTime(date, timeTo) < now && lesson.value?.zoom_link) {
        return 'after';
      } else if (lesson.value?.zoom_link) {
        return 'between'
      } else {
        return 'no-link'
      }
    });

    function submitTest () {
      if (locked.value) return;
      submit();
    }

    let selectLesson = ref(route.params.lessonId);
    watch(() => selectLesson.value, () => {
      router.push({name: 'lesson', params: {id: route.params.id, lessonId: selectLesson.value}})
    });

    return {
      lesson, test, status, course, route, selectLesson, locked, form, submit: submitTest, errors, loading, lessonResult, questions, resetForm, left
    }
  },
}
</script>

<style lang="scss" scoped>
.question-card {
  margin-bottom: 20px;
  &__main-image { display: none; }

  @media (min-width: 1250px) {
    &--with-image {
      display: flex;
      align-items: center;
      .question-images {
        display: none;
      }
    }

    &__main-image {
      display: block;
      margin-right: 32px;
      flex-basis: 40%;
      width: 0;

      img { width: 100%; }
    }
  }
}

.question-number {
  display: block;
  font-size: 18px;
  margin-bottom: 8px;
  font-weight: bold;
}

.question-title {
  margin: 0;
  font-size: 32px;
}

.question-subtitle {
  opacity: 0.6;
  margin-bottom: 10px;
}

.question-images {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 12px;
}

.question-image {
  max-height: 300px;
  margin-right: 12px;
  margin-bottom: 12px;
}

.question-check-wrap {
  margin-bottom: 12px;
}

.dnc-inputs__submit {
  color: var(--color);
  text-transform: none;
}

.dnc-inputs__submit label span {
  margin-right: 12px;
}

/**, ::before, ::after {*/
  /*box-sizing: border-box;*/
  /*margin: 0;*/
  /*padding: 0;*/
/*}*/

.radio-style {
  justify-content: flex-end !important;
  .dnc-inputs__submit:first-child {
    margin-right: 65px;
  }
  .dnc-checkbox__checkmark {
    border-radius: 50% !important;

  }
}

.dnc-submit__checkbox {
  display: flex;
  align-items: center;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked ~ .dnc-checkbox__checkmark {
      background-color: #777777;
      &:after {
        display: block;
      }
    }
  }
  .dnc-checkbox__checkmark {
    position: relative;
    top: 0;
    left: 0;
    height: 20px;
    min-width: 20px;
    //border: 2px solid #555555;
    background-color: #DEDEDE;
    border-radius: 4px;
    cursor: pointer;
    &:after {
      display: none;
      content: "";
      position: absolute;
      left: 7px;
      top: 5px;
      width: 6px;
      height: 9px;
      border: solid #FFF;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      border-radius: 2px;
    }
  }
}

.radio-style .dnc-checkbox__checkmark::after {
  border: none;
  top: 6px;
  left: 6px;
  width: 8px;
  height: 8px;
  border-radius: 6px;
  background: #FFF;
}

.form-field {
  margin-bottom: 12px;
}

.form-field__label {
  display: block;
}

.form-field__input {
  display: block;
  width: 100%;
  padding: 10px 16px;
  font: inherit;
  border: 1px solid;
  border-radius: 8px;
}

textarea.form-field__input {
  resize: vertical;
  height: 80px;
  margin: 8px 0;
}

select.form-field__input {
  background: var(--color-test-select-background);
  border: 1px solid var(--color-school-outline-card-border);
  font-weight: 500;
  padding-right: 40px;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select.form-field__input:focus {
  outline-color: var(--color-accent);
}

.form-field__select {
  position: relative;
}

.form-field__select svg {
  position: absolute;
  top: 18px;
  right: 16px;
  opacity: .5;
}

.evq-button-primary {
  font-weight: 700;
  padding: 16px 24px 14px;
}

@media (min-width: 1200px) {
  .form-field--select {
    display: grid;
    grid-template-columns: minmax(180px, 1fr) minmax(300px, 2fr) 1fr;
    grid-column-gap: 16px;
  }

  .form-field__label {
    align-self: center;
  }
}

.answer-correct, .answer-correct-label {
  --color: #009900;
}

.answer-incorrect, .answer-incorrect-label {
  --color: #990000;
}

.answer-correct, .answer-incorrect {
  input, select, textarea, svg {
    color: var(--color);
    border-color: #FFF;
  }

  select[disabled], input[disabled] {
    color: var(--color);
    border-color: var(--color);
    border: 1px solid;
  }

  svg {
    opacity: 1;
  }

  svg path {
    fill: currentColor;
  }

  .dnc-checkbox__checkmark {
    --color-primary: var(--color);
  }

  input:not(:checked) + .dnc-checkbox__checkmark {
    box-shadow: inset 0 0 0 1px var(--color);
    background: none;
    &::after {
//        display: block;
        border-color: var(--color);
      }
  }

  input[type=radio]:not(:checked) + .dnc-checkbox__checkmark::after {
    background-color: var(--color);
  }

  label {
    color: var(--color);
  }
}

.answer-correct-label, .answer-incorrect-label {
  color: var(--color);
  align-self: center;

  svg {
    margin-right: 9px;
  }
}

.question-explanation {
  margin: 24px 0 10px 0;
  font-size: 18px;
}

.question-explanation + p {
  margin: 0;
}

.dnc-error-msg {
  color: #990000;
  font-size: 0.9rem;
}

img {
  max-width: 100%;
  max-height: 100%;
}

.zoom {
  text-decoration: none;
}

.content-main_desc p, .content-main_desc figure {
  margin: 1rem 0;
}

.test-header {
  font-size: 1.3rem;
}

.test-results {
  padding-top: 13px;
  font-size: 0.9rem;
  line-height: 1.5rem;
}

.question-card {
  margin: 20px 0;
  border: 1px solid #eeeeee;
  border-radius: 10px;
  padding: 15px;
}
.question-header {
  opacity: 0.7;
  margin-bottom: 10px;
  font-size: 1.1rem;
}

.question-body {
  width: 100%;
  display: flex;
}
.question-text {
  width: 60%;
}
.question-desc{
  margin-bottom: 10px;
  line-height: 32px;
}
.question-image {
  margin-left: 15px;
  width: 40%;
  margin-bottom: 45px;
}
.buttons-container {
  margin-bottom: 30px;
}

.gray {
  opacity: 0.6;
}

.question-subtitle {

}

.local-primary {
  padding: 10px 25px;
}
</style>
