<template>
    <div class="content">
        <div class="content-header">
            <button class="content-header__exit" @click="logout" :class="{'logout-hide': !user}">Выйти</button>
            <RouterLink :to="{name: 'timetable'}" class="content-header__close"><i class="fas fa-times"></i></RouterLink>
        </div>
        <div class="content-header__title">Билеты ГИМС</div>
        <div class="content-main">
            <div class="content-main_desc ck-content" v-if="isLoading">
                <div v-html="content.tickets"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import {inject, onMounted, ref} from "@vue/runtime-core";
    import {getSiteInfo} from "@/data/requests";

    export default {
        components: {},
        setup() {
            const logout = inject('logout');
            const user = inject('user');
            const token = ref(localStorage.getItem("token"));
            const content = ref(null);
            const isLoading = ref(false);

            async function loadData() {
                content.value = await getSiteInfo(token.value);
                isLoading.value = true;
            }
            onMounted(loadData);

            return {logout, user, content, isLoading}
        }
    }
</script>
<style scoped lang="scss">
    .content-main_desc {
        max-width: 1000px;
    }
    .logout-hide {
        opacity: 0;
        pointer-events: none;
    }
</style>
