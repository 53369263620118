<template>
  <div class="user-info_item">
    <div class="user-info_item__title">Телефон:</div>
    <div class="user-info_item__value">
      <FormField class="field" :error="errors.phone">
        <input class="input" type="text" v-model="phoneValue" id="phone" :disabled="!show">
      </FormField>
    </div>
    <button class="user-info_item__action" v-if="!show" @click="show = true">Изменить телефон {{ !verified ? '(Подтвердить)' : '' }}</button>
    <button class="user-info_item__action" v-if="(show && !showCode)" @click="change">Подтвердить</button>
    <button class="user-info_item__action red" v-if="show" @click="cancel">Отмена</button>
  </div>
  <div class="user-info_item" v-show="showCode">
    <div class="user-info_item__title">Код из смс:</div>
    <div class="user-info_item__value">
      <FormField class="field" :error="errors.code">
        <input class="input sms" type="text" v-model="code" id="phone_code">
      </FormField>
    </div>
    <button class="user-info_item__action button primary sm" @click="confirmPhone">Подтвердить</button>
  </div>
</template>

<script>
import {inject, onMounted, ref, watch} from "@vue/runtime-core";
import FormField from "@/components/common/FormField";
import Inputmask from "inputmask";
import phoneFormat from "@/helpers/phoneFormat";
import {acceptNewPhone, changePhone} from "@/data/requests";

export default {
  props: ['phone', 'verified'],
  emits: ['success'],
  components: {FormField},
  setup(props, {emit}) {
    const showCode = ref(false);
    const token = inject('token');
    const errors = ref({});
    const phoneValue = ref('');
    watch(() => props.phone, value => phoneValue.value = phoneFormat(value), {immediate: true});

    const show = ref(false);

    async function change() {
      errors.value = {};
      const data = await changePhone(token.value, phoneValue.value);
      if (data.errors) {
        errors.value = data.errors;
        return;
      }
      emit('success', `На номер ${phoneValue.value} отправлен код подтверждения`)
      showCode.value = true;
    }

    const code = ref('');

    async function confirmPhone() {
      errors.value = {};
      const data = await acceptNewPhone(token.value, code.value);

      if (data.errors) {
        errors.value = data.errors;
        return;
      }
      emit('success', 'Телефон успешно изменён');
      showCode.value = false;
      show.value = false;
      code.value = '';
    }

    function cancel() {
      phoneValue.value = phoneFormat(props.phone);
      showCode.value = false;
      show.value = false;
    }

    onMounted(() => {
      Inputmask('+7 (999) 999-99-99').mask(document.getElementById('phone'));
      Inputmask('9999').mask(document.getElementById('phone_code'));
    });

    return {showCode, change, confirmPhone, cancel, phoneValue, code, errors, show}
  }
}
</script>

<style scoped>
.input {
  margin-top: 0;
}
.field {
  margin-bottom: 0;
}
.sms {
  text-align: center;
}
</style>
