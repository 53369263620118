<template>
  <div class="content">
    <div class="content-header">
      <div class="content-header__title">Оплата выбранного курса</div>
      <RouterLink :to="{name: 'timetable'}" class="content-header__close"><i class="fas fa-times"></i></RouterLink>
    </div>
    <div class="content-main">
      <div class="item-menu">
        <div class="item-menu_header">
          <div class="item-menu_header__title">{{ data.course_name }}</div>
          <div class="item-menu_header__desc" style="font-style: italic; margin-bottom: 10px;">{{ data.city }}</div>
          <div class="item-menu_header__desc">{{ data.course_description }}</div>
        </div>
        <div class="item-menu_guide">
          <div class="item-guide">
            <div class="item-guide__title">Срок обучения</div>
            <div class="item-guide__desc">{{ dateOf }} - {{ dateTo }}</div>
            <div class="item-guide__lessons" style="display: none">?? занятий</div>
          </div>
          <div class="item-guide" v-for="custom in data.customs" :key="custom.id">
            <div class="item-guide__title">{{ custom.title }}</div>
            <div class="item-guide__desc">{{ custom.fields.map(el => el.title ?? "").join(", ") }}</div>
          </div>
          <div class="item-guide">
            <div class="item-guide__title">Способ оплаты</div>
            <div class="item-guide__desc">{{ method.title }}</div>
          </div>
          <div class="item-guide" v-if="data?.price_detailed?.details?.discount">
            <div class="item-guide__title">
              {{data?.price_detailed?.details.discount.name}}
            </div>
            <div class="item-guide__desc">
              -{{ numberFormat(data?.price_detailed?.details.discount.discount) }} <CurrencyRuble/>
            </div>
          </div>

<!--          <div class="item-guide">-->
<!--            <div class="item-guide__title">Промокод</div>-->
<!--            <div class="item-guide__desc">Нет</div>-->
<!--          </div>-->
        </div>
        <div class="item-menu_footer">
          <div class="item-footer_payment">
            <div class="item-footer_payment__img"><i class="far fa-clock"></i>
            </div>
            <div class="item-footer_payment_desc">
              <div class="item-footer_payment_desc__title">Курс ожидает оплаты</div>
              <div class="item-footer_payment_desc__description">Сразу после оплаты он появится в
                твоем кабинете</div>
            </div>
          </div>
          <div class="item-menu__btn">
            <button class="button secondary big" style="display: none">Изменить заказ</button>
          </div>
        </div>
      </div>
      <div class="item-main" v-if="false">
        <div class="item-main__title">Способ оплаты</div>
        <div class="item-main_blocks">
          <PaymentOption v-for="option in paymentMethods" :key="option.id" :option="option" type="radio">
            <input type="radio" v-model="method" :value="option">
          </PaymentOption>
        </div>
      </div>
      <div class="item-main" v-if="data?.id !== 9">
        <div class="item-main__title">Применить скидку</div>
        <div class="item-main_blocks">
          <EditDiscounts :discounts="user?.discounts" />
        </div>

        <div class="item-main_pay alert" v-if="discountsInProcess">
          <div class="item-main__title">Ваша скидка на проверке</div>
          <div class="item-main_blocks">
            Как только администраторы проверят ваши документы, вы сможете оплатить со скидкой!
          </div>
        </div>
      </div>
      <div class="item-main_pay">
        <div class="item-main_header">
          <div class="item-main_payment">
            <div class="item-main_payment__title">
              Оплатите онлайн
            </div>
            <div class="item-main_date__title"></div>
            <div class="item-main_payment__address" v-if="data.date_of">
              <div class="item-main_payment__address__icon">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.00033 1.16667C5.76265 1.16667 4.57566 1.65833 3.70049 2.5335C2.82532 3.40867 2.33366 4.59566 2.33366 5.83333C2.33366 7.64053 3.50951 9.39415 4.7988 10.7551C5.43152 11.4229 6.06622 11.9697 6.54339 12.3497C6.72098 12.4911 6.87609 12.6089 7.00033 12.7007C7.12456 12.6089 7.27968 12.4911 7.45726 12.3497C7.93444 11.9697 8.56914 11.4229 9.20185 10.7551C10.4911 9.39415 11.667 7.64053 11.667 5.83333C11.667 4.59566 11.1753 3.40867 10.3002 2.5335C9.42499 1.65833 8.238 1.16667 7.00033 1.16667ZM7.00033 13.4167C6.67675 13.902 6.6766 13.9019 6.67643 13.9018L6.67477 13.9007L6.67088 13.8981L6.65751 13.8891C6.64615 13.8813 6.62995 13.8703 6.60923 13.8559C6.5678 13.8272 6.50826 13.7854 6.4332 13.7312C6.28314 13.6228 6.0707 13.4646 5.81664 13.2623C5.30944 12.8584 4.63164 12.275 3.95185 11.5574C2.61615 10.1475 1.16699 8.10947 1.16699 5.83333C1.16699 4.28624 1.78157 2.80251 2.87554 1.70854C3.9695 0.614581 5.45323 0 7.00033 0C8.54742 0 10.0312 0.614581 11.1251 1.70854C12.2191 2.80251 12.8337 4.28624 12.8337 5.83333C12.8337 8.10947 11.3845 10.1475 10.0488 11.5574C9.36902 12.275 8.69122 12.8584 8.18401 13.2623C7.92995 13.4646 7.71751 13.6228 7.56745 13.7312C7.49239 13.7854 7.43285 13.8272 7.39142 13.8559C7.3707 13.8703 7.3545 13.8813 7.34314 13.8891L7.32977 13.8981L7.32588 13.9007L7.32465 13.9015C7.32448 13.9016 7.3239 13.902 7.00033 13.4167ZM7.00033 13.4167L7.3239 13.902C7.12796 14.0327 6.87238 14.0324 6.67643 13.9018L7.00033 13.4167Z" fill="#07A4B2"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.00033 4.66667C6.35599 4.66667 5.83366 5.189 5.83366 5.83333C5.83366 6.47767 6.35599 7 7.00033 7C7.64466 7 8.16699 6.47767 8.16699 5.83333C8.16699 5.189 7.64466 4.66667 7.00033 4.66667ZM4.66699 5.83333C4.66699 4.54467 5.71166 3.5 7.00033 3.5C8.28899 3.5 9.33366 4.54467 9.33366 5.83333C9.33366 7.122 8.28899 8.16667 7.00033 8.16667C5.71166 8.16667 4.66699 7.122 4.66699 5.83333Z" fill="#07A4B2"/>
                </svg>
              </div>
              <div class="item-main_payment__address__text">Занятия проходят по адресу: {{ data.address }} или онлайн на платформе Zoom</div>
            </div>
            <div class="item-main_payment__address">
              <div class="item-main_payment__address__icon"><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.854272 9.02096C1.40125 8.47398 2.14312 8.16669 2.91667 8.16669H7C7.77355 8.16669 8.51541 8.47398 9.06239 9.02096C9.60938 9.56794 9.91667 10.3098 9.91667 11.0834V12.25C9.91667 12.5722 9.6555 12.8334 9.33333 12.8334C9.01117 12.8334 8.75 12.5722 8.75 12.25V11.0834C8.75 10.6192 8.56563 10.1741 8.23744 9.84592C7.90925 9.51773 7.46413 9.33335 7 9.33335H2.91667C2.45254 9.33335 2.00742 9.51773 1.67923 9.84592C1.35104 10.1741 1.16667 10.6192 1.16667 11.0834V12.25C1.16667 12.5722 0.905499 12.8334 0.583333 12.8334C0.261167 12.8334 0 12.5722 0 12.25V11.0834C0 10.3098 0.307291 9.56794 0.854272 9.02096Z" fill="#07A4B2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.95833 2.33335C3.99183 2.33335 3.20833 3.11686 3.20833 4.08335C3.20833 5.04985 3.99183 5.83335 4.95833 5.83335C5.92483 5.83335 6.70833 5.04985 6.70833 4.08335C6.70833 3.11686 5.92483 2.33335 4.95833 2.33335ZM2.04167 4.08335C2.04167 2.47252 3.3475 1.16669 4.95833 1.16669C6.56916 1.16669 7.875 2.47252 7.875 4.08335C7.875 5.69418 6.56916 7.00002 4.95833 7.00002C3.3475 7.00002 2.04167 5.69418 2.04167 4.08335Z" fill="#07A4B2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.8291 4.83754C14.057 5.06535 14.057 5.43469 13.8291 5.6625L11.4958 7.99583C11.268 8.22364 10.8987 8.22364 10.6709 7.99583L9.50419 6.82917C9.27638 6.60136 9.27638 6.23201 9.50419 6.00421C9.73199 5.7764 10.1013 5.7764 10.3291 6.00421L11.0833 6.7584L13.0042 4.83754C13.232 4.60974 13.6013 4.60974 13.8291 4.83754Z" fill="#07A4B2"/>
              </svg>
              </div>
              <div class="item-main_payment__address__text">После получения оплаты курс станет доступен в
                кабинете
              </div>
            </div>
            <div class="item-main_payment__address" v-if="data.phone">
              <div class="item-main_payment__address__icon"><svg width="14" height="14" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="m512 346.5c0-74.628906-50.285156-139.832031-121.195312-159.480469-4.457032-103.878906-90.347657-187.019531-195.304688-187.019531-107.800781 0-195.5 87.699219-195.5 195.5 0 35.132812 9.351562 69.339844 27.109375 99.371094l-26.390625 95.40625 95.410156-26.386719c27.605469 16.324219 58.746094 25.519531 90.886719 26.90625 19.644531 70.914063 84.851563 121.203125 159.484375 121.203125 29.789062 0 58.757812-7.933594 84.210938-23.007812l80.566406 22.285156-22.285156-80.566406c15.074218-25.453126 23.007812-54.421876 23.007812-84.210938zm-411.136719-15.046875-57.117187 15.800781 15.800781-57.117187-3.601563-5.632813c-16.972656-26.554687-25.945312-57.332031-25.945312-89.003906 0-91.257812 74.242188-165.5 165.5-165.5s165.5 74.242188 165.5 165.5-74.242188 165.5-165.5 165.5c-31.671875 0-62.445312-8.972656-89.003906-25.945312zm367.390625 136.800781-42.382812-11.726562-5.660156 3.683594c-21.941407 14.253906-47.433594 21.789062-73.710938 21.789062-58.65625 0-110.199219-37.925781-128.460938-92.308594 89.820313-10.355468 161.296876-81.832031 171.65625-171.65625 54.378907 18.265625 92.304688 69.808594 92.304688 128.464844 0 26.277344-7.535156 51.769531-21.789062 73.710938l-3.683594 5.660156zm0 0" fill="#07A4B2"/><path d="m180.5 271h30v30h-30zm0 0"/><path fill="#07A4B2" d="m225.5 150c0 8.519531-3.46875 16.382812-9.765625 22.144531l-35.234375 32.25v36.605469h30v-23.394531l25.488281-23.328125c12.398438-11.347656 19.511719-27.484375 19.511719-44.277344 0-33.085938-26.914062-60-60-60s-60 26.914062-60 60h30c0-16.542969 13.457031-30 30-30s30 13.457031 30 30zm0 0"/></svg>
              </div>
              <div class="item-main_payment__address__text">Если у Вас остались вопросы звоните по телефону {{ data.phone }}
              </div>
            </div>
            <div class="item-main_payment__address" v-if="user">
              <FormCheckbox v-model="useBonus">
                <span class="item-main_payment__address__text" style="margin-top: 5px;display: inline-block;">Использовать {{ user.bonus }} {{ numberDecl(user.bonus, ['балл', 'балла', 'баллов']) }}</span>
              </FormCheckbox>
            </div>
          </div>

          <div class="item-main_price">
            <div class="item-main_price__title">
              Стоимость обучения
            </div>
            <div class="item-main_price__price">{{ numberFormat(data.price) }} <CurrencyRuble/></div>
          </div>
        </div>
        <div class="item-main_footer">
          <div class="item-main_footer_btn">
            <button class="button primary" disabled style="display: none">Отменить выбор курса</button>
          </div>
          <div class="item-main_footer_btn">
            <a v-if="data.price" :href="discountsInProcess ? '#':data.pay_url" class="button primary" :class="{disabled: discountsInProcess}">Перейти для оплаты</a>
            <a v-else @click.prevent="bonusPay()" class="button primary" href="#">Оплатить баллами</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <SliderModal v-if="show">
    <button class="button circle" @click.stop="show = false"><i class="fas fa-times"></i></button>
    <form class="company-form" @submit.prevent>
      <FormField title="Отправитель (сокращенное наименование)">
        <input class="input" type="text">
      </FormField>
      <FormField title="Адрес юридический">
        <input class="input" type="text">
      </FormField>
      <FormField title="ОГРН / ОГРНИП">
        <input class="input" type="text">
      </FormField>
      <FormField title="ИНН/КПП">
        <input class="input" type="text">
      </FormField>
      <FormField title="БИК">
        <input class="input" type="text">
      </FormField>
      <FormField title="Банк">
        <input class="input" type="text">
      </FormField>
      <FormField title="Р/с">
        <input class="input" type="text">
      </FormField>
      <FormField title="К/С">
        <input class="input" type="text">
      </FormField>
      <div class="flex">
        <div class="col50">
          <FormField title="Контакты">
            <input class="input" type="text">
          </FormField>
        </div>
        <div class="col50">
          <FormField title="Эл. почта">
            <input class="input" type="text">
          </FormField>
        </div>
      </div>
      <FormField title="ИП / Генеральный директор">
        <input class="input" type="text">
      </FormField>
      <div class="buttons">
        <button class="button primary" type="submit">Отправить</button>
      </div>
    </form>
  </SliderModal>
</template>

<script>
import {computed} from "@vue/reactivity";
import numberFormat from "@/helpers/numberFormat";
import PaymentOption from "@/components/setup/PaymentOption";
import paymentMethods from "@/data/paymentMethods";
import {dayMonthFormat, stringToDate} from "@/helpers/date";
import {inject, ref, watch} from "@vue/runtime-core";
import SliderModal from "@/components/common/SliderModal";
import FormField from "@/components/common/FormField";
import CurrencyRuble from "@/components/common/CurrencyRuble";
import EditDiscounts from "@/components/user/EditDiscounts";
import numberDecl from "@/helpers/numberDecl";
import {paymentByBonus} from "@/data/requests";
import {useRoute, useRouter} from "vue-router";
import FormCheckbox from "@/components/common/FormCheckbox.vue";

export default {
  methods: {numberDecl},
  props: ["data"],
  components: {FormCheckbox, CurrencyRuble, SliderModal, PaymentOption, FormField, EditDiscounts},
  setup(props, context) {
    const user = inject('user');
    const token = inject('token');
    const route = useRoute();
    const router = useRouter();
    const useBonus = ref(false);

    const dateOf = computed(() => props.data?.date_of ? dayMonthFormat(stringToDate(props.data.date_of)) : '');
    const dateTo = computed(() => props.data?.date_to ? dayMonthFormat(stringToDate(props.data.date_to)) : '');

    const method = ref(paymentMethods[0]);
    const discountsInProcess = ref(false);
    const show = ref(false);
    const bonusPay = async () => {
      const data = await paymentByBonus(token.value, route.params.id);

      if (data?.status) {
        await router.push({path: '/timetable'})
      } else {
        alert('Произошла ошибка. Обратитесь в поддержку')
      }
    }

    watch(method, value => show.value = value.id === 3)
    watch(useBonus, () => {
      context.emit('bonus', useBonus.value)
    })

    watch(user, () => {
      discountsInProcess.value = user.value?.discounts.some(discount => {
        return discount.url && !discount.confirmed
      })
    }, {immediate: true, deep: true})

    return {numberFormat, paymentMethods, dateOf, dateTo, method, show, user, discountsInProcess, useBonus, bonusPay}
  }
}
</script>

<style scoped lang="scss">
.flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.col50 {
  width: calc(50% - 8px);

  @media all and (max-width: 1025px) {
    width: 100%;
  }
}
.company-form {
  margin-top: 20px;
}
</style>
