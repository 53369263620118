<template>
  <div class="item-main_block" :class="{'is-disabled': disabled}">
    <div class="item-main_block_header">
      <div class="item-main_block__title">{{ option.title ?? option.name }}</div>
      <label class="item-main_block__checkbox" :class="type" v-if="!disabled">
        <slot></slot>
        <span class="item-main_block__checkmark"></span>
      </label>
    </div>
    <div class="item-main_block__desc">{{ option.description }}</div>
    <div class="item-main_block__price" v-if="option?.purchased > 0">
      {{ option?.purchased + ' ' + numberDecl(option?.purchased, ['затятие', 'занятия', 'занятий']) }}
    </div>
  </div>
</template>

<script>
import {inject} from "@vue/runtime-core";
import numberDecl from "@/helpers/numberDecl";

export default {
  props: ['option', 'type', 'step', 'disabled'],
  setup() {
    const setupData = inject('setupData')

    return {setupData, numberDecl}
  }
}
</script>

<style scoped lang="scss">
.is-disabled {
  opacity: 0.56;
}
</style>
