const colors = [
    '#616386',
    '#07a4b2',
    '#8bc34a',
    '#9c27b0',
    '#ff9800',
    '#e91e63',
    '#3f51b5',
    '#2196f3',
    '#ff5722',
    '#673ab7',
];

export default colors;

export const getColor = (index) => colors[index % colors.length];