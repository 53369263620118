<template>
  <div class="content">
    <div class="content-header">
      <div class="content-header__title">Курсы</div>
      <div class="header__notification">
        <button class="btn_notification" style="display:none;"><i class="far fa-bell"></i></button>
      </div>
    </div>
    <div class="filter">
      <div class="filter-option">
        <RouterLink class="button big light" active-class="active" :to="{name: 'setup'}">Теория</RouterLink>
        <RouterLink class="button big light" active-class="active" :to="{name: 'master-class'}">Мастер-класс</RouterLink>
        <RouterLink class="button big light" active-class="active" :to="{name: 'setup-practice'}">Практика</RouterLink>
        <RouterLink class="button big light" active-class="active" :to="{name: 'practice-exam'}">Практический экзамен</RouterLink>
        <RouterLink class="button big light" :to="{name: 'tickets'}">Билеты ГИМС</RouterLink>
        <RouterLink class="button big light" :to="{name: 'practice-batches'}">Оплаченные пакетные занятия</RouterLink>
      </div>
    </div>
    <div class="select-city">
      <i class="fas fa-map-marker-alt"></i>
      <div class="select-city-item"
           v-for="city in cities" :key="city.id"
           @click="city_id = city.id"
           :class="{ 'active': city_id === city.id }"
      >
        {{ city.name }}
      </div>
    </div>
    <div class="content-main">
      <CourseItem v-for="course in courses" :key="course.id" :course="course" @next-page="$emit('next-page')"/>
    </div>
  </div>
</template>

<script>

import CourseItem from "@/components/setup/CourseItem";
import {onMounted, ref} from "@vue/runtime-core";
import {getCities} from "@/data/requests";

export default {
  components: {
    CourseItem
  },
  props: ["data"],

  data() {
    return {
      city_id: 1
    };
  },

  setup() {
    const cities = ref([]);

    onMounted(async function loadData() {
      cities.value = await getCities();
    });

    return {
      cities,
    };
  },

  computed: {
    courses() {
      return this.data.value.filter((course) => {
        return course.city?.id === this.city_id;
      });
    }
  },
}
</script>
