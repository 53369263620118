<template>
  <div class="sidebar">
    <div class="sidebar_title">Начать<br>
      обучение —<br>
      просто
    </div>
    <div class="sidebar-guide">
      <a href="#" class="item-guide item-guide__active">
        <div class="item-guide__icon">О</div>
        <div class="item-guide__title">Оплата</div>
      </a>
    </div>
    <div class="sidebar-price">
      <div class="sidebar-price__title">Предварительная стоимость</div>
      <div class="sidebar-price__price">{{ numberFormat(summary) }} <CurrencyRuble/></div>
      <div class="sidebar-price__btns">
        <RouterLink class="button secondary big" :to="{name: 'timetable'}">Назад</RouterLink>
      </div>
    </div>
  </div>
  <PracticesPaymentView :data="data" @bonus="reloadBonus" v-if="isPractice" @refresh="loadData"/>
  <CoursePaymentView :data="data" @bonus="reloadBonus" v-else/>
</template>

<script>
import numberFormat from "@/helpers/numberFormat";
import CoursePaymentView from "@/components/payment/CoursePaymentView";
import {inject, onMounted, onUnmounted, ref, watch} from "@vue/runtime-core";
import {getCourseInfo, getUnpaidPractices} from "@/data/requests";
import {useRoute} from "vue-router";
import {computed} from "@vue/reactivity";
import PracticesPaymentView from "@/components/payment/PracticesPaymentView";
import CurrencyRuble from "@/components/common/CurrencyRuble";

export default {
  components: {CurrencyRuble, CoursePaymentView, PracticesPaymentView},
  setup() {
    const data = ref({});

    const token = inject('token');
    const route = useRoute();
    const isPractice = computed(() => route.params.id === 'practices');

    const loadData = async (useBouns = false) => {
      data.value = isPractice.value
          ? await getUnpaidPractices(token.value, useBouns)
          : await getCourseInfo(token.value, route.params.id, useBouns);
    }

    const reloadBonus = useBonus => {
      loadData(useBonus)
    }

    watch(() => route.params.id, value => value && loadData());
    onMounted(loadData);

    const interval = setInterval(loadData, 1000 * 60);
    onUnmounted(() => clearInterval(interval));

    const summary = computed(() => data.value?.price);
    return {numberFormat, summary, data, isPractice, loadData, reloadBonus}
  }
}
</script>

<style scoped>

</style>
