<template>
  <div class="content">
    <div class="content-header">
      <div>
        <div class="content-header__title">Авторизация</div>
        <div class="content-header__subtitle">Введите Ваш логин и пароль</div>
      </div>
      <button class="content-header__close" @click="$emit('clearSetup')"><i class="fas fa-times"></i></button>
    </div>
    <div class="content-form">
      <form @submit.prevent="submit">
        <FormField :error="errors.login">
          <input class="input" type="text" v-model="form.login" placeholder="Логин">
        </FormField>
        <FormField :error="errors.password">
          <template #icon>
            <i class="form-icon fas fa-key"></i>
          </template>
          <span class="content-form-password">
            <input class="input" :type="showPassword ? 'text' : 'password'" v-model="form.password" placeholder="Пароль">
            <button type="button" @click="showPassword = !showPassword">
              <i class="fas fa-eye" v-if="!showPassword"></i>
              <i class="fas fa-eye-slash" v-if="showPassword"></i>
            </button>
          </span>
        </FormField>
        <div class="form-actions">
          <RouterLink :to="{name: 'register'}" class="forgot">Регистрация</RouterLink>
          <RouterLink :to="{name: 'forgot-password'}" class="forgot">Забыли пароль?</RouterLink>
        </div>
        <button class="button primary wide">Войти</button>
      </form>
    </div>
  </div>
  <BaseModal :show="isErrorModalShowing"
             @cancel-modal="isErrorModalShowing = false"
             title="Ошибка регистрации на курс или практику"
             :description="modalErrors">
    <RouterLink :to="{name: 'setup'}" class="button primary">Вернуться</RouterLink>
    <button class="button secondary red" @click="stopCourseRegistration">Прервать</button>
  </BaseModal>
</template>

<script>
import {inject, reactive, ref} from "@vue/runtime-core";
import {useRoute, useRouter} from "vue-router";
import {auth, registerCourse, registerPractice} from "@/data/requests";
import {
  clearGroupId, clearPracticeData, clearRegisterErrors, clearSetupData,
  getGroupId, getPracticeData,
  getSetupData,
  prepareCourseRegisterData, preparePracticeRegisterData,
  setGroupId, setRegisterErrors
} from "@/helpers/courseRegister";
import FormField from "@/components/common/FormField";
import BaseModal from "@/components/common/BaseModal";

export default {
  components: {
    FormField,
    BaseModal
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const login = inject('login');
    const showPassword = ref(false);

    const errors = ref({});

    const form = reactive({
      login: "",
      password: "",
      remember: false
    });

    const isErrorModalShowing = ref(false);
    const modalErrors = ref("");

    async function submit() {
      const data = await auth(form);

      if (data.token) {
        await login(data.token);

        const practiceData = getPracticeData();
        if (practiceData) {
          const preparedData = preparePracticeRegisterData(practiceData);
          const {errors} = await registerPractice(data.token, preparedData);
          if (errors) {
            setRegisterErrors(errors);
            isErrorModalShowing.value = true;
            modalErrors.value = 'Пожалуйста, проверьте время бронирования и исправьте ошибки';
            await router.push({name: "setup-practice"})
            return;
          }
          clearPracticeData();
        }

        const setupData = getSetupData();
        if (setupData) {
          const preparedData = prepareCourseRegisterData(setupData);
          const {group_id, message, errors} = await registerCourse(data.token, preparedData);
          if (!group_id) {
            isErrorModalShowing.value = true;
            modalErrors.value = message ?? Object.values(errors).join("\n");
            return;
          }
          clearSetupData();
          setGroupId(group_id);
        }

        const group_id = getGroupId();
        if (group_id) {
          clearGroupId();
          await router.push({name: "payment", params: {id: group_id}});
        } else {
          await router.push(route.query.redirect ?? {name: "timetable"});
        }

        return;
      }

      errors.value = data.errors;
    }

    function stopCourseRegistration() {
      isErrorModalShowing.value = false;
      clearSetupData();
      clearRegisterErrors();
      router.push(route.query.redirect ?? {name: "timetable"});
    }

    return {errors, form, submit, stopCourseRegistration, isErrorModalShowing, modalErrors, showPassword}
  }
}
</script>

<style scoped lang="scss">
.content-form {
  max-width: 360px;
  @media all and (max-width: 1025px) {
    max-width: 100%;
  }
}

.form-actions {
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.forgot {
  font-size: 12px;
  color: #086596;
  &:last-child {
    margin-left: auto;
  }
}

.content-offer {
  max-width: 360px;
  width: calc(100% - 30px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 42px;

  &__text {
    font-size: 12px;
    line-height: 16px;
    color: #51585C;
  }
  .button {
    width: 50%;
    box-sizing: border-box;
  }
}
.content-form-password {
  position: relative;
}
.content-form-password button {
  position: absolute;
  top: 3px;
  right: 6px;
}

</style>
