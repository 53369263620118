<template>
  <DiscountItem v-for="document in discounts" :key="document.id" :discount="document" deletable="true"/>
</template>

<script>
import DiscountItem from "@/components/common/DiscountItem";

export default {
  components: {
    DiscountItem,
  },
  props: ['discounts'],
  setup() {
  }
}
</script>
