<template>
  <div class="content">
    <div class="content-header">
      <div>
        <div class="content-header__title">Регистрация</div>
        <div class="content-header__subtitle">Введите Ваши данные чтобы продолжить</div>
      </div>
      <button class="content-header__close" @click="$emit('clearSetup')"><i class="fas fa-times"></i></button>
    </div>
    <form @submit.prevent="submit" class="content-form">
      <FormField :error="errors.last_name">
        <input class="input" type="text" v-model="form.last_name" placeholder="Фамилия">
      </FormField>
      <FormField :error="errors.first_name">
        <input class="input" type="text" v-model="form.first_name" placeholder="Имя">
      </FormField>
      <FormField :error="errors.middle_name">
        <input class="input" type="text" v-model="form.middle_name" placeholder="Отчество">
      </FormField>
      <FormField :error="errors.email">
        <input class="input" type="text" v-model="form.email" placeholder="E-mail">
      </FormField>
      <FormField :error="errors.phone">
        <input class="input" id="phone" type="text" v-model="form.phone" placeholder="Телефон" autocomplete="false">
      </FormField>
      <div class="form-actions">
        <FormCheckbox v-model="form.agree" :error="errors.agree">
          <span class="agree">Я принимаю условия <a href="#" target="_blank" class="link">Лицензионного соглашения</a> и
          на обработку <RouterLink :to="{name: 'policy'}" target="_blank" class="link">Персональных данных</RouterLink></span>
        </FormCheckbox>
      </div>
      <button class="button primary wide">Продолжить</button>
    </form>
    <div class="content-offer">
      <span class="content-offer__text">Уже есть аккаунт?</span>
      <RouterLink class="button secondary wide" :to="{name: 'auth'}">Войти</RouterLink>
    </div>
  </div>
  <BaseModal :show="isErrorModalShowing"
             @cancel-modal="isErrorModalShowing = false"
             title="Ошибка регистрации на курс"
             :description="modalErrors">
    <button class="button primary" @click="isErrorModalShowing = false">Вернуться</button>
    <button class="button secondary red" @click="stopCourseRegistration">Прервать</button>
  </BaseModal>
</template>

<script>
import {reactive, ref, onMounted} from "@vue/runtime-core";
import {useRouter} from "vue-router";
import {register} from "@/data/requests";
import {
  clearPracticeData,
  clearSetupData,
  getPracticeData,
  getSetupData,
  prepareCourseRegisterData, preparePracticeRegisterData,
  setGroupId, setRegisterErrors
} from "@/helpers/courseRegister";
import FormField from "@/components/common/FormField";
import FormCheckbox from "@/components/common/FormCheckbox";
import BaseModal from "@/components/common/BaseModal";
import Inputmask from "inputmask/lib/inputmask";

export default {
  components: {
    FormField,
    FormCheckbox,
    BaseModal
  },
  setup() {
    const router = useRouter();

    const errors = ref({});
    const form = reactive({});

    const isErrorModalShowing = ref(false);
    const modalErrors = ref("");

    async function submit() {
      modalErrors.value = "";
      const setupData = getSetupData();
      const practiceData = getPracticeData();
      const preparedData = {
        ...form,
        ...(setupData?.group ? {course: prepareCourseRegisterData(setupData)} : {}),
        ...(practiceData?.times?.length > 0 ? preparePracticeRegisterData(practiceData) : {}),
      }
      const {data, code} = await register(preparedData);

      if (code === 201) {
        if (data.message) {
          isErrorModalShowing.value = true;
          modalErrors.value = data.message;
        }
        if (data.group_id)
          setGroupId(data.group_id);
        localStorage.setItem('register', JSON.stringify({email: form.email}));
        clearSetupData();
        await router.push({name: 'email-confirm'});
        return;
      }
      errors.value = data.errors;
      if (Object.keys(data.errors).some(el => el.match(/^times/))) {
        setRegisterErrors(errors);
        isErrorModalShowing.value = true;
        modalErrors.value = 'Пожалуйста, проверьте время бронирования и исправьте ошибки';
        await router.push({name: "setup-practice"})
      }
    }

    function stopCourseRegistration() {
      isErrorModalShowing.value = false;
      clearSetupData();
      clearPracticeData();
    }

    onMounted(() => Inputmask('+7 (999) 999-99-99').mask(document.getElementById('phone')));

    return {errors, form, submit, isErrorModalShowing, modalErrors, stopCourseRegistration}
  }
}
</script>

<style scoped lang="scss">
.content-form {
  max-width: 360px;
  @media all and (max-width: 1025px) {
    max-width: 100%;
  }
  .button {
    margin-top: 16px;
  }
}

.col50 {
  width: calc(50% - 8px);

  @media all and (max-width: 1025px) {
    width: 100%;
  }
}

.form-actions {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.forgot {
  font-size: 12px;
  color: #086596;
}

.content-offer {
  max-width: 360px;
  width: calc(100% - 30px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 0 0;
  &__text {
    font-size: 12px;
    line-height: 16px;
    color: #51585C;
  }
  .button {
    width: 50%;
    box-sizing: border-box;
  }
}

.agree {
  font-size: 12px;
  line-height: 16px;
  color: #51585C;
  .link {
    color: #086596;
    text-decoration: none;
  }
}

</style>
