<template>
  <BaseModal :show="show"
             @cancel-modal="$emit('hide')"
             title="Сообщение в тех поддержку"
             :description="description">
    <form @submit.prevent="sendMessage" class="form">
      <div class="success" v-if="success">{{ success }}</div>
      <FormField title="Тема" :error="errors.title">
        <input class="input" type="text" v-model="form.title">
      </FormField>
      <FormField title="Описание ошибки" :error="errors.message">
        <textarea class="input" v-model="form.message"></textarea>
      </FormField>
      <div class="buttons">
        <button class="button primary" type="submit" :disabled="!canSend">Отправить</button>
        <button class="button secondary" type="button" @click="$emit('hide')">Закрыть</button>
      </div>
    </form>
  </BaseModal>
</template>

<script>
import BaseModal from "@/components/common/BaseModal";
import {inject, ref} from "@vue/runtime-core";
import {computed} from "@vue/reactivity";
import {sendSupportMessage} from "@/data/requests";
import FormField from "@/components/common/FormField";
import {wait} from "@/helpers/wait";

export default {
  props: ['show'],
  components: {BaseModal, FormField},
  setup(props, {emit}) {
    const user = inject('user');
    const token = inject('token');
    const description = computed(() => `Попытайтесь максимально подробно описать проблему. Ответ прейдёт на вашу электронную почту: ${user.value.email}`)

    const form = ref({});
    const errors = ref({});
    const success = ref("");

    const sending = ref(false);
    const canSend = computed(() => !sending.value && form.value.title && form.value.message);

    async function sendMessage() {
      if (!canSend.value) return;

      errors.value = {};
      sending.value = true;
      const data = await sendSupportMessage(token.value, form.value);
      sending.value = false;
      if (data.data.errors) {
        errors.value = data.data.errors;
      } else {
        success.value = "Ваше сообщение успешно отправлено. Ожидайте ответа...";
        form.value = {};
        await wait(5000);
        emit('hide');
        success.value = "";
      }
    }

    return {description, form, errors, sendMessage, success, canSend}
  }
}
</script>

<style scoped>
.form {
  width: 100%;
}

.buttons {
  text-align: left;
}
</style>
