import { ref } from 'vue'

export default function useForm (request, onSuccess = () => {}, onFailure = () => {}) {
  const form = ref({})
  const formErrors = ref({})
  const loading = ref(false)

  async function submit () {
    formErrors.value = {}
    loading.value = true
    const {
      status,
      errors,
      hasError,
      message,
      data
    } = await request(form.value)
    loading.value = false

    if (errors) {
      formErrors.value = errors
    }

    if (message) {
      formErrors.value = { general: message }
    }

    if (hasError) {
      onFailure(data, status)
      return
    }

    onSuccess(data)
  }

  return {
    form,
    loading,
    errors: formErrors,
    submit
  }
}
