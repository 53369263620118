<template>
  <div class="content">
    <div class="content-header relative">
      <div>
        <div class="content-header__title">Успешная регистрация</div>
      </div>
      <svg width="270" height="241" viewBox="0 0 270 241" fill="none" xmlns="http://www.w3.org/2000/svg" class="email-image">
        <path d="M211.396 79.9475L153.99 126.167C143.127 134.684 127.898 134.684 117.034 126.167L59.1353 79.9475" stroke="#D4F4F7" stroke-width="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M71.6991 5.10413H198.583C216.878 5.30932 234.291 13.0436 246.767 26.5056C259.242 39.9676 265.692 57.9823 264.612 76.3541V164.208C265.692 182.58 259.242 200.595 246.767 214.057C234.291 227.519 216.878 235.253 198.583 235.458H71.6991C32.402 235.458 5.9165 203.489 5.9165 164.208V76.3541C5.9165 37.0737 32.402 5.10413 71.6991 5.10413Z" stroke="#D4F4F7" stroke-width="10" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <RouterLink :to="{name: 'register'}" class="content-header__close"><i class="fas fa-times"></i></RouterLink>
    </div>
    <div class="content-main">
      <div class="content-main__desc">На Ваш указанный E-Mail <a :href="'mailto:' + registerData?.email">{{ registerData?.email }}</a>
        отправлены учетные данные для входа в систему.</div>
      <div>
        <RouterLink :to="{name: 'auth'}" class="button primary big">Войти</RouterLink> <button class="button secondary big" @click="send">Повторить отправку</button>
      </div>
      <div v-if="success" class="success">{{ success }}</div>
    </div>
  </div>
</template>

<script>
import {sendPasswordToEmail} from "@/data/requests";
import {ref} from "@vue/runtime-core";

export default {
  setup() {
    const registerData = JSON.parse(localStorage.getItem('register'));
    const success = ref('');
    const send = () => {
      success.value = 'Письмо отправлено на почту';
      sendPasswordToEmail(registerData?.email)
    };
    return {registerData, send, success};
  }
}
</script>

<style scoped lang="scss">
.relative {
  position: relative;
}

.email-image {
  position: absolute;
  right: 200px;
  top: 0;
  z-index: -1;
  &.closed {
    right: 219px;
    top: 2px;
  }
}

.content-main {
  max-width: 360px;
  &__desc {
    font-size: 14px;
    line-height: 24px;
    color: #51585C;
    margin-top: 20px;
    margin-bottom: 32px;
    a {
      color: #086596;
      text-decoration: none;
    }
  }
  &__small {
    margin-bottom: 220px;
    color: #51585C;
    font-size: 12px;
    line-height: 16px;
  }
}

.success {
  margin-bottom: 0;
  margin-top: 20px;
}
</style>
