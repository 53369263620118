<template>
  <DocumentItem v-for="document in documents" :key="document.id" :document="document" deletable="true"/>
  <DocumentPreview v-for="(file, i) in filesForUpload" :key="file.lastModified" :file="file"
                   @remove-file="filesForUpload.splice(i, 1)"/>
  <div class="user-info__document add-document">
    <span class="fas fa-file-upload"></span>
    <span>Добавьте фото паспорта.
      <br />1. Главная страница
      <br />2. Страница регистрации</span>
    <input class="document-loader" type="file" @change="addDocument">
  </div>
</template>

<script>
import DocumentItem from "@/components/common/DocumentItem";
import DocumentPreview from "@/components/common/DocumentPreview";
import {ref} from "@vue/runtime-core";

export default {
  components: {
    DocumentItem,
    DocumentPreview,
  },
  props: ['documents'],
  setup() {
    const filesForUpload = ref([]);

    async function addDocument(e) {
      const file = e.target.files[0];
      if (!file) return;
      e.target.value = null;
      filesForUpload.value.push(file)
    }

    return {filesForUpload, addDocument}
  }
}
</script>

<style scoped lang="scss">
.add-document {
  min-width: 200px;
  padding: 40px 20px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 3px dashed #333333;

  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #333;

  .fas {
    font-size: 32px;
    line-height: 32px;
    margin-bottom: 16px;
  }

  .document-loader {
    position: absolute;
    box-sizing: border-box;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
}
</style>
