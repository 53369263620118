<template>
  <div class="sidebar">
    <div class="sidebar_title">Начать<br>
      обучение —<br>
      просто
    </div>
    <div class="sidebar-guide">
      <a href="#" class="item-guide" v-for="(step, i) in steps" :key="step.title"
         :class="{'item-guide__active': i <= currentIndex}" @click.prevent="changeStep(i)">
        <div class="item-guide__icon">{{ i + 1 }}</div>
        <div class="item-guide__title">{{ step.title }}</div>
      </a>
    </div>
    <div class="sidebar-price">

      <div class="sidebar-price__title" v-if="priceDetails?.details?.discount">{{priceDetails.details.discount.name}}</div>
      <div class="sidebar-price__price" v-if="priceDetails?.details?.discount">-{{ numberFormat(priceDetails.details.discount.discount) }} <CurrencyRuble/></div>

      <template v-if="user">
        <div class="sidebar-price__title">Баллы</div>
        <div class="sidebar-price__price">
          {{ user.bonus }}<br>
          <p style="font-size: 12px;line-height: 1.2;margin-bottom: 20px;">Использовать баллы возможно только при оплате</p>
        </div>
      </template>

      <div class="sidebar-price__title">Предварительная стоимость</div>
      <div class="sidebar-price__price">{{ numberFormat(finalPrice >= 0 ? finalPrice:0) }} <CurrencyRuble/></div>
      <div class="sidebar-price__btns">
        <button class="button secondary wide" v-if="isFirst"
                :class="{disabled: !readyToSwitch}" @click="next">Выбрать курс</button>
        <template v-else>
          <button class="button secondary big" @click="prev">Назад</button>
          <button class="button primary big" :class="{disabled: !readyToSwitch}" @click="next"
                  v-if="!isLast">Далее</button>
          <RouterLink class="button primary big"
                      v-if="isLast && !user && readyToSwitch" :to="{name: 'register'}">Войти
          </RouterLink>
          <button class="button primary big"
                  v-if="isLast && user && readyToSwitch" @click="register">К оплате</button>
        </template>
      </div>
    </div>
  </div>
  <div class="mobile_footer">
    <div class="mobile_footer__left">
      <div class="title" v-if="priceDetails?.details?.discount">{{priceDetails.details.discount.name}}</div>
      <div class="price" v-if="priceDetails?.details?.discount">-{{ numberFormat(priceDetails.details.discount.discount) }} <CurrencyRuble/></div>

      <div class="title">Стоимость</div>
      <div class="price">{{ numberFormat(finalPrice >= 0 ? finalPrice:0) }} <CurrencyRuble/></div>
    </div>
    <div class="mobile_footer__right">
      <button class="button secondary" v-if="isFirst"
              :class="{disabled: !readyToSwitch}" @click="next">Выбрать курс</button>
      <template v-else>
        <button class="button secondary" @click="prev">Назад</button>
        <button class="button primary" :class="{disabled: !readyToSwitch}" @click="next"
                v-if="!isLast">Далее</button>
        <RouterLink class="button primary"
                    v-if="isLast && !user && readyToSwitch" :to="{name: 'register'}">Войти
        </RouterLink>
        <button class="button primary" :class="{disabled: !readyToSwitch}"
                v-if="isLast && user && readyToSwitch" @click="register">К оплате</button>
      </template>
    </div>
  </div>
  <component :is="currentStep.component" :data="currentStep.data"
             @next-page="next" @prev-page="prev" @clear-setup="isModalShowing = true"/>
  <BaseModal :show="isModalShowing"
             @cancel-modal="isModalShowing = false"
             title="Вы уверены, что хотите прервать выбор курса?"
             description="При возвращении к выбору придется выбрать все пункты повторно">
    <button class="button primary" @click="isModalShowing = false">Продолжить выбор</button>
    <button class="button secondary red" @click="clearSetup">Прервать</button>
  </BaseModal>
  <BaseModal :show="isErrorModalShowing"
             @cancel-modal="isErrorModalShowing = false"
             title="Ошибка регистрации на курс"
             :description="modalErrors">
    <button class="button primary" @click="isErrorModalShowing = false">Вернуться</button>
    <button class="button secondary red" @click="clearSetup">Прервать</button>
  </BaseModal>
</template>

<script>
import CoursesView from "@/components/setup/CoursesView";
import StepView from "@/components/setup/StepView";
import StepSingleView from "@/components/setup/StepSingleView";
import StartDateView from "@/components/setup/StartDateView";
import BaseModal from "@/components/common/BaseModal";
import {inject, onMounted, provide, readonly, ref, watch} from "@vue/runtime-core";
import {computed} from "@vue/reactivity";
import {calculateCourse, getCourses, registerCourse} from "@/data/requests";
import numberFormat from "@/helpers/numberFormat";
import {useRouter} from "vue-router";
import {prepareCourseRegisterData} from "@/helpers/courseRegister";
import {useSteps} from "@/use/steps";
import {useLocalStore} from "@/use/localStore";
import CurrencyRuble from "@/components/common/CurrencyRuble";

export default {
  components: {CurrencyRuble, BaseModal},
  setup() {
    const token = inject('token');
    const user = inject('user');
    const coursesInfo = ref([]);
    const router = useRouter();
    onMounted(async () => coursesInfo.value = await getCourses(token.value, router.currentRoute.value.name === 'master-class'));

    watch(router.currentRoute, async () => {
      coursesInfo.value = await getCourses(token.value, router.currentRoute.value.name === 'master-class')
    })

    const {
      data: setupData,
      clear: clearSetupData,
      add: updateSetupData
    } = useLocalStore('setupData', {fields: [], format: 'base'});

    function setField(field) {
      if(field.id === 'format') {
        setupData.value.format = field.value
      }
      if(field.slug === 'region' && (field.value.includes(27) || field.value.includes(59))) {
        field.value = field.value.filter(el => el !== 26 && el !== 12)
      }
      const currentIndex = setupData.value.fields.findIndex(el => el.id === field.id);
      if (currentIndex !== -1) {
        setupData.value.fields[currentIndex] = field;
      } else {
        setupData.value.fields.push(field);
      }
    }

    const clearSetupDataProv = () => {
      setupData.value.fields.splice(0)
      clearSetupData()
    }
    provide('setupData', readonly(setupData));
    provide('clearSetupData', clearSetupDataProv);
    provide('updateSetupData', updateSetupData);
    provide('setField', setField);

    const stepReady = step => {
      if(setupData.value?.format === 'base' && step.not_required_base) return true;
      const currentIndex = setupData.value.fields.find(el => el.id === step.id);
      if (!currentIndex) return false;
      if (Array.isArray(currentIndex.value)) return currentIndex.value.length > 0;
      return !!currentIndex.value;
    };

    const steps = computed(() => {
      const singleStepCustoms = setupData.value.course?.customs.filter(el => el.is_step) ?? [];
      const multipleStepCustoms = setupData.value.course?.customs.filter(el => !el.is_step) ?? [];
      const currentGroups = setupData.value.course?.groups ?? [];

      if(setupData.value && setupData.value.course && setupData.value.course.format) {
        singleStepCustoms.unshift({
          "id": 'format',
          "title": "Формат обучения",
          "description": null,
          "slug": "format",
          "type": "radio",
          "is_step": 1,
          "order": 0,
          "fields": [
            {
              "id": 'base',
              "title": setupData.value.course.format.base.name,
              "description": setupData.value.course.format.base.description,
              "slug": 'base',
              "price": setupData.value.course.format.base.price
            },
            {
              "id": 'additional',
              "title": setupData.value.course.format.additional.name,
              "description": setupData.value.course.format.additional.description,
              "slug": 'additional',
              "price": setupData.value.course.format.additional.price
            }
          ]
        })
      }

      return [
        {
          title: "Выбери направление",
          component: CoursesView,
          data: coursesInfo,
          readyFunc: () => !!setupData.value.course
        },
        ...singleStepCustoms.map(custom => ({
          title: custom.title,
          component: StepSingleView,
          data: custom,
          readyFunc: () => stepReady(custom)
        })),
        ...(multipleStepCustoms.length === 0 ? [] : [
          {
            title: "Сформируй курс",
            component: StepView,
            data: multipleStepCustoms,
            readyFunc: () => setupData.value?.format === 'base' ? multipleStepCustoms.map(stepReady).every(Boolean):multipleStepCustoms.map(stepReady).some(Boolean)
          }
        ]),
        {
          title: "Выбери дату начала",
          component: StartDateView,
          data: currentGroups,
          readyFunc: () => !!setupData.value.group ?? user.value
        }
      ];
    });

    const isModalShowing = ref(false);

    const message = ref("");
    provide("message", message);

    async function clearSetup() {
      isModalShowing.value = false;
      await router.push({name: "timetable"});
      clearSetupData();
    }

    const isErrorModalShowing = ref(false);
    const modalErrors = ref("");

    async function register() {
      modalErrors.value = "";
      const preparedData = prepareCourseRegisterData(setupData.value);
      const data = await registerCourse(token.value, preparedData);

      if (!data.group_id) {
        isErrorModalShowing.value = true;
        modalErrors.value = data.message ?? Object.values(data.errors).join("\n");
        return;
      }

      await router.push({name: 'payment', params: {id: data.group_id}});
      clearSetupData();
    }

    const finalPrice = ref(0);
    const priceDetails = ref({});
    async function calculate() {
      modalErrors.value = "";
      const preparedData = prepareCourseRegisterData(setupData.value);
      const data = await calculateCourse(token.value, preparedData);
      finalPrice.value = data.price
      priceDetails.value = data.price_detailed
    }
    watch(() => setupData.value, calculate, {immediate: true, deep: true})

    const {changeStep, currentIndex, currentStep, next, prev, readyToSwitch, isFirst, isLast} = useSteps(steps, "setup");

    return {
      steps,
      prev,
      next,
      changeStep,
      readyToSwitch,
      currentIndex,
      finalPrice,
      priceDetails,
      currentStep,
      numberFormat,
      isModalShowing,
      clearSetup,
      setupData,
      user,
      register,
      isErrorModalShowing,
      modalErrors,
      isFirst,
      isLast
    }
  }
}
</script>
