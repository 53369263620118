<template>
  <div class="content">
    <div class="wrapper">
      <i class="far fa-times-circle"></i>
      <div>
        <div class="content-header">
          <div>
            <div class="content-header__title">Ошибка</div>
          </div>
        </div>
        <div class="content-main">
          <div class="content-main__desc">При оплате возникла ошибка. <br>Обратитесь в поддержку.</div>
          <div class="buttons">
            <RouterLink :to="{name: 'timetable'}" class="button primary big">Перейти к расписанию</RouterLink>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
.content, .wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-main__desc {
  line-height: normal;
}

.buttons {
  margin-top: 20px;
}

.far {
  font-size: 120px;
  margin-right: 20px;
  color: #e57373;
}
</style>
